import React from 'react'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { testDataPart1, testDataPart2 } from './data'
import { hasValue } from '@root/misc/helpers'
import { useAppContext } from '@root/global/context'
import { AdmDataEntry, Color } from '@root/misc/types'
import YipYipButtons from '@misc/YipYipButtons'
import { ColorVariableStrings, modalStyle, ProgressStateAdm, returnColorNicename, returnColorVariable } from '@root/misc/constants'
import { t, Trans } from '@lingui/macro'
import { getTranslatedText } from '@root/utils/ui'

/*
  This is the practise ADM test. It works as follows:

  Provided data from Human Company in two parts are being presented to the user.

  The first part shows 5 rows of 17 numbers. These numbers are all the numbers in the range of 10-59.
  The numbers seem randomly spread out, but there are some 'rules'. Number can't follow each other up on the same row.
  So, 10 could be on row 1, 11 can NOT be on row 1 and must be on a row between 2-5.

  Each row has a specific color.

  The user has to count from 10 up to 59, and should enter the correct color of the row where the current number is displayed.

  In part 2, each number will be accompanied by a second (smaller) number. The assignment is the same, except you need to enter the color of the smaller number.

  In the practise round the correct color/number is highlighted

  SEE FOR MORE INFO THE ADMVIEW COMPONENT. THESE ARE PRACTICALLY THE SAME
*/

interface Props {
  endFn: () => void
  variant: string
  pages?: number
  part: number
}

export function AdmViewPractise (props: Props): JSX.Element {
  const dataToUse: any = [testDataPart1, testDataPart2][props.part - 1]
  const [currentAnswerToGive, setCurrentAnswerToGive] = React.useState<number>(10)
  const [currentColor, setCurrentColor] = React.useState<Color>(Color.red)
  const [startTime, setStartTime] = React.useState<Date>(new Date())
  const [cueOpen, setCueOpen] = React.useState<boolean>(false)
  const [givenAnswers, setGivenAnswers] = React.useState<AdmDataEntry[]>([])
  const modalCorrectBtnRef = React.useRef<HTMLButtonElement>(null)
  const modalCueBtnRef = React.useRef<HTMLButtonElement>(null)
  const modalReturnBtnRef = React.useRef<HTMLButtonElement>(null)

  const [correctOpen, setCorrectOpen] = React.useState(false)
  const [returnOpen, setReturnOpen] = React.useState(false)

  const { setAdmData } = useAppContext()
  const [shouldProceed, setShouldProceed] = React.useState<boolean>(false)
  const { setProgressStateAdm } = useAppContext()

  React.useEffect(() => {
    if (shouldProceed) {
      setAdmData((old: AdmDataEntry[][]) => {
        const newArr = [...old]
        const newGivenAnswers = [...givenAnswers].map(g => {
          if (g.isCorrect) {
            g.amountWrongAnswers = givenAnswers.filter(a => a.itemNumber === g.itemNumber && !a.isCorrect).length
          }
          return g
        }).filter(g => g.isCorrect)
        newArr[props.part - 1] = newGivenAnswers
        return newArr
      })
      props.endFn()
      setShouldProceed(false)
    }
  }, [shouldProceed, givenAnswers, props, setAdmData])

  // TODO: Remove when going to production
  // This one set the CurrentColor to give
  React.useEffect(() => {
    const color = dataToUse.rows.find((row: any) => {
      return row.bigNumbers.includes(currentAnswerToGive)
    })
    if (hasValue(color)) {
      setCurrentColor(props.part === 1 ? color.bigColor : color.smallColor)
    }
  }, [currentAnswerToGive, dataToUse.rows, props.part])
  // end tODO

  React.useEffect(() => {
    if (currentAnswerToGive === 10 + (dataToUse.numberOfNumbersToFind as number) + 1) {
      handleCorrectOpen()
    }
  }, [currentAnswerToGive, dataToUse.numberOfNumbersToFind])

  const giveAnswer = React.useCallback((color: Color): void => {
    if (color === currentColor) {
      setGivenAnswers((old: AdmDataEntry[]): AdmDataEntry[] => {
        return [
          ...old,
          {
            beginTime: startTime,
            endTime: new Date(),
            searchDuration: Math.abs(startTime.getTime() - new Date().getTime()) / 1000,
            itemNumber: currentAnswerToGive,
            isCorrect: true,
            askedColor: currentColor,
            pressedColor: color
          }
        ]
      })

      setCurrentAnswerToGive(old => old + 1)
      setStartTime(new Date())
    } else {
      const amountIncorrect = givenAnswers.filter(a => !a.isCorrect).length
      amountIncorrect !== 2 && handleCueOpen()
      setGivenAnswers((old: AdmDataEntry[]): AdmDataEntry[] => {
        return [
          ...old,
          {
            beginTime: startTime,
            endTime: new Date(),
            searchDuration: Math.abs(startTime.getTime() - new Date().getTime()) / 1000,
            itemNumber: currentAnswerToGive,
            isCorrect: false,
            askedColor: currentColor,
            pressedColor: color
          }
        ]
      })
    }
  }, [currentAnswerToGive, currentColor, givenAnswers, startTime])

  const handleUserKeyPress = React.useCallback((event: KeyboardEvent): void => {
    if (event.repeat) { return }
    if (event.key === 'f') {
      event.preventDefault()
    }
    const possibleKeys = ['F1', 'F2', 'F3', 'F4', 'F5']
    if (possibleKeys.includes(event.key)) {
      event.preventDefault()

      const keyMap = { F1: Color.white, F2: Color.blue, F3: Color.yellow, F4: Color.red, F5: Color.green }

      if (!correctOpen) {
        const { key } = event
        giveAnswer((keyMap as any)[key])
      }
    }
  }, [correctOpen, giveAnswer])

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  const handleCorrectOpen = (): void => {
    setCorrectOpen(true)
    setTimeout(() => {
      modalCorrectBtnRef.current?.focus()
    }, 100)
  }
  const handleCorrectClose = (): void => {
    setCorrectOpen(false)
    props.endFn()
  }

  const handleCueOpen = (): void => {
    setCueOpen(true)
    setTimeout(() => {
      modalCueBtnRef.current?.focus()
    }, 100)
  }
  const handleCueClose = (): void => {
    setStartTime(new Date())
    setCueOpen(false)
  }

  const handleReturnOpen = (): void => {
    setReturnOpen(true)
    setTimeout(() => {
      modalReturnBtnRef.current?.focus()
    }, 100)
  }
  const handleReturnClose = (): void => {
    setReturnOpen(false)
    setProgressStateAdm(props.part === 1 ? ProgressStateAdm.InstructionPart1 : ProgressStateAdm.InstructionPart2)
  }

  React.useEffect(() => {
    const amountIncorrect = givenAnswers.filter(a => !a.isCorrect).length
    if (amountIncorrect >= 3) {
      handleReturnOpen()
    }
  }, [givenAnswers])

  const color: string = (returnColorNicename as any)[currentColor]

  return (
    <>
      <Modal
        disableAutoFocus
        keepMounted
        open={correctOpen}
        onClose={handleCorrectClose}
        aria-labelledby='modal-correct-title'
        aria-describedby='modal-correct-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-correct-title' variant='h6' component='h2'>
                <Trans id='adm.correct_practise.title'>
                  Heel goed!
                </Trans>
              </Typography>
              <Typography id='modal-correct-description' sx={{ mt: 2 }}>

                {getTranslatedText('adm.correct_practise.description', props.part.toString())}

              </Typography>
            </div>
            <Button ref={modalCorrectBtnRef} color='primary' variant='contained' onClick={() => handleCorrectClose()}>
              <Trans id='adm.correct_practise.button'>
                Doorgaan
              </Trans>
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        disableEscapeKeyDown
        disableAutoFocus
        keepMounted
        open={returnOpen}
        aria-labelledby='modal-return-title'
        aria-describedby='modal-return-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-return-title' variant='h6' component='h2'>
                <Trans id='adm.too_many_mistakes.title'>
                  Je hebt 3 fouten gemaakt!
                </Trans>
              </Typography>
              <Typography id='modal-return-description' sx={{ mt: 2 }}>
                <Trans id='adm.too_many_mistakes.description'>
                  Wellicht heb je de instructie niet helemaal begrepen, lees deze opnieuw.
                </Trans>
              </Typography>
            </div>
            <Button ref={modalReturnBtnRef} color='primary' variant='contained' onClick={() => handleReturnClose()}>
              <Trans id='adm.too_many_mistakes.button'>
                Naar de instructie
              </Trans>
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        disableAutoFocus
        keepMounted
        open={cueOpen}
        onClose={handleCueClose}
        aria-labelledby='modal-cue-title'
        aria-describedby='modal-cue-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-cue-title' variant='h6' component='h2'>
                <Trans id='adm.incorrect_practise.title'>
                  Helaas!
                </Trans>
              </Typography>
              <Typography id='modal-cue-description' sx={{ mt: 2 }}>
                <Trans id='adm.incorrect_practise.description'>
                  {getTranslatedText('adm.incorrect_practise.description', currentAnswerToGive)}
                </Trans>
              </Typography>
            </div>
            <Button ref={modalCueBtnRef} color='primary' variant='contained' onClick={() => handleCueClose()}>
              <Trans id='adm.incorrect_practise.button'>
                Doorgaan
              </Trans>
            </Button>
          </Stack>
        </Box>
      </Modal>

      {!(hasValue(process.env.NODE_ENV) && process.env.NODE_ENV === 'production') && (
        <div className='data'>
          <pre>{JSON.stringify({ currentColor, currentAnswerToGive, givenAnswers }, null, 2)}</pre>
        </div>
      )}

      <Stack direction='column' sx={{ height: '100%' }}>
        <Stack sx={{ flex: 1, padding: 2, background: 'var(--color-grey-500)' }} justifyContent='space-between'>
          {
            dataToUse.rows.map((row: any) => {
              return (
                <Box key={row.bigColor} sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
                  {row.bigNumbers.map((val: number, idx: number) => {
                    if (cueOpen) return null

                    return (
                      <Box key={val} sx={{ color: returnColorVariable[row.bigColor as keyof ColorVariableStrings], textAlign: 'center', display: 'grid', placeItems: 'center', border: val === currentAnswerToGive ? '1px solid var(--color-white-100)' : '1px solid transparent', p: '2px' }}>
                        <Typography variant='h4' sx={{ userSelect: 'none' }}>
                          {val}
                        </Typography>
                        {props.part === 2 && row.smallNumbers !== undefined && (
                          <Typography variant='body1' sx={{ userSelect: 'none', color: returnColorVariable[row.smallColor as keyof ColorVariableStrings] }}>
                            {row.smallNumbers[idx]}
                          </Typography>
                        )}
                      </Box>
                    )
                  })}
                </Box>
              )
            })
          }
        </Stack>

        <Box sx={{ height: '50px', flex: '0 0 50px', p: '10px', background: 'var(--color-grey-500)', justifyItems: 'center' }}>
          <Typography variant='h6' sx={{ color: 'white', textAlign: 'center' }}>
            {getTranslatedText(props.part === 1 ? 'adm.practise.cue' : 'adm.practise.cue2', [currentAnswerToGive, t({ id: color })])}
          </Typography>
        </Box>

        {props.variant === 'home' && (
          <Stack sx={{ height: '100px', flex: '0 0 100px', p: '10px', background: 'var(--color-grey-500)' }} direction='row' spacing={2}>
            <YipYipButtons.ButtonRed variant='contained' onClick={() => giveAnswer(Color.red)}><Trans id='general.color.red'>rood</Trans></YipYipButtons.ButtonRed>
            <YipYipButtons.ButtonYellow variant='contained' onClick={() => giveAnswer(Color.yellow)}><Trans id='general.color.yellow'>geel</Trans></YipYipButtons.ButtonYellow>
            <YipYipButtons.ButtonGreen variant='contained' onClick={() => giveAnswer(Color.green)}><Trans id='general.color.green'>groen</Trans></YipYipButtons.ButtonGreen>
            <YipYipButtons.ButtonWhite variant='contained' onClick={() => giveAnswer(Color.white)}><Trans id='general.color.white'>wit</Trans></YipYipButtons.ButtonWhite>
            <YipYipButtons.ButtonBlue variant='contained' onClick={() => giveAnswer(Color.blue)}><Trans id='general.color.blue'>blauw</Trans></YipYipButtons.ButtonBlue>
          </Stack>
        )}
      </Stack>

    </>
  )
}
