export const dataPart1 = {
  id: 1,
  orderNumberType: 'big',
  colorNumberType: 'big',
  numberOfNumbersToFind: 50,
  rows: [
    {
      bigColor: 'red',
      bigNumbers: [
        16,
        50,
        13,
        27,
        24,
        37,
        44,
        20,
        30,
        54
      ]
    },
    {
      bigColor: 'yellow',
      bigNumbers: [
        38,
        10,
        19,
        57,
        33,
        29,
        15,
        47,
        41,
        52
      ]
    },
    {
      bigColor: 'green',
      bigNumbers: [
        14,
        51,
        42,
        34,
        56,
        26,
        31,
        45,
        23,
        48
      ]
    },
    {
      bigColor: 'white',
      bigNumbers: [
        53,
        40,
        32,
        11,
        21,
        18,
        35,
        58,
        49,
        46
      ]
    },
    {
      bigColor: 'blue',
      bigNumbers: [
        22,
        12,
        25,
        39,
        17,
        36,
        55,
        59,
        43,
        28
      ]
    }
  ]
}

export const dataPart2 = {
  id: 1,
  orderNumberType: 'big',
  colorNumberType: 'small',
  numberOfNumbersToFind: 50,
  rows: [
    {
      bigColor: 'red',
      bigNumbers: [
        16,
        50,
        13,
        27,
        24,
        37,
        44,
        20,
        30,
        54
      ],
      smallColor: 'white',
      smallNumbers: [
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19
      ]
    },
    {
      bigColor: 'yellow',
      bigNumbers: [
        38,
        10,
        19,
        57,
        33,
        29,
        15,
        47,
        41,
        52
      ],
      smallColor: 'blue',
      smallNumbers: [
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29
      ]
    },
    {
      bigColor: 'green',
      bigNumbers: [
        14,
        51,
        42,
        34,
        56,
        26,
        31,
        45,
        23,
        48
      ],
      smallColor: 'red',
      smallNumbers: [
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39
      ]
    },
    {
      bigColor: 'white',
      bigNumbers: [
        53,
        40,
        32,
        11,
        21,
        18,
        35,
        58,
        49,
        46
      ],
      smallColor: 'green',
      smallNumbers: [
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49
      ]
    },
    {
      bigColor: 'blue',
      bigNumbers: [
        22,
        12,
        25,
        39,
        17,
        36,
        55,
        59,
        43,
        28
      ],
      smallColor: 'yellow',
      smallNumbers: [
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        59
      ]
    }
  ]
}
