import React from 'react'
import { Trans, t } from '@lingui/macro'
import EndScreen from '@root/components/molecules/EndScreen/Component'
import Introduction from '@root/components/molecules/Introduction/Component'
import { VdaView } from '@root/components/organisms/VdaView/Component'

import { useAppContext } from '@root/global/context'
import { ProgressStateVda } from '@root/misc/constants'
import { checkTitle } from '@root/misc/helpers'
import { useLocation, useParams } from 'react-router-dom'

// For each different step in a test, this file renders the HTML for that specific step.
export function Vda (): JSX.Element {
  const { progressStateVda, setProgressStateVda, vdaData } = useAppContext()
  const location = useLocation()
  const { variant } = useParams()

  if (progressStateVda === ProgressStateVda.Introduction) {
    return (
      <Introduction
        key='vda1'
        nextFn={() => setProgressStateVda(ProgressStateVda.InstructionPart1)}
        title={checkTitle(location.pathname)}
        label={t({ id: 'vda.introduction.title', message: 'Lees de instructie' })}
        description={(
          <p>
            <Trans id='vda.introduction'>
              Bij deze test gaat het om uw langdurige aandacht. In de test moet u soms reageren op patronen met
              lichtsignalen.<br /><br />
              Er zijn twee soorten signalen: series en velden.<br /><br />
              Een veld is een groep signalen die tegelijk worden getoond.<br />
              Een serie is een reeks van 3 signalen die achter elkaar worden getoond.
            </Trans>
          </p>
        )}
      />
    )
  }

  if (progressStateVda === ProgressStateVda.InstructionPart1) {
    return (
      <Introduction
        key='vda21'
        nextFn={() => setProgressStateVda(ProgressStateVda.Practise1)}
        title={checkTitle(location.pathname)}
        label={t({ id: 'vda.instruction.title.part1', message: 'Oefenen met velden' })}
        description={(
          <p>
            <Trans id='vda.instruction.part1'>
              Een veld is een groep lampjes die tegelijk worden getoond. Het gaat om een aantal lampjes met
              verschillende kleuren. Deze groep lampjes worden gedurende 2 seconden getoond. U moet reageren
              als er 5 lampjes zijn die dezelfde kleur hebben.<br /><br />
              Als er 5 lampjes dezelfde kleur hebben drukt u op de “L” toets van het toetsenbord.<br />
              Als er geen 5 lampjes zijn met dezelfde kleur hoeft u niets te doen.<br /><br />
              U krijgt nu een aantal velden om te oefenen.
            </Trans>
          </p>
        )}
      />
    )
  }

  if (progressStateVda === ProgressStateVda.Practise1) {
    return (
      <VdaView part={1} key='practise-test1' isPractise endFn={() => setProgressStateVda(ProgressStateVda.InstructionPart2)} />
    )
  }

  if (progressStateVda === ProgressStateVda.InstructionPart2) {
    return (
      <Introduction
        key='vda22'
        nextFn={() => setProgressStateVda(ProgressStateVda.Practise2)}
        title={checkTitle(location.pathname)}
        label={t({ id: 'vda.instruction.title.part2', message: 'Oefenen met series' })}
        description={(
          <>
            <p>
              <Trans id='vda.instruction.part2'>
                Een serie is een groep van drie lampjes die achter elkaar worden getoond. Elk lampje verschijnt een
                halve seconde, daarna komt de volgende. De lampjes hebben verschillende kleuren. U moet alleen
                reageren als de drie lampjes in de volgorde groen-geel-rood worden getoond.<br /><br />
                Als de 3 lampjes in de volgorde groen-geel-rood verschijnen drukt u op de “H” toets van het
                toetsenbord.<br />
                Als de lampjes in een andere volgorde verschijnen hoeft u niets te doen.<br /><br />
                U krijgt nu een aantal series om te oefenen.
              </Trans>
            </p>
          </>
        )}
      />
    )
  }
  if (progressStateVda === ProgressStateVda.Practise2) {
    return (
      <VdaView part={2} key='practise-test2' isPractise endFn={() => setProgressStateVda(ProgressStateVda.InstructionPart3)} />
    )
  }

  if (progressStateVda === ProgressStateVda.InstructionPart3) {
    return (
      <Introduction
        key='vda22'
        nextFn={() => setProgressStateVda(ProgressStateVda.Practise3)}
        title={checkTitle(location.pathname)}
        label={t({ id: 'vda.instruction.title.part3', message: 'Oefenen met velden en series' })}
        description={(
          <>
            <p>
              <Trans id='vda.instruction.part3'>
                In de test worden velden en series tegelijk getoond. Eerst verschijnt een veld, en een halve seconde
                later begint de serie.<br /><br />
                Als een veld wordt getoond met 5 lampjes met dezelfde kleur drukt u op de “L” toets.<br />
                Als de serie verschijnt in de volgorde groen-geel-rood drukt u op de “H” toets.<br />
                Als een veld met 5 dezelfde lampjes verschijnt, en ook een serie groen-geel-rood, dan drukt u op de
                “L” en de “H” toets.<br /><br />
                U krijgt nu een oefening met velden en series. Dit is net als bij de echte test, alleen gaat het in de
                echte test sneller.
              </Trans>
            </p>
          </>
        )}
      />
    )
  }

  if (progressStateVda === ProgressStateVda.Practise3) {
    return (
      <VdaView part={3} key='practise-test3' isPractise endFn={() => setProgressStateVda(ProgressStateVda.InstructionPart4)} />
    )
  }

  if (progressStateVda === ProgressStateVda.InstructionPart4) {
    return (
      <Introduction
        key='vda22'
        nextFn={() => setProgressStateVda(ProgressStateVda.Test)}
        title={checkTitle(location.pathname)}
        label={t({ id: 'vda.instruction.title.part4', message: 'Start de test' })}
        description={(
          <>
            <p>
              <Trans id='vda.instruction.part4'>
                Nog een keer een samenvatting:<br /><br />
                Beeld<br />
                -----<br />
                Velden vijf gelijke lampen Linkertoets ('L')<br /><br />
                Reactie<br />
                ------------<br />
                Serie volgorde groen-geel-rood Rechtertoets('H')<br /><br />

                U kunt nu met de echte test beginnen. Deze test duurt ca 30 minuten.
              </Trans>
            </p>
          </>
        )}
      />
    )
  }

  if (progressStateVda === ProgressStateVda.End) {
    return (
      <EndScreen type='vda' variant={variant ?? 'on-location'} data={vdaData} />
    )
  }

  return (
    <VdaView key='test' endFn={() => setProgressStateVda(ProgressStateVda.End)} />
  )
}
