import React from 'react'
import ReactDOM from 'react-dom/client'
import '@styles/index.css'
import Router from './Router'
import ContextWrapper from './components/templates/ContextWrapper/Component'
import I18nWrapper from './components/templates/I18nWrapper/Component'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

// The website is wrap in two layers of provider.
// The Context Wrapper provides the website with access to a global React.Context
// The I18n Wrapper provides the translations
// Teh rest of the website is provided through a Router

root.render(
  <ContextWrapper>
    <I18nWrapper>
      <Router />
    </I18nWrapper>
  </ContextWrapper>
)
