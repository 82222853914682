import React from 'react'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { data, testData1, testData2, testData3 } from './data'
import { hasValue, splitToNChunks } from '@root/misc/helpers'
import style from './style.module.css'
import { useAppContext } from '@root/global/context'
import { Tone, VdaDataEntry, VdaInputField, VdaTimes } from '@root/misc/types'
import { vdaParser } from './parser'
import { Trans } from '@lingui/react'
import { modalStyle, ProgressStateVda } from '@root/misc/constants'

/*
 This is the Vda Test.

 This test consists of two 'parts'.
 Every 2.5seconds, a new stimulus is shown. There are a number of static circles with a certain color.
 After 0.5seconds, a new circle is shown. This circle is a certain color. After 0.5 seconds another one shows, and after another 0.5seconds the third circle shows itself.

 There are 4 types of user feedback:
 - Nothing is pressed.
 - The 'L' button is pressed. This is when there are 5 circles with the same color among the static circles
 - The 'H' button is pressed. This is when the 3 circles that come after each other are 'green', 'yellow', 'red', in that order.
 - Both are pressed. This is when both the above are true.

 There should be 89 stimuli in total. The test is divided up in 3 parts, this is to check if the user attention is the same among the 3 parts.

 */

interface Props {
  endFn: () => void
  isPractise?: boolean
  part?: number
}
type PossibleAnswer = 'none' | 'L' | 'H' | 'LH' | 'first-entry'
interface GivenAnswer {
  input: PossibleAnswer
  askedSignal: PossibleAnswer
  itemNumber: number
  isCorrect: boolean
  actualSignalTimesMS?: VdaTimes
}

const returnAnswer = (val: VdaInputField, isPractise: boolean, part?: number): string => {
  if (isPractise && hasValue(part)) {
    const hasFive = part !== 2 && val.data.field.filter((f: any) => val.data.field.filter(g => g.color === f.color).length >= 5).length > 0
    const hasTheSequence = part !== 1 && val.data.serie.length === 3 && val.data.serie[0].color === '2' && val.data.serie[1].color === '14' && val.data.serie[2].color === '4'
    return hasFive && hasTheSequence ? 'LH' : hasFive ? 'L' : hasTheSequence ? 'H' : 'none'
  } else {
    const hasFive = val.data.field.filter((f: any) => val.data.field.filter(g => g.color === f.color).length >= 5).length > 0
    const hasTheSequence = val.data.serie.length === 3 && val.data.serie[0].color === '2' && val.data.serie[1].color === '14' && val.data.serie[2].color === '4'
    return hasFive && hasTheSequence ? 'LH' : hasFive ? 'L' : hasTheSequence ? 'H' : 'none'
  }
}

const returnElement = (val: number, idx: number): JSX.Element => {
  switch (val) {
    case 4:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-red-500)' }} />
    case 2:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-green-500)' }} />
    case 14:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-yellow-500)' }} />
    case 1:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-blue-500)' }} />
    case 15:
    default:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-white-100)' }} />
  }
}

// const possibleAnswers = [Color.red, Color.green, Color.yellow, Color.blue, Color.white, Tone.low, Tone.high, Peddal.left, Peddal.right]

enum ErrorMessageEnum {
  NONE = 'NONE',
  PART1_L_ENTERED = 'PART1_L_ENTERED',
  PART1_H_ENTERED = 'PART1_H_ENTERED',
  PART1_NONE_ENTERED = 'PART1_NONE_ENTERED',
  PART2_L_ENTERED = 'PART2_L_ENTERED',
  PART2_H_ENTERED = 'PART2_H_ENTERED',
  PART2_NONE_ENTERED = 'PART2_NONE_ENTERED',
  PART3_L_ENTERED = 'PART3_L_ENTERED',
  PART3_H_ENTERED = 'PART3_H_ENTERED',
  PART3_H_ENTERED_L_ASKED = 'PART3_H_ENTERED_L_ASKED',
  PART3_L_ENTERED_H_ASKED = 'PART3_L_ENTERED_H_ASKED',
  PART3_NONE_ENTERED_L_ASKED = 'PART3_NONE_ENTERED_L_ASKED',
  PART3_NONE_ENTERED_H_ASKED = 'PART3_NONE_ENTERED_H_ASKED',
  PART3_NONE_ENTERED_LH_ASKED = 'PART3_NONE_ENTERED_LH_ASKED'
}

export function VdaView (props: Props): JSX.Element {
  const [signalTimes, setSignalTimes] = React.useState<number[]>([])
  const [currentTime, setCurrentTime] = React.useState<Date>(new Date())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setVdaData, setProgressStateVda } = useAppContext()
  const [errorMessage, setErrorMessage] = React.useState<ErrorMessageEnum>(ErrorMessageEnum.NONE)
  const [retryCurrentSignalOpen, setRetryCurrentSignalOpen] = React.useState(false)

  const duration = hasValue(props.isPractise) && props.isPractise ? 1000 : 500

  const [correctOpen, setCorrectOpen] = React.useState(false)
  const [returnOpen, setReturnOpen] = React.useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startTime, setStartTime] = React.useState<Date>(new Date())
  const [resetSignalsPart, setResetSignalsPart] = React.useState<'full' | 'part'>('full')

  const [currentVisibleAnswer, setCurrentVisibleAnswer] = React.useState<number>(-1)
  const [currentSerieIdx, setCurrentSerieIdx] = React.useState<number>(-1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [givenAnswers, setGivenAnswers] = React.useState<GivenAnswer[]>([])
  const [shouldProceed, setShouldProceed] = React.useState<boolean>(false)

  const modalCorrectBtnRef = React.useRef<HTMLButtonElement>(null)
  const modalReturnBtnRef = React.useRef<HTMLButtonElement>(null)
  const modalRetryCurrentSignalBtnRef = React.useRef<HTMLButtonElement>(null)

  const sanitizeAnswers = (answers: GivenAnswer[]): VdaDataEntry[] => {
    return dataToUse.map((d: number, idx: number): VdaDataEntry => {
      const givenAnswer = answers.filter(g => g.itemNumber === idx)?.[0]
      const idxNormalized = idx % 5
      const actualSignalTimesMS: VdaTimes = {
        section1: signalTimes[idxNormalized],
        section2: signalTimes[idxNormalized + 1],
        section3: signalTimes[idxNormalized + 2],
        section4: signalTimes[idxNormalized + 3],
        section5: signalTimes[idxNormalized + 4],
        combinedTime: signalTimes[idxNormalized] + signalTimes[idxNormalized + 1] + signalTimes[idxNormalized + 2] + signalTimes[idxNormalized + 3] + signalTimes[idxNormalized + 4]
      }

      const defaultAnswer = {
        itemNumber: idx,
        askedSignal: returnAnswer(dataToUse[idx], props.isPractise ?? false, props.part),
        isCorrect: returnAnswer(dataToUse[idx], props.isPractise ?? false, props.part) === 'none',
        input: 'none' as PossibleAnswer,
        actualSignalTimesMS
      }

      return hasValue(givenAnswer)
        ? {
            ...givenAnswer,
            actualSignalTimesMS
          }
        : defaultAnswer
    }).sort((a: VdaDataEntry, b: VdaDataEntry) => a.itemNumber - b.itemNumber)
  }

  const dataToUse: any = React.useMemo(() => {
    const arrData: any = hasValue(props.isPractise) ? [testData1, testData2, testData3][(props.part ?? 1) - 1] : data
    return vdaParser(arrData)
  }, [props.isPractise])

  const origData: any = React.useMemo(() => {
    return hasValue(props.isPractise) ? testData1 : data
  }, [props.isPractise])

  React.useEffect(() => {
    setTimeout(() => {
      setCurrentVisibleAnswer(0)
    }, 250)
  }, [])

  React.useEffect(() => {
    if (shouldProceed) {
      setVdaData((old: VdaDataEntry[]) => {
        const newArr = sanitizeAnswers(givenAnswers)
        const splittedArr = splitToNChunks(newArr, 3)
        return splittedArr
      })
      props.endFn()
      setShouldProceed(false)
    }
  }, [shouldProceed, dataToUse, givenAnswers, props, setVdaData])

  const incrementCurrentSerieIdx = (): void => {
    setCurrentSerieIdx(old => {
      const newVal = old === 4 ? 0 : old + 1
      return newVal
    }
    )
  }

  const incrementCurrentAnswer = (): void => {
    setCurrentVisibleAnswer(old => old + 1)
  }

  React.useEffect(() => {
    if (!retryCurrentSignalOpen) {
      if (currentVisibleAnswer === dataToUse.length) {
        if (hasValue(props.isPractise)) {
          const sanitizedAnswers = sanitizeAnswers(givenAnswers)
          sanitizedAnswers.length < 10 || sanitizedAnswers.filter(a => !a.isCorrect).length !== 0 ? handleReturnOpen() : handleCorrectOpen()
        } else {
          const thisDate = new Date()
          const difference = Math.abs(thisDate.getTime() - currentTime?.getTime())
          setCurrentTime(thisDate)
          setSignalTimes(old => [...old, difference])
          setShouldProceed(true)
        }
      } else {
        const timerSerie = setTimeout(() => {
          const thisDate = new Date()
          const difference = Math.abs(thisDate.getTime() - currentTime?.getTime())
          setCurrentTime(thisDate)
          setSignalTimes(old => [...old, difference])

          if (currentSerieIdx === 3) {
            if (props.isPractise ?? false) {
              const askedSignal = returnAnswer(dataToUse[currentVisibleAnswer], props.isPractise ?? false, props.part)
              const input = givenAnswers.filter(g => g.itemNumber === currentVisibleAnswer)?.[0]?.input ?? 'none' as PossibleAnswer
              if (props.part === 1) {
                if (input === 'none' && askedSignal !== 'none') {
                  handleRetryCurrentSignalOpen(ErrorMessageEnum.PART1_NONE_ENTERED, 'full')
                  return
                }
              }
              if (props.part === 2) {
                if (input === 'none' && askedSignal !== 'none') {
                  handleRetryCurrentSignalOpen(ErrorMessageEnum.PART2_NONE_ENTERED, 'full')
                  return
                }
              }
              if (props.part === 3) {
                if (input === 'none' && askedSignal === 'L') {
                  handleRetryCurrentSignalOpen(ErrorMessageEnum.PART3_NONE_ENTERED_L_ASKED, 'full')
                  return
                }
                if (input === 'none' && askedSignal === 'H') {
                  handleRetryCurrentSignalOpen(ErrorMessageEnum.PART3_NONE_ENTERED_H_ASKED, 'full')
                  return
                }
                if (input === 'none' && askedSignal === 'LH') {
                  handleRetryCurrentSignalOpen(ErrorMessageEnum.PART3_NONE_ENTERED_LH_ASKED, 'full')
                  return
                }
              }
            }

            incrementCurrentAnswer()
          }
          incrementCurrentSerieIdx()
        }, duration)

        return () => {
          clearTimeout(timerSerie)
        }
      }
    }
  }, [currentSerieIdx, dataToUse.length, origData, props.isPractise, retryCurrentSignalOpen])

  const giveAnswer = React.useCallback((answer: Tone): void => {
    let input = answer === Tone.low ? 'L' : 'H' as PossibleAnswer
    const askedSignal = returnAnswer(dataToUse[currentVisibleAnswer], props.isPractise ?? false, props.part)
    if (currentVisibleAnswer === 0 && !(props.isPractise ?? false)) {
      input = 'first-entry' as PossibleAnswer
    }

    if (props.isPractise ?? false) {
      if (props.part === 1) {
        if (input === 'L' && askedSignal !== 'L') {
          handleRetryCurrentSignalOpen(ErrorMessageEnum.PART1_L_ENTERED, 'part')
          return
        }
        if (input === 'H') {
          handleRetryCurrentSignalOpen(ErrorMessageEnum.PART1_H_ENTERED, 'part')
          return
        }
      }
      if (props.part === 2) {
        if (input === 'H' && askedSignal !== 'H') {
          handleRetryCurrentSignalOpen(ErrorMessageEnum.PART2_H_ENTERED, 'part')
          return
        }
        if (input === 'L') {
          handleRetryCurrentSignalOpen(ErrorMessageEnum.PART2_L_ENTERED, 'part')
          return
        }
      }
      if (props.part === 3) {
        if (input === 'H' && askedSignal === 'L') {
          handleRetryCurrentSignalOpen(ErrorMessageEnum.PART3_H_ENTERED_L_ASKED, 'part')
          return
        }
        if (input === 'L' && askedSignal === 'H') {
          handleRetryCurrentSignalOpen(ErrorMessageEnum.PART3_L_ENTERED_H_ASKED, 'part')
          return
        }
        if (input === 'H' && askedSignal === 'none') {
          handleRetryCurrentSignalOpen(ErrorMessageEnum.PART3_H_ENTERED, 'part')
          return
        }
        if (input === 'L' && askedSignal === 'none') {
          handleRetryCurrentSignalOpen(ErrorMessageEnum.PART3_L_ENTERED, 'part')
          return
        }
      }
    }

    setGivenAnswers((old: GivenAnswer[]): GivenAnswer[] => {
      const prevAnswered = old.filter(o => o.itemNumber === currentVisibleAnswer)
      if (prevAnswered.length > 0) {
        if (prevAnswered[0].input !== 'first-entry') {
          if (prevAnswered[0].input !== input) {
            input = 'LH' as PossibleAnswer
          }
          return old.map(o => o.itemNumber === currentVisibleAnswer
            ? {
                ...o,
                input,
                isCorrect: input !== 'first-entry' ? input === o.askedSignal : true
              }
            : o)
        } else {
          return old
        }
      }

      return [
        ...old,
        {
          itemNumber: currentVisibleAnswer,
          input,
          askedSignal: askedSignal as PossibleAnswer,
          isCorrect: input !== 'first-entry' ? input === askedSignal : true
        }
      ]
    })
  }, [currentVisibleAnswer, dataToUse, origData, startTime])

  const handleUserKeyPress = React.useCallback((event: KeyboardEvent): void => {
    if (event.repeat) { return }

    const possibleKeys = ['F6', 'F7']
    if (possibleKeys.includes(event.key)) {
      event.preventDefault()

      const keyMap = {
        F6: Tone.low,
        F7: Tone.high
      }

      if (!correctOpen && !returnOpen && !retryCurrentSignalOpen) {
        const { key } = event
        giveAnswer((keyMap as any)[key])
      }
    }
  }, [correctOpen, returnOpen, retryCurrentSignalOpen, giveAnswer])

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  const handleCorrectOpen = (): void => {
    setCorrectOpen(true)
    setTimeout(() => {
      modalCorrectBtnRef.current?.focus()
    }, 100)
  }
  const handleCorrectClose = (): void => {
    setCorrectOpen(false)
    props.endFn()
  }

  const handleReturnOpen = (): void => {
    setReturnOpen(true)
    setTimeout(() => {
      modalReturnBtnRef.current?.focus()
    }, 100)
  }
  const handleReturnClose = (): void => {
    setReturnOpen(false)
    setProgressStateVda(ProgressStateVda.InstructionPart1)
  }

  const handleRetryCurrentSignalOpen = (entry: ErrorMessageEnum, revertSignals: 'full' | 'part'): void => {
    setErrorMessage(entry)
    setRetryCurrentSignalOpen(true)
    setResetSignalsPart(revertSignals)
    setTimeout(() => {
      modalRetryCurrentSignalBtnRef.current?.focus()
    }, 100)
  }
  const handleRetryCurrentSignalClose = (): void => {
    const amountToRevert = (resetSignalsPart === 'full' ? 5 : signalTimes.length % 5) * -1
    setSignalTimes(old => old.slice(0, amountToRevert))
    setCurrentSerieIdx(-1)
    const thisDate = new Date()
    setCurrentTime(thisDate)
    setRetryCurrentSignalOpen(false)
  }

  const isPractisePart1 = hasValue(props.isPractise) && props.part === 1
  const isPractisePart2 = hasValue(props.isPractise) && props.part === 2

  return (
    <>

      <Modal
        disableEscapeKeyDown
        disableAutoFocus
        keepMounted
        open={retryCurrentSignalOpen}
        aria-labelledby='modal-return-title'
        aria-describedby='modal-return-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-return-title' variant='h6' component='h2'>
                <Trans id='vda.too_many_mistakes.title'>
                  Helaas!
                </Trans>
              </Typography>
              <Typography id='modal-return-description' sx={{ mt: 2 }}>
                {errorMessage === ErrorMessageEnum.NONE && (
                  <Trans id='vda.retry.none'>
                    Dit is foutmelding
                  </Trans>
                )}

                {errorMessage === ErrorMessageEnum.PART1_NONE_ENTERED && (
                  <Trans id='vda.retry.answer.part1.none'>
                    Er zijn 5 lampjes met dezelfde kleur, U moet dus op de “L” toets drukken. U krijgt het veld opnieuw
                    te zien.
                  </Trans>
                )}

                {errorMessage === ErrorMessageEnum.PART1_L_ENTERED && (
                  <Trans id='vda.retry.answer.part1.L'>
                    Er zijn geen 5 lampjes met dezelfde kleur, U hoeft dus niet te reageren. U krijgt het veld opnieuw te
                    zien.
                  </Trans>
                )}

                {errorMessage === ErrorMessageEnum.PART1_H_ENTERED && (
                  <Trans id='vda.retry.answer.part1.H'>
                    Bij 5 lampjes met dezelfde kleur moet u op de “L” toets drukken. U krijgt het veld opnieuw te zien.
                  </Trans>
                )}

                {errorMessage === ErrorMessageEnum.PART2_NONE_ENTERED && (
                  <Trans id='vda.retry.answer.part2.none'>
                    De drie lampjes zijn in de volgorde groen-geel-rood verschenen, U moet dus op de “H” toets
                    drukken. U krijgt de serie opnieuw te zien.
                  </Trans>
                )}

                {errorMessage === ErrorMessageEnum.PART2_L_ENTERED && (
                  <Trans id='vda.retry.answer.part2.L'>
                    Als de drie lampjes in de volgorde groen-geel-rood verschijnen moet u op de “H” toets drukken. U
                    krijgt de serie opnieuw te zien.
                  </Trans>
                )}

                {errorMessage === ErrorMessageEnum.PART2_H_ENTERED && (
                  <Trans id='vda.retry.answer.part2.H'>
                    De drie lampjes zijn niet in de volgorde groen-geel-rood verschenen, U hoeft dus niet te reageren. U
                    krijgt de serie opnieuw te zien.
                  </Trans>
                )}

                {errorMessage === ErrorMessageEnum.PART3_NONE_ENTERED_L_ASKED && (
                  <Trans id='vda.retry.answer.part3.none_L'>
                    Er zijn 5 lampjes met dezelfde kleur, U moet dus op de “L” toets drukken. U krijgt het scherm opnieuw te zien.
                  </Trans>
                )}

                {errorMessage === ErrorMessageEnum.PART3_NONE_ENTERED_H_ASKED && (
                  <Trans id='vda.retry.answer.part3.none_H'>
                    De drie lampjes zijn in de volgorde groen-geel-rood verschenen, U moet dus op de “H” toets drukken. U krijgt het scherm opnieuw te zien.
                  </Trans>
                )}
                {errorMessage === ErrorMessageEnum.PART3_NONE_ENTERED_LH_ASKED && (
                  <Trans id='vda.retry.answer.part3.none_LH'>
                    Er zijn 5 lampjes met dezelfde kleur, U moet dus op de “L” toets drukken.
                    De drie lampjes zijn in de volgorde groen-geel-rood verschenen, U moet dus ook op de “H” toets drukken. U krijgt het scherm opnieuw te zien.
                  </Trans>
                )}

                {errorMessage === ErrorMessageEnum.PART3_H_ENTERED_L_ASKED && (
                  <Trans id='vda.retry.answer.part3.H_L'>
                    Bij 5 lampjes met dezelfde kleur moet u op de “L” toets drukken. U krijgt het scherm opnieuw te
                    zien.
                  </Trans>
                )}
                {errorMessage === ErrorMessageEnum.PART3_L_ENTERED_H_ASKED && (
                  <Trans id='vda.retry.answer.part3.L_H'>
                    Als de drie lampjes in de volgorde groen-geel-rood verschijnen moet u op de “H” toets drukken. U krijgt het scherm opnieuw te zien.
                  </Trans>
                )}

                {errorMessage === ErrorMessageEnum.PART3_L_ENTERED && (
                  <Trans id='vda.retry.answer.part3.L'>
                    Er zijn geen 5 lampjes met dezelfde kleur, U hoeft dus niet te reageren. U krijgt het scherm opnieuw
                    te zien.
                  </Trans>
                )}

                {errorMessage === ErrorMessageEnum.PART3_H_ENTERED && (
                  <Trans id='vda.retry.answer.part3.L'>
                    De drie lampjes zijn niet in de volgorde groen-geel-rood verschenen, U hoeft dus niet te reageren. U krijgt het scherm opnieuw te zien.
                  </Trans>
                )}

              </Typography>
            </div>
            <Button ref={modalRetryCurrentSignalBtnRef} color='primary' variant='contained' onClick={() => handleRetryCurrentSignalClose()}><Trans id='vda.retry.button'>Opnieuw proberen</Trans></Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        disableEscapeKeyDown
        disableAutoFocus
        keepMounted
        open={returnOpen}
        aria-labelledby='modal-return-title'
        aria-describedby='modal-return-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-return-title' variant='h6' component='h2'>
                <Trans id='vda.too_many_mistakes.title'>
                  Helaas!
                </Trans>
              </Typography>
              <Typography id='modal-return-description' sx={{ mt: 2 }}>
                <Trans id='vda.too_many_mistakes.description'>
                  Je hebt te veel fouten gemaakt. Wellicht heb je de instructie niet helemaal begrepen, lees deze opnieuw.
                </Trans>
              </Typography>
            </div>
            <Button ref={modalReturnBtnRef} color='primary' variant='contained' onClick={() => handleReturnClose()}><Trans id='vda.too_many_mistakes.button'>Naar de instructie</Trans></Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        disableAutoFocus
        keepMounted
        open={correctOpen}
        onClose={handleCorrectClose}
        aria-labelledby='modal-correct-title'
        aria-describedby='modal-correct-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-correct-title' variant='h6' component='h2'>
                <Trans id='vda.correct_practise.title'>
                  Heel goed!
                </Trans>
              </Typography>
              <Typography id='modal-correct-description' sx={{ mt: 2 }}>
                <Trans id='vda.correct_practise.description'>
                  Je hebt de oefening voltooid. Druk op 'Doorgaan' om de test te maken.
                </Trans>
              </Typography>
            </div>
            <Button ref={modalCorrectBtnRef} color='primary' variant='contained' onClick={() => handleCorrectClose()}><Trans id='vda.correct_practise.button'>Doorgaan</Trans></Button>
          </Stack>
        </Box>
      </Modal>

      {!(hasValue(process.env.NODE_ENV) && process.env.NODE_ENV === 'production') && (
        <div className='data'>
          <pre>{hasValue(dataToUse[currentVisibleAnswer]) && JSON.stringify({ startTime, currentVisibleAnswer, answer: returnAnswer(dataToUse[currentVisibleAnswer], props.isPractise ?? false, props.part), givenAnswers, signalTimes }, null, 2)}</pre>
        </div>
      )}

      <Stack direction='column' sx={{ height: '100%' }}>
        <Stack sx={{ flex: 1, padding: 2, background: '#B2B2B0', position: 'relative' }} justifyContent={hasValue(props.isPractise) ? 'flex-start' : 'space-between'}>

          {/* <pre>{JSON.stringify(dataToUse[currentVisibleAnswer], null, 2)}</pre> */}
          <div className={style.grid}>

            {
              !isPractisePart1 && hasValue(dataToUse[currentVisibleAnswer]) && hasValue(dataToUse[currentVisibleAnswer].data.serie[currentSerieIdx]) &&
              (
                <div style={{ gridColumn: `${dataToUse[currentVisibleAnswer].data.serie[currentSerieIdx].x as string} / span 1`, gridRow: `${dataToUse[currentVisibleAnswer].data.serie[currentSerieIdx].y as string} / span 1` }}>
                  <Box>
                    {
                      currentVisibleAnswer !== -1 &&
                      !returnOpen &&
                      !correctOpen &&
                      returnElement(parseInt(dataToUse[currentVisibleAnswer].data.serie[currentSerieIdx].color, 10), 123)
                    }
                  </Box>
                </div>
              )
            }

            {
              !isPractisePart2 && hasValue(dataToUse[currentVisibleAnswer]) && currentSerieIdx !== 3 && dataToUse[currentVisibleAnswer].data.field.map((d: any, idx: number) => {
                return (
                  <div style={{ gridColumn: `${d.x as string} / span 1`, gridRow: `${d.y as string} / span 1` }} key={`warrep_${idx}`}>
                    <Box>
                      {
                        currentVisibleAnswer !== -1 &&
                        !returnOpen &&
                        !correctOpen &&
                        returnElement(parseInt(d.color, 10), currentVisibleAnswer)
                      }
                      {!(hasValue(process.env.NODE_ENV) && process.env.NODE_ENV === 'production') && (
                        <>{d.x} - {d.y}</>
                      )}
                    </Box>
                  </div>
                )
              }
              )
            }

          </div>
        </Stack>
      </Stack>
    </>
  )
}
