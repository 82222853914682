export const testData1 = `
$$ X   Y   Col.
** veld 1
   3   1   2
   5   4  14
  11   4  15
   4   4   1
   7   1  14
   2   4   1
  12   5   4
   6   1  15
  10   3   4
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 1
   7   5   4
   5   3  15
  10   5  14
** veld 2
   5   4   4 R
   4   5   2 G
   8   5   2 G
  10   5  14 Y
  12   3  14 Y
   2   4   1 B
   5   2   1 B
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 2
  11   4   2 G
   7   1  14 Y
   3   1   4 R
** veld  3
   3   1   4
  11   2   1
   4   5   2
   8   5  15
  11   4   2
   4   4   4
  10   5   2
  12   3  14
   9   1  14
   6   1  14
  10   3  15
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie  3
   1   5  15
   5   2   1
   7   5   4
** veld  4 
   2   5   4
  11   2  15
   1   3   1
   4   4  14
   7   1  15
  10   5  14
  12   3  14
   9   1   4
   1   5  14
  10   3  14
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie  4
   8   2   2
   8   4  15
   2   4   1
** veld  5
   3   1  14
   5   4   4
   7   5  14
  11   2  14
   2   2   1
   8   5   4
  11   4   2
   1   3   4
   7   1   1
  12   3   2
   2   4   1
   5   2   2
  12   5  15
   8   4  15
  10   3   4
   0   0   0
   0   0   0
   0   0   0
** serie  5
   4   5   2
  10   5  14
   7   3   1
** veld 6
   5   4   4
   7   5   2
  11   2  15
   8   5   2
   8   2   4
  11   4  15
   8   4   2
  10   3   4
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 6
   3   1   4
   6   1  15
   2   2   2
** veld 7 
   3   1  15
   5   4   2
   8   5   2
   8   2   1
  11   4   2
   7   1  14
  10   5  14
  12   3   1
   5   2   1
   7   3   4
   9   1  15
  12   5  15
   1   5   1
   5   3  14
   8   4   4
   0   0   0
   0   0   0
   0   0   0
** serie 7
   4   5   2
   1   3  14
   7   5   4
** veld 8  
   5   4   4
   7   5   4
   4   5   2
   1   3  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie   8
  11   4   2
  12   3  14 Y
  11   2   4
** veld 9
   2   2   2
   4   5   1
   8   5  15
   1   3   1
  10   5  14
  12   3  15
   2   4   2
   5   2  14
   7   3  15
   1   5   2
   5   3   1
   8   4  14
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 9
   4   4  14
   6   1  15
   8   2   2
** veld 10
   2   5  14
   3   1   4
   8   5   2
  11   4  14
   4   4   4
   7   1  15
  10   5   2
  12   3  14
   7   3  14
   5   3  15
   8   4  15
  10   3   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 10
  11   2   4
   4   5   2
  12   5   1
`
export const testData2 = `
$$ X   Y   Col.
** veld  1
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 1
   2   2   2
   1   5  15 W
   1   3  14 Y
** veld 2
   3   1   4
   5   4  15
   2   2   2
   4   5   1
  10   5  15
  12   3   1
   2   4  14
   5   2   1
   7   3   2
   6   1   4
   8   4  14
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 2
   4   4  14
   1   5  15
  11   2   4
** veld 3 
   2   5   4
   3   1  15
   5   4   4
   2   2   2
   4   5   2
   1   5  15
   5   3  15
   6   1  15
   8   4  15
  10   3   4
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 3
   8   2   2
   7   5   4
   5   2   1
** veld 4
   7   5   1
  11   2   4
   2   2   1
   4   5   2
  11   4  14
   4   4   2
   7   1  15
  10   5  14
   2   4  14
   7   3   2
   9   1   1
   5   3   4
   6   1  15
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 4
   5   2   1
   5   4   4
   8   2   2
** veld 5
   5   4   1
   7   5   1
  11   2   1
   2   2   2
   8   2   4
   1   3  14
   4   4  14
   7   1   2
   2   4   4
   5   2  14
  12   5  15
   1   5  15
   6   1   4
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 5
  10   3  15
  11   4   2
   9   1   1
** veld 6 
   7   5   1
  11   4   4
   1   3   1
   4   4   1
   7   1  14
  12   3   2
   7   3  15
   9   1  14
  12   5  14
   5   3  14
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 6
   8   5   2
  10   5  14
   2   5   4
** veld 7
   2   5   4
   3   1  14
   7   5   4
  11   2  15
   8   2  15
   4   4  15
   2   4   2
   5   2   4
   9   1   1
   5   3   4
   6   1  15
   8   4   1
  10   3   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 7
   1   5  15
   7   3   1
   5   4   4
** veld 8
   5   4   2
   7   5  14
  11   2   4
   2   2   4
   4   5  14
   8   5   1
   1   3   2
  10   5   2
  12   3   1
   2   4   4
  12   5  14
   8   4  15
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 8
   3   1   4
   7   1  14
   5   3  15
** veld 9  
   5   4  15
   7   5   4
  11   4  15
   1   3   2
   4   4  15
   9   1  15
  12   5  14
   1   5   1
   5   3  14
   6   1   4
   8   4   1
  10   3  15
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 9
   4   5   2
  10   5  14
   2   5   4
** veld 10
   2   5   1
   3   1  15
   4   5  15
   8   5  15
   8   2   4
  11   4   2
   7   1   4
  10   5   1
   2   4   2
   9   1   2
   1   5  14
   6   1  14
   8   4   2
  10   3  15
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 10
   4   4  14
   5   3  15
  11   2   4
`
export const testData3 = `
$$ X   Y   Col.
** veld 1
   3   1   2
   5   4  14
  11   4  15
   4   4   1
   7   1  14
   2   4   1
  12   5   4
   6   1  15
  10   3   4
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 1
   7   5   4
   5   3  15
  10   5  14
** veld 2
   5   4   4 R
   4   5   2 G
   8   5   2 G
  10   5  14 Y
  12   3  14 Y
   2   4   1 B
   5   2   1 B
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 2
  11   4   2 G
   7   1  14 Y
   3   1   4 R
** veld  3
   3   1   4
  11   2   1
   4   5   2
   8   5  15
  11   4   2
   4   4   4
  10   5   2
  12   3  14
   9   1  14
   6   1  14
  10   3  15
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie  3
   1   5  15
   5   2   1
   7   5   4
** veld  4 
   2   5   4
  11   2  15
   1   3   1
   4   4  14
   7   1  15
  10   5  14
  12   3  14
   9   1   4
   1   5  14
  10   3  14
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie  4
   8   2   2
   8   4  15
   2   4   1
** veld  5
   3   1  14
   5   4   4
   7   5  14
  11   2  14
   2   2   1
   8   5   4
  11   4   2
   1   3   4
   7   1   1
  12   3   2
   2   4   1
   5   2   2
  12   5  15
   8   4  15
  10   3   4
   0   0   0
   0   0   0
   0   0   0
** serie  5
   4   5   2
  10   5  14
   7   3   1
** veld 6
   5   4   4
   7   5   2
  11   2  15
   8   5   2
   8   2   4
  11   4  15
   8   4   2
  10   3   4
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 6
   3   1   4
   6   1  15
   2   2   2
** veld 7 
   3   1  15
   5   4   2
   8   5   2
   8   2   1
  11   4   2
   7   1  14
  10   5  14
  12   3   1
   5   2   1
   7   3   4
   9   1  15
  12   5  15
   1   5   1
   5   3  14
   8   4   4
   0   0   0
   0   0   0
   0   0   0
** serie 7
   4   5   2
   1   3  14
   7   5   4
** veld 8  
   5   4   4
   7   5   4
   4   5   2
   1   3  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie   8
  11   4   2
  12   3  14 Y
  11   2   4
** veld 9
   2   2   2
   4   5   1
   8   5  15
   1   3   1
  10   5  14
  12   3  15
   2   4   2
   5   2  14
   7   3  15
   1   5   2
   5   3   1
   8   4  14
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 9
   4   4  14
   6   1  15
   8   2   2
** veld 10
   2   5  14
   3   1   4
   8   5   2
  11   4  14
   4   4   4
   7   1  15
  10   5   2
  12   3  14
   7   3  14
   5   3  15
   8   4  15
  10   3   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 10
  11   2   4
   4   5   2
  12   5   1
** veld  11
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 11
   2   2   2
   1   5  15 W
   1   3  14 Y
** veld 12
   3   1   4
   5   4  15
   2   2   2
   4   5   1
  10   5  15
  12   3   1
   2   4  14
   5   2   1
   7   3   2
   6   1   4
   8   4  14
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 12
   4   4  14
   1   5  15
  11   2   4
** veld 13 
   2   5   4
   3   1  15
   5   4   4
   2   2   2
   4   5   2
   1   5  15
   5   3  15
   6   1  15
   8   4  15
  10   3   4
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 13
   8   2   2
   7   5   4
   5   2   1
** veld 14
   7   5   1
  11   2   4
   2   2   1
   4   5   2
  11   4  14
   4   4   2
   7   1  15
  10   5  14
   2   4  14
   7   3   2
   9   1   1
   5   3   4
   6   1  15
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 14
   5   2   1
   5   4   4
   8   2   2
** veld 15
   5   4   1
   7   5   1
  11   2   1
   2   2   2
   8   2   4
   1   3  14
   4   4  14
   7   1   2
   2   4   4
   5   2  14
  12   5  15
   1   5  15
   6   1   4
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 15
  10   3  15
  11   4   2
   9   1   1
** veld 16 
   7   5   1
  11   4   4
   1   3   1
   4   4   1
   7   1  14
  12   3   2
   7   3  15
   9   1  14
  12   5  14
   5   3  14
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 16
   8   5   2
  10   5  14
   2   5   4
** veld 17
   2   5   4
   3   1  14
   7   5   4
  11   2  15
   8   2  15
   4   4  15
   2   4   2
   5   2   4
   9   1   1
   5   3   4
   6   1  15
   8   4   1
  10   3   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 17
   1   5  15
   7   3   1
   5   4   4
** veld 18
   5   4   2
   7   5  14
  11   2   4
   2   2   4
   4   5  14
   8   5   1
   1   3   2
  10   5   2
  12   3   1
   2   4   4
  12   5  14
   8   4  15
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 18
   3   1   4
   7   1  14
   5   3  15
** veld 19  
   5   4  15
   7   5   4
  11   4  15
   1   3   2
   4   4  15
   9   1  15
  12   5  14
   1   5   1
   5   3  14
   6   1   4
   8   4   1
  10   3  15
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 19
   4   5   2
  10   5  14
   2   5   4
** veld 20
   2   5   1
   3   1  15
   4   5  15
   8   5  15
   8   2   4
  11   4   2
   7   1   4
  10   5   1
   2   4   2
   9   1   2
   1   5  14
   6   1  14
   8   4   2
  10   3  15
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie 20
   4   4  14
   5   3  15
  11   2   4
`

export const data = `
$$ X   Y   Col.
** veld :    1
   3   1   4 R
   5   4   4 R
   7   5   4 R
   2   2   2 G
   8   2   2 G
  11   4   2 G
  12   3  14 Y
   5   2   1 B
  12   5   1 B
   1   5  15 W
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :    1
   4   4  14 Y
   7   3   1 B
   2   5   4 R
** veld :    2
   2   5   4 R
   5   4   4 R
   7   5   4 R
  11   2   4 R
   0   0   0
   4   5   2 G
   8   5   2 G
  11   4   2 G
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1 B
   5   2   1 B
  12   5   1 B
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
** serie :    2
   8   4  15 W
   8   2   2 G
   7   3   1 B
** veld :  224
   2   5   4
   3   1   4
   5   4   4
   7   5   4
   4   5   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
   7   3   1
  12   5   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  224
   9   1   1
  10   3  15 W
  12   3  14 Y
** veld :    4
   2   5   4 R
   3   1   4 R
   8   2   2 G
  11   4   2 G
   2   4   1 B
   7   3   1 B
  12   5   1 B
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :    4
   9   1   1 B
   7   1  14 Y
   2   2   2 G
** veld :    5
   5   4   4 R
   4   5   2 G
   8   5   2 G
  10   5  14 Y
  12   3  14 Y
   2   4   1 B
   5   2   1 B
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :    5
  11   4   2 G
   7   1  14 Y
   3   1   4 R
** veld :    6
   5   4   4 R
   7   5   4 R
   8   5   2 G
   4   4  14 Y
   9   1   1 B
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :    6
   8   4  15 W
  11   2   4 R
   2   4   1 B
** veld :    7
   2   5   4 R
   3   1   4 R
   7   5   4 R
   2   2   2 G
   4   5   2 G
   8   5   2 G
  11   4   2 G
   1   3  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1 B
   7   3   1 B
  12   5   1 B
   1   5  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :    7
   4   4  14 Y
   5   4   4 R
  10   3  15 W
** veld :  298
   2   5   4
   3   1   4
   8   2   2
   1   3  14 Y
   7   1  14 Y
   5   2   1
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  298
  12   3  14 Y
   4   5   2
   7   5   4
** veld :    9
   7   5   4 R
  11   2   4 R
   8   5   2 G
   8   2   2 G
  11   4   2 G
   1   3  14 Y
  12   3  14 Y
   7   3   1 B
  12   5   1 B
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :    9
   6   1  15 W
   2   2   2 G
  10   5  14 Y
** veld :   10
   3   1   4 R
   5   4   4 R
   8   5   2 G
   1   3  14 Y
  12   3  14 Y
   2   4   1 B
   5   2   1 B
   7   3   1 B
   9   1   1 B
  12   5   1 B
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   10
  11   4   2 G
   7   5   4 R
   1   5  15 W
** veld :  294
   5   4   4
   7   5   4
  11   2   4
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
** serie :  294
   2   5   4
   7   1  14 Y
   5   3  15 W
** veld :   12
   5   4   4
  11   2   4
   4   5   2
   8   2   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1 B
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   12
   2   5   4
   8   4  15 W
  11   4   2
** veld :   13
   2   5   4
   3   1   4
   5   4   4
   7   5   4
   2   2   2
   4   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   7   3   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   13
   8   5   2
   5   2   1
  10   3  15 W
** veld :   14 Y
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   14 Y
   2   2   2
   1   5  15 W
   1   3  14 Y
** veld :   15 W
   5   4   4
   7   5   4
  11   2   4
   8   5   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   9   1   1 B
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   15 W
   2   5   4
  12   5   1
   1   5  15 W
** veld :   16
   2   5   4
   3   1   4
   2   2   2
   8   2   2
   1   3  14 Y
   2   4   1
   5   2   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   16
   8   5   2
   7   1  14 Y
  11   2   4
** veld :  262
   3   1   4
   5   4   4
  11   2   4
   8   2   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  262
   8   4  15 W
   4   5   2
   7   5   4
** veld :   18
   5   4   4
   7   5   4
  11   2   4
   8   5   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   9   1   1 B
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   18
   8   2   2
   4   4  14 Y
   3   1   4
** veld :   19
   3   1   4
   7   5   4
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   2   4   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   19
   9   1   1 B
   2   2   2
  11   2   4
** veld :   20
   3   1   4
   7   5   4
   4   4  14 Y
   7   1  14 Y
   2   4   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   20
   2   2   2
   1   3  14 Y
   9   1   1 B
** veld :   21
   2   5   4
   5   4   4
   7   5   4
  11   2   4
   1   3  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   21
   9   1   1 B
   7   1  14 Y
   2   2   2
** veld :   22
   3   1   4
   4   5   2
   8   5   2
   8   2   2
   7   1  14 Y
  10   5  14 Y
   7   3   1
  12   5   1
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   22
   8   4  15 W
  11   2   4
   2   2   2
** veld :   23
   2   5   4
   5   4   4
  11   2   4
   2   2   2
   8   5   2
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   9   1   1 B
  12   5   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   23
   7   3   1
  10   3  15 W
  11   4   2
** veld :   24
   2   5   4
   3   1   4
  11   2   4
   8   5   2
   8   2   2
   7   1  14 Y
  12   3  14 Y
   7   3   1
   9   1   1 B
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   24
   6   1  15 W
   2   2   2
  10   5  14 Y
** veld :   25
   3   1   4
   7   5   4
  11   2   4
   8   5   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   25
   2   5   4
   5   3  15 W
   9   1   1 B
** veld :   26
   2   5   4
   3   1   4
   5   4   4
  11   2   4
   4   5   2
   8   5   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   2   4   1
   9   1   1 B
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :   26
   7   5   4
  10   3  15 W
   5   2   1
** veld :  272
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   1   3  14 Y
   7   1  14 Y
   5   2   1
   9   1   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  272
   1   5  15 W
   8   2   2
  12   3  14 Y
** veld :   28
   3   1   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   4   4  14 Y
   7   1  14 Y
   2   4   1
   7   3   1
  12   5   1
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   28
   8   5   2
  10   5  14 Y
   5   2   1
** veld :   29
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
  11   4   2
   4   4  14 Y
   7   1  14 Y
   7   3   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   29
   4   5   2
  10   5  14 Y
   2   4   1
** veld :   30
  11   2   4
  11   4   2
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1 B
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   30
   7   5   4
   1   5  15 W
   4   5   2
** veld :   31
   2   5   4
   5   4   4
   7   5   4
  11   2   4
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
** serie :   31
  10   3  15 W
  12   3  14 Y
   2   2   2
** veld :  233
   5   4   4
   7   5   4
   1   3  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  233
   1   5  15 W
   7   1  14 Y
  11   2   4
** veld :   33
   3   1   4
  11   2   4
   4   5   2
   8   5   2
  11   4   2
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   9   1   1 B
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   33
   1   5  15 W
   5   2   1
   7   5   4
** veld :   34
   2   5   4
   3   1   4
  11   2   4
   2   2   2
   8   2   2
   7   1  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1 B
  12   5   1
   1   5  15 W
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   34
   7   5   4
   6   1  15 W
  10   5  14 Y
** veld :   35
   2   5   4
   5   4   4
   2   2   2
   4   5   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   7   3   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   35
   7   1  14 Y
  11   2   4
   1   5  15 W
** veld :   36
   3   1   4
   7   5   4
  11   2   4
   8   5   2
   8   2   2
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :   36
  10   3  15 W
   2   2   2
   4   4  14 Y
** veld :  189
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   4   5   2
   8   5   2
   7   1  14 Y
   2   4   1
   7   3   1
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  189
  11   4   2
   4   4  14 Y
   5   2   1
** veld :   38
  11   2   4
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
  12   5   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   38
   7   5   4
   7   3   1
   1   3  14 Y
** veld :  309
   2   5   4
   3   1   4
   7   5   4
  11   2   4
   7   1  14 Y
  10   5  14 Y
   2   4   1
   9   1   1
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  309
  12   3  14 Y
   1   5  15 W
  11   4   2
** veld :   40
   2   5   4
   5   4   4
  11   2   4
   8   2   2
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1 B
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   40
  12   5   1
   3   1   4
   1   5  15 W
** veld :   41
   3   1   4
   7   5   4
   2   2   2
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1 B
  12   5   1
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :   41
  10   3  15 W
   5   2   1
   4   5   2
** veld :  392
   2   5   4
   3   1   4
   5   4   4
   7   5   4
   4   5   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   5   2   1
  12   5   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  392
  12   3  14 Y
   8   5   2
   5   3  15 W
** veld :   43
   3   1   4
   7   5   4
   2   2   2
   4   5   2
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :   43
  11   4   2
   1   3  14 Y
   5   4   4
** veld :   44
   5   4   4
   7   5   4
  11   2   4
   4   5   2
   8   5   2
   8   2   2
   4   4  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   44
   7   1  14 Y
   1   5  15 W
   3   1   4
** veld :   45
   2   5   4
   3   1   4
  11   2   4
   2   2   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   5   2   1
   9   1   1 B
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   45
  12   5   1
   7   5   4
   1   3  14 Y
** veld :   46
   7   5   4
  11   2   4
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   9   1   1 B
  12   5   1
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   46
   5   2   1
   1   5  15 W
   7   1  14 Y
** veld :   47
   2   5   4
   3   1   4
   5   4   4
   8   5   2
   8   2   2
  11   4   2
  10   5  14 Y
   5   2   1
   9   1   1 B
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   47
   8   4  15 W
   2   4   1
  11   2   4
** veld :   48
   3   1   4
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   48
   2   2   2
  11   2   4
   7   3   1
** veld :   49
   5   4   4
  11   2   4
   4   5   2
   8   2   2
   1   3  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1 B
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   49
   7   1  14 Y
   2   4   1
   7   5   4
** veld :  408
   5   4   4
  11   2   4
   2   2   2
   4   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  408
   2   5   4
   1   5  15 W
   5   2   1
** veld :   51
   3   1   4
   4   5   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   51
   2   2   2
   4   4  14 Y
   6   1  15 W
** veld :   52
   5   4   4
   2   2   2
   4   5   2
   8   5   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
  12   5   1
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   52
   2   4   1
   7   1  14 Y
   8   2   2
** veld :  339
   5   4   4
   7   5   4
  11   2   4
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   5   2   1
   9   1   1
  12   5   1
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  339
   1   5  15 W
   2   5   4
  12   3  14 Y
** veld :   54
   5   4   4
   7   5   4
   4   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
   2   4   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   54
   5   2   1
   2   2   2
  12   3  14 Y
** veld :   55
   2   5   4
   3   1   4
   7   5   4
  11   2   4
   8   2   2
   4   4  14 Y
   2   4   1
   5   2   1
   9   1   1 B
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   55
   8   5   2
  12   3  14 Y
   1   5  15 W
** veld :  377
   2   5   4
   3   1   4
   5   4   4
   7   5   4
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
  12   5   1
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  377
   7   3   1
   1   5  15 W
   1   3  14 Y
** veld :   57
   3   1   4
   5   4   4
   2   2   2
   4   5   2
   1   3  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
  12   5   1
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   57
  11   4   2
  10   5  14 Y
   6   1  15 W
** veld :   58
   2   5   4
   7   5   4
  11   2   4
   2   2   2
   8   5   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1 B
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   58
   5   3  15 W
  12   5   1
  10   5  14 Y
** veld :  296
   2   5   4
   5   4   4
   2   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  296
   8   2   2
   2   4   1
   1   3  14 Y
** veld :   60
   2   5   4
   3   1   4
   7   5   4
   2   2   2
   4   5   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   60
   8   5   2
   7   1  14 Y
   1   5  15 W
** veld :   61
   2   5   4
   7   5   4
   4   5   2
  11   4   2
   4   4  14 Y
  10   5  14 Y
   2   4   1
   7   3   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   61
  11   2   4
   5   2   1
   8   2   2
** veld :   62
   2   5   4
   7   5   4
  11   2   4
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
   5   2   1
   9   1   1 B
   1   5  15 W
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   62
   1   3  14 Y
   3   1   4
   4   5   2
** veld :   63
   2   5   4
   5   4   4
   4   5   2
   8   2   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
   2   4   1
   9   1   1 B
  12   5   1
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   63
   3   1   4
   1   5  15 W
   7   3   1
** veld :   64
   5   4   4
   7   5   4
   4   5   2
   1   3  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   64
  11   4   2
  12   3  14 Y
  11   2   4
** veld :   65
   3   1   4
   2   2   2
   4   5   2
   8   5   2
   8   2   2
   4   4  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   65
  11   2   4
   8   4  15 W
  11   4   2
** veld :   66
   3   1   4
   5   4   4
  11   2   4
   2   2   2
   8   2   2
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   66
   1   5  15 W
  10   5  14 Y
  11   4   2
** veld :  478
   2   5   4
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
  12   5   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
** serie :  478
   1   3  14 Y
   5   3  15 W
   8   2   2
** veld :   68
   2   5   4
   7   5   4
   2   2   2
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   5   2   1
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   68
   5   4   4
   4   5   2
  12   5   1
** veld :   69
   3   1   4
   5   4   4
  11   2   4
   4   5   2
   8   5   2
   8   2   2
   1   3  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
  12   5   1
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   69
  10   5  14 Y
   5   2   1
   2   5   4
** veld :   70
   5   4   4
   2   2   2
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
   2   4   1
   7   3   1
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   70
  10   3  15 W
  12   5   1
  11   2   4
** veld :   71
   7   5   4
  11   2   4
   8   5   2
  11   4   2
  10   5  14 Y
  12   3  14 Y
   7   3   1
  12   5   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   71
  10   3  15 W
   2   2   2
   7   1  14 Y
** veld :   72
   5   4   4
  11   4   2
   1   3  14 Y
   4   4  14 Y
   5   2   1
   7   3   1
  12   5   1
   1   5  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   72
  12   3  14 Y
   9   1   1 B
   8   5   2
** veld :   73
   2   5   4
   3   1   4
   7   5   4
   4   5   2
   8   5   2
  11   4   2
   7   1  14 Y
   2   4   1
   5   2   1
   7   3   1
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   73
  12   5   1
   5   3  15 W
  11   2   4
** veld :   74
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   8   5   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :   74
   4   5   2
  10   5  14 Y
   7   3   1
** veld :   75
   3   1   4
  11   2   4
   4   5   2
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   9   1   1 B
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   75
   2   5   4
   1   5  15 W
   8   2   2
** veld :   76
  11   2   4
   8   2   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
   2   4   1
   7   3   1
   9   1   1 B
  12   5   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   76
   4   5   2
   4   4  14 Y
   5   4   4
** veld :   77
   7   5   4
   2   2   2
   4   5   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
   9   1   1 B
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   77
  12   5   1
   5   4   4
   1   5  15 W
** veld :   78
   5   4   4
   7   5   4
  11   2   4
   4   5   2
   8   5   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1 B
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   78
   5   3  15 W
  12   5   1
  11   4   2
** veld :   79
   3   1   4
   7   5   4
   2   2   2
   4   5   2
  11   4   2
   4   4  14 Y
  12   3  14 Y
   2   4   1
   9   1   1 B
  12   5   1
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   79
   5   3  15 W
  11   2   4
   7   3   1
** veld :  382
   5   4   4
   7   5   4
  11   2   4
   4   5   2
   8   2   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  382
   1   3  14 Y
   2   2   2
   9   1   1
** veld :   81
   3   1   4
   5   4   4
   2   2   2
   4   5   2
   8   5   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   81
   9   1   1 B
  11   2   4
   1   3  14 Y
** veld :   82
   3   1   4
   7   5   4
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   9   1   1 B
  12   5   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :   82
   2   2   2
   7   3   1
  10   3  15 W
** veld :   83
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   2   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
   2   4   1
   7   3   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   83
   8   5   2
   1   3  14 Y
   9   1   1 B
** veld :   84
   2   5   4
   5   4   4
   7   5   4
   4   5   2
   8   2   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
  12   5   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   84
   5   3  15 W
   9   1   1 B
  11   2   4
** veld :  431
   5   4   4
   7   5   4
   4   5   2
   8   5   2
  10   5  14 Y
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  431
  11   4   2
   7   1  14 Y
   1   5  15 W
** veld :   86
   2   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   5   2   1
  12   5   1
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   86
   1   5  15 W
   8   2   2
  11   2   4
** veld :   87
   3   1   4
   5   4   4
   2   2   2
  11   4   2
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   87
   8   5   2
  12   5   1
   1   3  14 Y
** veld :   88
   2   5   4
   5   4   4
   8   5   2
   8   2   2
   7   1  14 Y
  10   5  14 Y
   5   2   1
  12   5   1
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   88
   1   5  15 W
   7   3   1
  12   3  14 Y
** veld :  306
   2   2   2
   4   5   2
   4   4  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  306
   8   2   2
   7   1  14 Y
   5   3  15 W
** veld :   90
   2   5   4
   3   1   4
   8   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
   5   2   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   90
   9   1   1 B
   5   3  15 W
  11   2   4
** veld :   91
   2   5   4
   3   1   4
   5   4   4
   2   2   2
   4   5   2
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   91
   1   5  15 W
   8   5   2
  11   2   4
** veld :   92
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   4   4  14 Y
   7   1  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
  12   5   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   92
   5   3  15 W
  11   4   2
   2   5   4
** veld :   93
   2   5   4
   5   4   4
  11   2   4
   2   2   2
   4   5   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   7   3   1
  12   5   1
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   93
   8   5   2
   3   1   4
   2   4   1
** veld :   94
   2   5   4
   3   1   4
  11   2   4
   2   2   2
   4   5   2
   7   1  14 Y
  10   5  14 Y
   5   2   1
   9   1   1 B
  12   5   1
   1   5  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   94
   4   4  14 Y
   8   2   2
  10   3  15 W
** veld :   95
   2   5   4
  11   2   4
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   9   1   1 B
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   95
   8   2   2
   8   4  15 W
   2   4   1
** veld :   96
   5   4   4
   7   5   4
   2   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
   2   4   1
   7   3   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   96
   2   5   4
   8   2   2
   1   3  14 Y
** veld :   97
   2   5   4
   5   4   4
   4   5   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
   2   4   1
   9   1   1 B
  12   5   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   97
  10   5  14 Y
   7   3   1
  12   3  14 Y
** veld :   98
   2   5   4
   3   1   4
   7   5   4
   2   2   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   9   1   1 B
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   98
  10   3  15 W
   8   5   2
   5   2   1
** veld :   99
   2   5   4
   7   5   4
   2   2   2
   4   5   2
  11   4   2
   4   4  14 Y
  10   5  14 Y
   5   2   1
   9   1   1 B
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   99
   3   1   4
   1   3  14 Y
   8   2   2
** veld :  100
   2   5   4
   3   1   4
   7   5   4
   2   2   2
   4   5   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  100
   8   5   2
  10   5  14 Y
  12   5   1
** veld :  101
   2   5   4
   3   1   4
   8   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   7   3   1
  12   5   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  101
   6   1  15 W
   5   2   1
  11   2   4
** veld :  102
   5   4   4
   7   5   4
   2   2   2
   8   5   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
  12   5   1
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  102
   1   3  14 Y
   2   5   4
   8   4  15 W
** veld :  103
   3   1   4
   2   2   2
   8   5   2
  10   5  14 Y
   2   4   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  103
   7   5   4
  11   4   2
   1   5  15 W
** veld :  104
   2   5   4
   3   1   4
   4   5   2
   8   5   2
   1   3  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   9   1   1 B
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  104
   5   2   1
  11   4   2
  11   2   4
** veld :  105
   5   4   4
   7   5   4
   2   2   2
   8   5   2
   1   3  14 Y
   4   4  14 Y
   2   4   1
   7   3   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  105
   5   3  15 W
   9   1   1 B
   7   1  14 Y
** veld :  106
   2   5   4
   5   4   4
   7   5   4
   2   2   2
   8   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
   5   2   1
   7   3   1
   9   1   1 B
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  106
  10   5  14 Y
   8   4  15 W
  11   2   4
** veld :  107
   2   5   4
   3   1   4
   5   4   4
   2   2   2
   4   5   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
   7   3   1
   9   1   1 B
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  107
  11   2   4
   8   2   2
   1   5  15 W
** veld :  108
   2   5   4
   3   1   4
   7   5   4
  11   2   4
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   9   1   1 B
  12   5   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
** serie :  108
   5   4   4
   7   3   1
   2   2   2
** veld :  471
   2   5   4
   3   1   4
   4   5   2
   8   5   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   5   2   1
   7   3   1
   9   1   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  471
  12   3  14 Y
   2   4   1
   7   5   4
** veld :  110
   2   5   4
   5   4   4
   8   2   2
   4   4  14 Y
   7   1  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
  12   5   1
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  110
   2   2   2
  10   5  14 Y
   7   5   4
** veld :  111
   7   5   4
  11   2   4
   4   5   2
   8   5   2
   8   2   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   1   5  15 W
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  111
   3   1   4
   8   4  15 W
  12   5   1
** veld :  112
   2   5   4
   3   1   4
   4   5   2
   8   5   2
   8   2   2
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  112
   9   1   1 B
   1   3  14 Y
  11   2   4
** veld :  113
   7   5   4
  11   2   4
   2   2   2
   8   5   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
   5   2   1
   7   3   1
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  113
   4   5   2
  10   3  15 W
  10   5  14 Y
** veld :  114
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   4   5   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
   7   3   1
   9   1   1 B
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  114
   5   2   1
   8   5   2
   1   5  15 W
** veld :  115
   3   1   4
   5   4   4
  11   4   2
   4   4  14 Y
   7   1  14 Y
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  115
   5   2   1
   7   5   4
   1   5  15 W
** veld :  116
   3   1   4
   5   4   4
   7   5   4
   8   5   2
   8   2   2
   7   1  14 Y
  10   5  14 Y
   9   1   1 B
  12   5   1
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  116
   6   1  15 W
   2   4   1
  12   3  14 Y
** veld :  117
   2   5   4
   3   1   4
   7   5   4
   2   2   2
   8   5   2
   8   2   2
   1   3  14 Y
  10   5  14 Y
   7   3   1
   9   1   1 B
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  117
   5   3  15 W
  11   2   4
   5   2   1
** veld :  118
   2   5   4
   3   1   4
   5   4   4
  11   2   4
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   7   3   1
   9   1   1 B
  12   5   1
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  118
   7   1  14 Y
   1   5  15 W
   7   5   4
** veld :  119
   2   5   4
   3   1   4
   5   4   4
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   9   1   1 B
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  119
   5   2   1
  11   2   4
   2   2   2
** veld :  120
   2   5   4
   3   1   4
   5   4   4
   2   2   2
   4   5   2
   8   5   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1 B
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
** serie :  120
   8   4  15 W
  11   2   4
  10   5  14 Y
** veld :  121
   2   5   4
   3   1   4
   5   4   4
   2   2   2
   8   2   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   9   1   1 B
  12   5   1
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  121
   2   4   1
   6   1  15 W
   7   1  14 Y
** veld :  122
   2   5   4
   3   1   4
   8   5   2
   8   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1 B
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  122
   8   4  15 W
   5   4   4
   2   2   2
** veld :  123
   2   5   4
   5   4   4
  11   2   4
   2   2   2
   8   5   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  123
  11   4   2
   7   1  14 Y
   7   5   4
** veld :  124
   2   2   2
   4   5   2
   8   5   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  124
   4   4  14 Y
   6   1  15 W
   8   2   2
** veld :  125
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
   9   1   1 B
  12   5   1
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  125
   3   1   4
  10   3  15 W
   8   5   2
** veld :  126
   3   1   4
   5   4   4
   7   5   4
   2   2   2
   4   5   2
   2   4   1
   5   2   1
   7   3   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  126
   8   2   2
   1   5  15 W
   9   1   1 B
** veld :  127
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   5   2   1
   7   3   1
   9   1   1 B
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  127
   4   5   2
  10   5  14 Y
  12   5   1
** veld :  128
   2   5   4
   3   1   4
   5   4   4
   2   2   2
   4   5   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   9   1   1 B
  12   5   1
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  128
   5   2   1
   8   2   2
   5   3  15 W
** veld :  129
   3   1   4
  11   2   4
   8   5   2
   8   2   2
  11   4   2
   7   1  14 Y
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  129
   7   5   4
   1   5  15 W
   7   3   1
** veld :  130
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  130
   2   5   4
   1   5  15 W
  11   4   2
** veld :  131
   2   5   4
   7   5   4
   2   2   2
   4   5   2
   8   5   2
   4   4  14 Y
   7   1  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
  12   5   1
   1   5  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  131
  11   4   2
   5   4   4
   5   3  15 W
** veld :  132
   2   5   4
   5   4   4
   7   5   4
   2   2   2
   8   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   9   1   1 B
   1   5  15 W
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  132
   8   4  15 W
   1   3  14 Y
   8   5   2
** veld :  133
   2   5   4
   3   1   4
   5   4   4
   8   2   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
   2   4   1
   5   2   1
  12   5   1
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  133
  12   3  14 Y
   4   5   2
   9   1   1 B
** veld :  134
   2   5   4
   3   1   4
  11   2   4
   2   2   2
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   5   2   1
   7   3   1
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  134
   7   5   4
   4   5   2
   2   4   1
** veld :  135
   2   5   4
   3   1   4
   4   5   2
   8   5   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
   5   2   1
   7   3   1
   9   1   1 B
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  135
  10   5  14 Y
  11   2   4
   8   4  15 W
** veld :  136
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   2   4   1
   5   2   1
   7   3   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  136
   8   5   2
  12   3  14 Y
   2   5   4
** veld :  137
   2   5   4
   3   1   4
   5   4   4
   4   5   2
   8   5   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
   2   4   1
   5   2   1
   7   3   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  137
   5   3  15 W
  11   4   2
  12   3  14 Y
** veld :  138
   2   5   4
   3   1   4
  11   2   4
   4   5   2
   8   5   2
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  138
   4   4  14 Y
  12   5   1
   8   2   2
** veld :  139
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
   5   2   1
   7   3   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  139
   3   1   4
  12   5   1
  12   3  14 Y
** veld :  140
   2   5   4
   3   1   4
   5   4   4
   2   2   2
   8   2   2
  11   4   2
   9   1   1 B
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  140
   7   3   1
   1   5  15 W
  11   2   4
** veld :  141
   2   5   4
   3   1   4
   5   4   4
   2   2   2
   4   5   2
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  141
   8   2   2
   7   5   4
   5   2   1
** veld :  142
   3   1   4
   5   4   4
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   9   1   1 B
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  142
   4   5   2
  11   2   4
   2   4   1
** veld :  143
   2   5   4
   3   1   4
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   7   3   1
   9   1   1 B
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  143
  11   2   4
   1   5  15 W
  12   3  14 Y
** veld :  144
   2   5   4
   5   4   4
   4   5   2
   8   5   2
   8   2   2
   4   4  14 Y
  10   5  14 Y
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  144
   5   3  15 W
  11   4   2
   5   2   1
** veld :  145
   3   1   4
   5   4   4
   4   5   2
   8   5   2
   1   3  14 Y
   7   1  14 Y
  12   3  14 Y
   9   1   1 B
  12   5   1
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  145
   8   4  15 W
   5   2   1
  11   2   4
** veld :  146
   3   1   4
   5   4   4
  11   2   4
   2   2   2
   4   4  14 Y
   7   1  14 Y
  12   5   1
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  146
   5   2   1
   8   4  15 W
  12   3  14 Y
** veld :  147
   3   1   4
   5   4   4
  11   2   4
   4   5   2
   8   5   2
   1   3  14 Y
  10   5  14 Y
   2   4   1
   7   3   1
   9   1   1 B
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  147
   4   4  14 Y
  11   4   2
  12   5   1
** veld :  148
   7   5   4
  11   2   4
   4   5   2
   8   5   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   9   1   1 B
  12   5   1
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  148
   3   1   4
   2   4   1
  11   4   2
** veld :  149
   7   5   4
  11   2   4
   4   4  14 Y
   7   1  14 Y
   5   2   1
   9   1   1 B
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  149
   4   5   2
  10   5  14 Y
   2   5   4
** veld :  150
   2   5   4
   3   1   4
   8   5   2
   8   2   2
  12   3  14 Y
   9   1   1 B
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  150
   7   5   4
   5   2   1
   1   5  15 W
** veld :  151
   3   1   4
   5   4   4
   8   5   2
   8   2   2
   1   3  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  151
   9   1   1 B
   4   4  14 Y
  11   2   4
** veld :  152
   2   5   4
   3   1   4
   5   4   4
   8   5   2
   8   2   2
   4   4  14 Y
  12   3  14 Y
   7   3   1
   9   1   1 B
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  152
   7   1  14 Y
  11   2   4
   2   2   2
** veld :  153
   8   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
  12   5   1
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  153
   4   5   2
   1   3  14 Y
   1   5  15 W
** veld :  154
   7   5   4
  11   2   4
   4   5   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  154
   2   5   4
   8   5   2
   1   5  15 W
** veld :  155
   3   1   4
   7   5   4
   8   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
   5   2   1
   7   3   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  155
   5   3  15 W
  12   5   1
  12   3  14 Y
** veld :  156
   2   5   4
   3   1   4
  11   2   4
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   7   3   1
   9   1   1 B
  12   5   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  156
   2   2   2
  10   5  14 Y
  10   3  15 W
** veld :  157
   7   5   4
  11   2   4
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  157
   2   5   4
  10   5  14 Y
  11   4   2
** veld :  158
   8   5   2
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  158
  11   2   4
   1   3  14 Y
  11   4   2
** veld :  159
   5   4   4
   8   2   2
  11   4   2
   7   1  14 Y
   9   1   1
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  159
   4   5   2
   2   4   1
  11   2   4
** veld :  160
   7   5   4
  11   2   4
   4   5   2
   8   5   2
   4   4  14 Y
   5   2   1
   7   3   1
   1   5  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  160
   7   1  14 Y
  11   4   2
   5   3  15 W
** veld :  161
   2   5   4
   3   1   4
   7   5   4
   4   5   2
  11   4   2
   4   4  14 Y
  10   5  14 Y
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  161
   8   2   2
   9   1   1
   1   5  15 W
** veld :  162
   2   5   4
   3   1   4
   8   2   2
  11   4   2
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  162
   2   2   2
   4   4  14 Y
   8   4  15 W
** veld :  163
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   1   3  14 Y
   7   1  14 Y
  12   3  14 Y
   5   2   1
   9   1   1
  12   5   1
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  163
   3   1   4
   1   5  15 W
  11   4   2
** veld :  164
   5   4   4
   7   5   4
  11   4   2
   1   3  14 Y
   4   4  14 Y
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  164
   4   5   2
  10   5  14 Y
   2   5   4
** veld :  165
   3   1   4
   5   4   4
  11   2   4
   2   2   2
   8   5   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   9   1   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  165
   1   5  15 W
  12   5   1
  11   4   2
** veld :  166
   3   1   4
   5   4   4
   2   2   2
   4   5   2
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  166
   4   4  14 Y
   1   5  15 W
  11   2   4
** veld :  167
   3   1   4
   7   5   4
  11   2   4
   4   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
** serie :  167
   2   2   2
   4   4  14 Y
   2   4   1
** veld :  168
   2   5   4
   3   1   4
   8   5   2
   8   2   2
  11   4   2
  10   5  14 Y
  12   3  14 Y
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  168
   5   2   1
   7   5   4
   4   4  14 Y
** veld :  169
   2   5   4
   3   1   4
   2   2   2
   8   5   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   5   2   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  169
   5   3  15 W
   7   5   4
   7   3   1
** veld :  170
   5   4   4
   8   5   2
   8   2   2
   1   3  14 Y
  12   3  14 Y
   7   3   1
  12   5   1
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  170
   9   1   1
   1   5  15 W
  11   2   4
** veld :  171
   7   5   4
  11   2   4
   2   2   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
   2   4   1
   7   3   1
  12   5   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  171
   8   4  15 W
   3   1   4
   4   5   2
** veld :  172
   3   1   4
   7   5   4
   8   5   2
   5   2   1
   7   3   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  172
   5   3  15 W
  11   2   4
   4   4  14 Y
** veld :  173
   2   5   4
   3   1   4
   5   4   4
   4   5   2
   7   1  14 Y
   9   1   1
  12   5   1
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  173
   8   4  15 W
   8   2   2
  11   2   4
** veld :  174
   2   5   4
   3   1   4
   5   4   4
   4   5   2
   8   5   2
   4   4  14 Y
   9   1   1
  12   5   1
   1   5  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  174
   2   4   1
   8   2   2
   5   3  15 W
** veld :  359
   3   1   4
   5   4   4
   8   5   2
   8   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  359
   4   5   2
   1   3  14 Y
   7   5   4
** veld :  176
   3   1   4
   7   5   4
  11   4   2
   1   3  14 Y
  12   3  14 Y
   7   3   1
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  176
  10   5  14 Y
   8   5   2
   2   4   1
** veld :  177
   3   1   4
   8   2   2
   1   3  14 Y
   7   1  14 Y
   7   3   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  177
   5   2   1
  12   3  14 Y
  10   3  15 W
** veld :  178
   2   5   4
   3   1   4
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   9   1   1
  12   5   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  178
   4   5   2
   2   4   1
   7   5   4
** veld :  179
   2   5   4
  11   2   4
   8   5   2
   8   2   2
   4   4  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  179
   7   1  14 Y
   7   5   4
   5   3  15 W
** veld :  180
   4   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
   2   4   1
   9   1   1
  12   5   1
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  180
  12   3  14 Y
   5   2   1
   8   2   2
** veld :  181
   2   5   4
   3   1   4
   2   2   2
   4   5   2
   7   1  14 Y
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  181
   7   5   4
   5   2   1
  10   5  14 Y
** veld :  182
   2   5   4
   2   2   2
   4   5   2
   8   5   2
   8   2   2
  11   4   2
  10   5  14 Y
   5   2   1
   9   1   1
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  182
   7   5   4
   1   5  15 W
   7   1  14 Y
** veld :  183
   5   4   4
   7   5   4
  11   2   4
   8   2   2
  11   4   2
   1   3  14 Y
   5   2   1
   7   3   1
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  183
   6   1  15 W
   8   5   2
   7   1  14 Y
** veld :  184
   2   5   4
   3   1   4
  11   2   4
   2   2   2
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   9   1   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  184
   7   5   4
   4   5   2
   2   4   1
** veld :  185
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   9   1   1
  12   5   1
   1   5  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  185
   4   5   2
  10   5  14 Y
   2   4   1
** veld :  186
   3   1   4
   7   5   4
  11   2   4
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  186
   5   4   4
   4   5   2
  12   5   1
** veld :  187
   3   1   4
  11   2   4
   4   5   2
   8   5   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  187
  11   4   2
   7   1  14 Y
   5   2   1
** veld :  188
   7   5   4
  11   2   4
   4   5   2
   8   2   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  188
   8   5   2
   4   4  14 Y
  12   5   1
** veld :   37
   3   1   4
   5   4   4
   2   2   2
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   5   1
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   37
  12   3  14 Y
   7   3   1
   4   5   2
** veld :  190
   2   5   4
   5   4   4
   1   3  14 Y
   4   4  14 Y
   7   3   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  190
  10   5  14 Y
  10   3  15 W
  12   5   1
** veld :  191
   5   4   4
   7   5   4
  11   2   4
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  191
   8   2   2
   2   5   4
  10   3  15 W
** veld :  192
   5   4   4
  11   2   4
   8   5   2
   4   4  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  192
   7   1  14 Y
  12   5   1
   5   3  15 W
** veld :  193
   3   1   4
   5   4   4
   8   5   2
   7   3   1
   9   1   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  193
  11   4   2
   4   4  14 Y
   2   4   1
** veld :  194
   8   2   2
   4   4  14 Y
   7   1  14 Y
   9   1   1
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  194
  12   3  14 Y
   2   4   1
   1   5  15 W
** veld :  195
  11   2   4
  11   4   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   2   5   4 R
   3   1   4 R
   5   4   4 R
   7   5   4 R
   7   3   1 B
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  195
   2   2   2
   7   1  14 Y
  12   5   1
** veld :  196
   7   5   4
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   9   1   1
  12   5   1
   1   5  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  196
  10   3  15 W
   2   5   4
   4   4  14 Y
** veld :  197
   5   4   4
   7   5   4
   8   5   2
  11   4   2
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  197
   3   1   4
  10   5  14 Y
   1   5  15 W
** veld :  198
   3   1   4
   8   5   2
   8   2   2
   7   1  14 Y
   2   4   1
   5   2   1
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  198
   5   3  15 W
  12   5   1
  11   2   4
** veld :  199
   2   5   4
   7   5   4
   8   5   2
  11   4   2
   7   1  14 Y
   9   1   1
  12   5   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  199
  11   2   4
   5   2   1
  12   3  14 Y
** veld :  200
   3   1   4
   7   5   4
   4   5   2
   1   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  200
  11   4   2
   7   1  14 Y
   2   5   4
** veld :  201
   2   5   4
   3   1   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  201
   5   2   1
  10   5  14 Y
  11   4   2
** veld :  202
   2   5   4
   3   1   4
   7   5   4
   4   5   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  202
   8   4  15 W
  10   5  14 Y
   8   2   2
** veld :  203
   2   5   4
   3   1   4
   4   5   2
   8   5   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   2   4   1
   9   1   1
  12   5   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  203
   7   5   4
   5   2   1
  12   3  14 Y
** veld :  204
   2   5   4
   3   1   4
   5   4   4
   2   2   2
   8   2   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   5   2   1
  12   5   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  204
   5   3  15 W
   7   5   4
   4   4  14 Y
** veld :  205
   2   5   4
   5   4   4
   7   5   4
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   9   1   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
** serie :  205
   5   2   1
  11   2   4
   4   5   2
** veld :  206
   2   5   4
   3   1   4
   7   5   4
   4   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  206
   6   1  15 W
  12   5   1
  12   3  14 Y
** veld :  207
   2   5   4
   3   1   4
   5   4   4
   2   2   2
   8   5   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   5   2   1
   9   1   1
  12   5   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
** serie :  207
   1   3  14 Y
   7   3   1
  11   2   4
** veld :  208
   5   4   4
  11   2   4
   2   2   2
  11   4   2
   7   1  14 Y
   2   4   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  208
  10   5  14 Y
   7   3   1
   5   3  15 W
** veld :  209
   2   5   4
   5   4   4
   4   5   2
   8   5   2
   8   2   2
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  209
   3   1   4
  10   3  15 W
   4   4  14 Y
** veld :  210
   2   5   4
   7   5   4
  11   2   4
   2   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
   2   4   1
  12   5   1
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  210
   7   3   1
   8   5   2
   5   4   4
** veld :  211
   2   5   4
   3   1   4
  11   2   4
   4   5   2
   8   2   2
  11   4   2
  10   5  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  211
   7   1  14 Y
   5   2   1
   5   3  15 W
** veld :  212
   5   4   4
   7   5   4
   2   2   2
   8   5   2
   8   2   2
   2   4   1
  12   5   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  212
   1   3  14 Y
   9   1   1
  11   2   4
** veld :  213
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   4   5   2
   8   2   2
  11   4   2
   4   4  14 Y
  12   3  14 Y
   2   4   1
   9   1   1
  12   5   1
   1   5  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  213
   5   2   1
   8   4  15 W
  10   5  14 Y
** veld :  214
   5   4   4
   7   5   4
  11   2   4
   4   5   2
   8   2   2
   7   1  14 Y
   5   2   1
   7   3   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  214
   3   1   4
   1   5  15 W
  12   5   1
** veld :  215
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
   7   3   1
   9   1   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  215
  12   3  14 Y
   5   2   1
   1   5  15 W
** veld :  216
   3   1   4
   8   2   2
  11   4   2
   7   1  14 Y
   5   2   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  216
   7   5   4
   1   3  14 Y
   2   4   1
** veld :  217
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   1   3  14 Y
  12   3  14 Y
   7   3   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  217
   2   5   4
   4   4  14 Y
   2   4   1
** veld :  218
   4   5   2
   8   5   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   9   1   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  218
  10   3  15 W
   7   5   4
   8   2   2
** veld :  219
   3   1   4
   5   4   4
   8   2   2
  11   4   2
   1   3  14 Y
  12   3  14 Y
   2   4   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  219
   7   1  14 Y
   5   2   1
  11   2   4
** veld :  220
   2   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   9   1   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  220
   1   3  14 Y
   5   3  15 W
   7   3   1
** veld :  221
   2   5   4
   3   1   4
  11   2   4
   2   2   2
   4   5   2
   4   4  14 Y
   7   1  14 Y
   5   2   1
   7   3   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  221
   5   4   4
   1   3  14 Y
   2   4   1
** veld :  222
   2   5   4
   3   1   4
  11   2   4
   8   2   2
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  222
   2   2   2
   0   0   0
   5   4   4
** veld :  223
   2   5   4
   3   1   4
   7   5   4
   2   2   2
   8   5   2
   8   2   2
   1   3  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  223
   7   1  14 Y
   2   4   1
   1   5  15 W
** veld :    3
   3   1   4 R
   5   4   4 R
   7   5   4 R
  11   2   4 R
   2   2   2 G
  11   4   2 G
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1 B
   7   3   1 B
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :    3
   2   5   4 R
   4   4  14 Y
   8   2   2 G
** veld :  225
   2   5   4
   3   1   4
  11   2   4
   8   5   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
   2   4   1
   7   3   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  225
   7   5   4
   9   1   1
   4   5   2
** veld :  226
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
  11   4   2
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
** serie :  226
   8   5   2
   7   1  14 Y
   3   1   4
** veld :  227
   3   1   4
   5   4   4
   7   5   4
   8   2   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  227
   9   1   1
   2   2   2
   1   5  15 W
** veld :  228
   2   5   4
   3   1   4
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  228
   7   5   4
   2   4   1
   7   1  14 Y
** veld :  229
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  229
   9   1   1
   3   1   4
   5   3  15 W
** veld :  230
   2   5   4
   3   1   4
   8   2   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  230
  12   5   1
   5   4   4
   2   2   2
** veld :  231
   8   5   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  231
   2   2   2
  10   3  15 W
  10   5  14 Y
** veld :  232
   2   5   4
   3   1   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  232
  11   4   2
   7   1  14 Y
  10   3  15 W
** veld :   32
   3   1   4
   5   4   4
   7   5   4
   2   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   32
   7   1  14 Y
   8   2   2
   2   4   1
** veld :  234
   3   1   4
   5   4   4
   7   5   4
   2   2   2
   4   5   2
   8   2   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  234
   4   4  14 Y
   2   5   4
   9   1   1
** veld :  235
   2   5   4
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
  11   4   2
   1   3  14 Y
   2   4   1
   7   3   1
   9   1   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  235
  12   5   1
   8   5   2
   5   3  15 W
** veld :  236
   7   5   4
  11   2   4
   2   2   2
   4   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   7   3   1
   9   1   1
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  236
   5   2   1
   5   4   4
   8   2   2
** veld :  237
   5   4   4
   4   5   2
   8   2   2
   4   4  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  237
   2   2   2
  10   5  14 Y
  11   2   4
** veld :  238
   5   4   4
  11   2   4
   2   2   2
   8   5   2
  11   4   2
   7   1  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
  12   5   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  238
   2   5   4
   4   5   2
   1   3  14 Y
** veld :  239
   2   5   4
   2   2   2
  11   4   2
   1   3  14 Y
  12   3  14 Y
   2   4   1
  12   5   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  239
   4   5   2
   9   1   1
  11   2   4
** veld :  240
   3   1   4
   7   5   4
   4   5   2
   8   2   2
  10   5  14 Y
   9   1   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  240
  10   3  15 W
   8   5   2
   5   2   1
** veld :  241
   2   5   4
   5   4   4
   7   5   4
   2   2   2
   8   5   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  241
   3   1   4
   8   2   2
   2   4   1
** veld :  242
   2   5   4
   5   4   4
   2   2   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  242
   8   5   2
   1   3  14 Y
   1   5  15 W
** veld :  243
   3   1   4
   5   4   4
   7   5   4
   8   2   2
   4   4  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   1   5  15 W
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  243
  12   5   1
   7   1  14 Y
   8   4  15 W
** veld :  244
   2   5   4
   8   5   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   9   1   1
  12   5   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  244
   4   5   2
   4   4  14 Y
   5   2   1
** veld :  245
  11   2   4
   8   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  245
   1   3  14 Y
   6   1  15 W
   7   5   4
** veld :  246
   2   5   4
   3   1   4
  11   2   4
   2   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  246
   7   5   4
   4   5   2
   8   4  15 W
** veld :  247
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   9   1   1
  12   5   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
** serie :  247
   7   3   1
   8   2   2
   3   1   4
** veld :  248
   5   4   4
   7   5   4
   2   2   2
   4   5   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  248
   8   4  15 W
   2   4   1
  10   5  14 Y
** veld :  249
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   5   2   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  249
   8   5   2
  11   4   2
   2   5   4
** veld :  250
   2   5   4
   3   1   4
   5   4   4
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  250
   7   5   4
   2   2   2
   8   4  15 W
** veld :  251
   2   5   4
   5   4   4
   7   5   4
   2   2   2
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   9   1   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
** serie :  251
  11   2   4
   7   3   1
  10   3  15 W
** veld :  252
   2   5   4
   3   1   4
   5   4   4
   2   2   2
   8   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
** serie :  252
   4   4  14 Y
   7   5   4
   4   5   2
** veld :  253
   2   5   4
   3   1   4
  11   2   4
   4   5   2
   8   5   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
** serie :  253
   1   5  15 W
   7   5   4
  11   4   2
** veld :  254
   2   5   4
   3   1   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
  12   5   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
** serie :  254
   7   5   4
   5   3  15 W
   7   3   1
** veld :  255
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   4   5   2
   8   5   2
  12   3  14 Y
   2   4   1
   5   2   1
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  255
   2   2   2
   7   3   1
   1   5  15 W
** veld :  256
   7   5   4
  11   2   4
   4   5   2
   8   5   2
  11   4   2
   4   4  14 Y
  10   5  14 Y
   2   4   1
   7   3   1
   1   5  15 W
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  256
   9   1   1
  12   3  14 Y
   3   1   4
** veld :  257
   3   1   4
   5   4   4
   8   5   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   5   2   1
   7   3   1
   9   1   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  257
   5   3  15 W
  11   2   4
   2   2   2
** veld :  258
   7   5   4
  11   2   4
   2   2   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  258
   8   2   2
   4   4  14 Y
   2   5   4
** veld :  259
   3   1   4
   4   5   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
  12   5   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  259
   7   3   1
   2   2   2
  10   5  14 Y
** veld :  260
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
   9   1   1
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  260
   1   5  15 W
   3   1   4
   8   2   2
** veld :  261
   2   5   4
   3   1   4
   4   5   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  261
   4   4  14 Y
   1   5  15 W
   8   5   2
** veld :   17
   5   4   4
  11   2   4
   4   5   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   7   3   1
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   17
   5   2   1
  10   5  14 Y
   2   5   4
** veld :  263
  11   2   4
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
   9   1   1
  12   5   1
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  263
   3   1   4
  10   3  15 W
   2   4   1
** veld :  264
   5   4   4
   2   2   2
  12   3  14 Y
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  264
   9   1   1
   8   5   2
   8   4  15 W
** veld :  265
   2   5   4
   3   1   4
   7   5   4
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
  12   5   1
   1   5  15 W
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  265
  11   2   4
   4   5   2
   9   1   1
** veld :  266
   5   4   4
   7   5   4
  11   2   4
   8   5   2
   8   2   2
  11   4   2
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  266
   3   1   4
   6   1  15 W
   2   2   2
** veld :  267
   2   5   4
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   8   5   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   8   4  15 W
   0   0   0
** serie :  267
   8   2   2
   4   4  14 Y
   6   1  15 W
** veld :  268
   8   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
   7   3   1
  12   5   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  268
   5   3  15 W
  10   5  14 Y
   2   2   2
** veld :  269
   2   5   4
   3   1   4
   5   4   4
   2   2   2
   4   5   2
   8   2   2
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  269
   1   3  14 Y
   8   5   2
   7   3   1
** veld :  270
   2   5   4
   3   1   4
  11   2   4
   8   2   2
  11   4   2
   1   3  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  270
   7   5   4
   4   4  14 Y
   2   4   1
** veld :  271
   2   5   4
  11   2   4
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  271
   9   1   1
   5   4   4
   1   5  15 W
** veld :   27
   2   5   4
  11   2   4
   2   2   2
   4   5   2
   8   2   2
  11   4   2
   7   1  14 Y
   7   3   1
  12   5   1
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   27
   2   4   1
   8   5   2
   8   4  15 W
** veld :  273
   3   1   4
   5   4   4
   2   2   2
   4   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  273
   5   3  15 W
  10   5  14 Y
   2   5   4
** veld :  274
   3   1   4
   5   4   4
   7   5   4
   4   5   2
   8   5   2
  10   5  14 Y
  12   3  14 Y
   5   2   1
   9   1   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  274
  11   4   2
   1   5  15 W
   2   4   1
** veld :  275
   2   5   4
   5   4   4
   8   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
   7   3   1
   9   1   1
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  275
  11   2   4
   5   2   1
   2   2   2
** veld :  276
   3   1   4
   5   4   4
  11   2   4
   2   2   2
   4   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
  12   5   1
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  276
   7   5   4
   5   3  15 W
   8   2   2
** veld :  277
  11   2   4
   4   5   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   5   2   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  277
   8   2   2
  12   3  14 Y
   3   1   4
** veld :  278
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
   2   4   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  278
   5   2   1
   2   5   4
   8   4  15 W
** veld :  279
   2   5   4
   3   1   4
   5   4   4
   8   5   2
   8   2   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  279
   5   3  15 W
  12   5   1
   7   1  14 Y
** veld :  280
   8   5   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  280
   1   5  15 W
   1   3  14 Y
   8   2   2
** veld :  281
   2   5   4
   3   1   4
   5   4   4
   2   2   2
   4   5   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  281
   7   3   1
   1   5  15 W
  11   2   4
** veld :  282
   3   1   4
   5   4   4
  11   2   4
   8   5   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  282
   1   5  15 W
   4   4  14 Y
   7   5   4
** veld :  283
   2   5   4
   3   1   4
   5   4   4
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  283
  10   5  14 Y
  11   2   4
   2   4   1
** veld :  284
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   8   2   2
   7   1  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  284
   1   3  14 Y
  11   4   2
   8   4  15 W
** veld :  285
   2   5   4
   3   1   4
  11   2   4
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   2   4   1
   5   2   1
  12   5   1
   1   5  15 W
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  285
   9   1   1
   7   5   4
  10   5  14 Y
** veld :  286
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   1   3  14 Y
   4   4  14 Y
   2   4   1
   5   2   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  286
   3   1   4
   8   2   2
   7   3   1
** veld :  287
   2   5   4
  11   2   4
   4   5   2
   8   5   2
  11   4   2
   4   4  14 Y
   9   1   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  287
  12   3  14 Y
   5   2   1
   5   4   4
** veld :  288
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   2   4   1
   5   2   1
  12   5   1
   1   5  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  288
  10   3  15 W
  11   4   2
   9   1   1
** veld :  289
   2   5   4
   3   1   4
   8   5   2
  11   4   2
   1   3  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  289
   4   5   2
   7   5   4
   6   1  15 W
** veld :  290
   3   1   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  290
   5   4   4
   4   4  14 Y
  11   4   2
** veld :  291
   2   5   4
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
  10   5  14 Y
   7   3   1
   9   1   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  291
  12   5   1
  12   3  14 Y
   8   2   2
** veld :  292
   2   5   4
   3   1   4
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   7   1  14 Y
  12   3  14 Y
   2   4   1
   9   1   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  292
   1   5  15 W
   7   3   1
   7   5   4
** veld :  293
   5   4   4
   7   5   4
  11   2   4
   4   5   2
   8   2   2
   4   4  14 Y
  12   3  14 Y
   5   2   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  293
  11   4   2
   7   1  14 Y
   2   5   4
** veld :   11
   2   5   4
   5   4   4
   7   5   4
   2   2   2
   4   5   2
   8   5   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
   5   3  15 W
   6   1  15 W
   8   4  15 W
** serie :   11
   1   3  14 Y
   1   5  15 W
   3   1   4
** veld :  295
   2   5   4
   3   1   4
   4   5   2
   8   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
  12   5   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  295
   7   3   1
   8   2   2
  11   2   4
** veld :   59
   5   4   4
   7   5   4
   2   2   2
   8   5   2
   7   1  14 Y
  12   3  14 Y
   2   4   1
   9   1   1 B
   1   5  15 W
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   59
   1   3  14 Y
  11   4   2
  11   2   4
** veld :  297
   3   1   4
   5   4   4
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
   2   4   1
   7   3   1
   9   1   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  297
   5   3  15 W
   7   5   4
   4   4  14 Y
** veld :    8
   2   5   4 R
   3   1   4 R
   5   4   4 R
   7   5   4 R
   4   5   2 G
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1 B
   5   2   1 B
   9   1   1 B
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :    8
  12   5   1 B
   2   2   2 G
  11   2   4 R
** veld :  299
   5   4   4
   7   5   4
   2   2   2
   4   5   2
  11   4   2
   7   3   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  299
   8   5   2
   2   5   4
   1   5  15 W
** veld :  300
   5   4   4
   7   5   4
  11   2   4
   8   5   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  300
   2   2   2
  12   5   1
   6   1  15 W
** veld :  301
   3   1   4
   8   2   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  301
  11   2   4
   5   2   1
   5   3  15 W
** veld :  302
   2   5   4
   3   1   4
  11   2   4
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  302
   4   4  14 Y
   7   5   4
   2   2   2
** veld :  303
   2   5   4
   3   1   4
  11   2   4
   2   2   2
   8   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  303
   5   4   4
   1   5  15 W
   9   1   1
** veld :  304
   5   4   4
  11   2   4
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  304
   9   1   1
  10   5  14 Y
   3   1   4
** veld :  305
   2   5   4
   3   1   4
   7   5   4
   2   2   2
   4   5   2
   8   5   2
   7   1  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  305
   8   4  15 W
  11   2   4
   1   3  14 Y
** veld :   89
   3   1   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1 B
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   89
   7   1  14 Y
   8   2   2
  12   5   1
** veld :  307
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   5   2   1
   9   1   1
  12   5   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  307
   2   4   1
  12   3  14 Y
   8   5   2
** veld :  308
   2   5   4
   3   1   4
   5   4   4
   8   5   2
   8   2   2
  11   4   2
  10   5  14 Y
   2   4   1
   5   2   1
   9   1   1
  12   5   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  308
   8   4  15 W
   7   5   4
   4   5   2
** veld :   39
   2   5   4
   3   1   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
  11   4   2
   5   2   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   39
   5   4   4
   5   3  15 W
   8   2   2
** veld :  310
   7   5   4
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  310
   8   5   2
  10   5  14 Y
   2   5   4
** veld :  311
   2   5   4
   3   1   4
   7   5   4
   4   5   2
   8   5   2
  11   4   2
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   9   1   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  311
   5   3  15 W
   2   2   2
  11   2   4
** veld :  312
   2   2   2
   8   5   2
  11   4   2
   1   3  14 Y
   5   2   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  312
   7   5   4
   4   5   2
   9   1   1
** veld :  313
   7   5   4
  11   2   4
   4   5   2
   8   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  313
   5   2   1
   2   2   2
   8   4  15 W
** veld :  314
   3   1   4
   1   3  14 Y
   4   4  14 Y
   5   2   1
   9   1   1
  12   5   1
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  314
  11   2   4
   8   5   2
   2   4   1
** veld :  315
   2   2   2
   4   5   2
   8   5   2
   8   2   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   9   1   1
   1   5  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  315
  11   4   2
   1   3  14 Y
   5   3  15 W
** veld :  316
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  316
   5   3  15 W
   4   5   2
   7   1  14 Y
** veld :  317
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   2   4   1
  12   5   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  317
  11   4   2
  10   5  14 Y
   7   3   1
** veld :  318
   3   1   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   1   3  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  318
  12   3  14 Y
   8   2   2
   2   4   1
** veld :  319
   2   5   4
   3   1   4
   7   5   4
   2   2   2
   8   5   2
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  319
   4   5   2
   7   1  14 Y
  11   2   4
** veld :  320
  11   2   4
   4   5   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  320
   5   4   4
   7   3   1
   2   2   2
** veld :  321
   3   1   4
   5   4   4
   7   5   4
   2   2   2
   1   3  14 Y
   4   4  14 Y
   5   2   1
   7   3   1
   9   1   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  321
   1   5  15 W
  12   5   1
   7   1  14 Y
** veld :  322
   3   1   4
   5   4   4
   8   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  322
   4   5   2
   2   5   4
   7   3   1
** veld :  323
   2   2   2
   4   5   2
   4   4  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
   9   1   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  323
  12   3  14 Y
  11   2   4
  10   3  15 W
** veld :  324
   2   5   4
   7   5   4
   4   4  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  324
   6   1  15 W
   2   2   2
  10   5  14 Y
** veld :  325
  11   2   4
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  325
  12   5   1
  12   3  14 Y
   2   2   2
** veld :  326
   5   4   4
   7   5   4
  11   4   2
   7   1  14 Y
  10   5  14 Y
   7   3   1
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  326
   3   1   4
   9   1   1
  12   3  14 Y
** veld :  327
   2   5   4
   5   4   4
   7   5   4
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  327
   8   2   2
   1   3  14 Y
  11   2   4
** veld :  328
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   1   3  14 Y
   7   1  14 Y
  12   3  14 Y
   5   2   1
   9   1   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  328
   5   4   4
   5   3  15 W
   8   2   2
** veld :  329
   3   1   4
   4   5   2
   8   5   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   9   1   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  329
  12   5   1
   5   4   4
   5   3  15 W
** veld :  330
   2   5   4
   3   1   4
   2   2   2
   8   2   2
  11   4   2
   4   4  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  330
  10   3  15 W
   2   4   1
   8   5   2
** veld :  331
   5   4   4
   7   5   4
  11   2   4
  10   5  14 Y
  12   3  14 Y
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  331
   4   5   2
   1   3  14 Y
  12   5   1
** veld :  332
   2   5   4
   3   1   4
  11   2   4
   2   2   2
   4   5   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  332
   5   3  15 W
   7   5   4
   2   4   1
** veld :  333
   5   4   4
  11   2   4
   4   5   2
   8   2   2
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  333
   4   4  14 Y
   5   3  15 W
   7   5   4
** veld :  334
   3   1   4
   5   4   4
  10   5  14 Y
   7   3   1
   9   1   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  334
   2   4   1
   7   5   4
   7   1  14 Y
** veld :  335
   2   5   4
   2   2   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
   2   4   1
   5   2   1
   7   3   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  335
  12   3  14 Y
   7   5   4
   8   2   2
** veld :  336
   3   1   4
   7   5   4
   8   5   2
   8   2   2
  10   5  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  336
   4   4  14 Y
   2   4   1
   5   4   4
** veld :  337
   2   5   4
   5   4   4
  11   2   4
   2   2   2
   4   5   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   2   4   1
   7   3   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  337
   5   2   1
  12   3  14 Y
   8   5   2
** veld :  338
   5   4   4
   7   5   4
  11   2   4
   4   5   2
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  338
   1   5  15 W
  12   5   1
   8   5   2
** veld :   53
   2   5   4
   3   1   4
   7   5   4
  11   2   4
   8   2   2
   4   4  14 Y
   2   4   1
   5   2   1
   9   1   1 B
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   53
   1   5  15 W
   7   3   1
   5   4   4
** veld :  340
   2   5   4
   3   1   4
   7   5   4
   2   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  340
  11   2   4
   4   5   2
   5   3  15 W
** veld :  341
   2   2   2
   8   5   2
   8   2   2
   4   4  14 Y
  10   5  14 Y
   2   4   1
  12   5   1
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  341
   7   3   1
  11   2   4
   4   5   2
** veld :  342
   7   5   4
  11   4   2
   1   3  14 Y
   4   4  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  342
   8   5   2
   7   1  14 Y
   5   4   4
** veld :  343
   2   5   4
   3   1   4
   7   5   4
  11   2   4
  11   4   2
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  343
   1   3  14 Y
   5   3  15 W
   2   2   2
** veld :  344
   5   4   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   1   3  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
  12   5   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  344
   6   1  15 W
   4   4  14 Y
   7   5   4
** veld :  345
   2   5   4
  11   2   4
   8   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   9   1   1
  12   5   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  345
   5   2   1
   1   5  15 W
   5   4   4
** veld :  346
   3   1   4
   5   4   4
  11   2   4
   2   2   2
   8   2   2
  11   4   2
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  346
   8   5   2
   1   3  14 Y
  12   5   1
** veld :  347
   2   5   4
   5   4   4
  11   2   4
   4   5   2
   8   5   2
  11   4   2
  10   5  14 Y
  12   3  14 Y
   2   4   1
   9   1   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  347
   1   3  14 Y
   5   2   1
   1   5  15 W
** veld :  348
   7   5   4
  11   2   4
   8   2   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  348
   4   4  14 Y
   9   1   1
   4   5   2
** veld :  349
   3   1   4
  11   2   4
   4   5   2
  11   4   2
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  349
  12   5   1
   5   3  15 W
   5   4   4
** veld :  350
   5   4   4
   7   5   4
   4   5   2
   8   5   2
   8   2   2
  12   3  14 Y
   2   4   1
   7   3   1
  12   5   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  350
   1   3  14 Y
   9   1   1
  10   3  15 W
** veld :  351
   3   1   4
   5   4   4
  11   2   4
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   9   1   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  351
  11   4   2
   1   5  15 W
   7   3   1
** veld :  352
   2   5   4
   3   1   4
   8   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   5   2   1
   7   3   1
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  352
   2   2   2
   6   1  15 W
  12   5   1
** veld :  353
   2   2   2
   4   5   2
   8   2   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  353
  11   2   4
   8   5   2
   1   5  15 W
** veld :  354
   3   1   4
   7   5   4
   4   5   2
   8   5   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  354
   7   1  14 Y
   9   1   1
   2   2   2
** veld :  355
   5   4   4
  11   2   4
   8   5   2
   8   2   2
  11   4   2
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  355
   2   2   2
   7   1  14 Y
   6   1  15 W
** veld :  356
   2   5   4
   3   1   4
   7   5   4
   2   2   2
   4   5   2
   8   5   2
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  356
   1   5  15 W
   1   3  14 Y
   7   3   1
** veld :  357
   7   5   4
  11   2   4
   2   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  357
   2   4   1
   3   1   4
   4   5   2
** veld :  358
   2   5   4
   3   1   4
   2   2   2
   4   5   2
   8   5   2
   1   3  14 Y
   4   4  14 Y
   2   4   1
   9   1   1
  12   5   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  358
  12   3  14 Y
   5   2   1
   6   1  15 W
** veld :  175
   3   1   4
   5   4   4
  11   4   2
   4   4  14 Y
   7   1  14 Y
   2   4   1
  12   5   1
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  175
   7   5   4
   5   3  15 W
  10   5  14 Y
** veld :  360
   3   1   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   1   3  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  360
   7   1  14 Y
   9   1   1
   8   4  15 W
** veld :  361
   2   5   4
   3   1   4
   5   4   4
   4   5   2
   8   5   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
   5   2   1
   7   3   1
  12   5   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  361
   9   1   1
   2   2   2
   6   1  15 W
** veld :  362
   5   4   4
   7   5   4
  11   2   4
   4   5   2
   8   2   2
  11   4   2
  12   3  14 Y
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  362
   2   5   4
  10   5  14 Y
   8   4  15 W
** veld :  363
   2   5   4
   3   1   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   4   4  14 Y
   2   4   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  363
  10   3  15 W
   5   4   4
   5   2   1
** veld :  364
   5   4   4
   7   5   4
  11   2   4
   8   5   2
  11   4   2
  10   5  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  364
   5   3  15 W
   5   2   1
   8   2   2
** veld :  365
   2   5   4
   3   1   4
   5   4   4
   4   5   2
   8   2   2
   7   1  14 Y
   2   4   1
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  365
   8   5   2
   5   2   1
  12   3  14 Y
** veld :  366
   7   5   4
  11   2   4
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   9   1   1
  12   5   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  366
   5   4   4
  12   3  14 Y
   5   2   1
** veld :  367
   2   5   4
   3   1   4
   5   4   4
   4   5   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  367
   5   2   1
   6   1  15 W
  11   2   4
** veld :  368
   5   4   4
   7   5   4
  11   2   4
   8   5   2
   8   2   2
   1   3  14 Y
   2   4   1
   5   2   1
   9   1   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  368
  11   4   2
   7   1  14 Y
   6   1  15 W
** veld :  369
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   8   2   2
  10   5  14 Y
  12   3  14 Y
   7   3   1
   1   5  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  369
   9   1   1
   5   3  15 W
  11   4   2
** veld :  370
   3   1   4
   5   4   4
  11   2   4
   1   3  14 Y
   4   4  14 Y
   2   4   1
   5   2   1
   9   1   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  370
   7   5   4
   7   1  14 Y
   4   5   2
** veld :  371
   2   5   4
   3   1   4
   8   2   2
   4   4  14 Y
   7   1  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  371
   5   3  15 W
   5   2   1
  10   5  14 Y
** veld :  372
   3   1   4
   7   5   4
   4   5   2
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  372
   7   1  14 Y
   8   2   2
   5   3  15 W
** veld :  373
   2   5   4
   5   4   4
  11   2   4
  12   3  14 Y
   2   4   1
   7   3   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  373
   9   1   1
   7   1  14 Y
  11   4   2
** veld :  374
   5   4   4
   7   5   4
  11   2   4
   1   3  14 Y
   2   4   1
   5   2   1
   9   1   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  374
   3   1   4
  10   3  15 W
   7   3   1
** veld :  375
   3   1   4
   8   2   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   7   3   1
  12   5   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  375
   2   5   4
   9   1   1
   4   5   2
** veld :  376
   3   1   4
   5   4   4
   7   5   4
   8   5   2
  11   4   2
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  376
  10   3  15 W
   5   2   1
  11   2   4
** veld :   56
   2   5   4
   4   5   2
   8   5   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   9   1   1 B
  12   5   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   56
   5   2   1
  10   3  15 W
   1   3  14 Y
** veld :  378
   3   1   4
   5   4   4
   1   3  14 Y
   7   1  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  378
   5   3  15 W
   7   5   4
  10   5  14 Y
** veld :  379
   2   5   4
   3   1   4
  11   2   4
  10   5  14 Y
   2   4   1
   7   3   1
   9   1   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  379
   7   5   4
   4   5   2
  10   3  15 W
** veld :  380
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   5   2   1
  12   5   1
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  380
   1   3  14 Y
   9   1   1
   3   1   4
** veld :  381
   2   5   4
   3   1   4
   7   5   4
   2   2   2
   4   4  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   9   1   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  381
   7   3   1
   7   1  14 Y
   8   2   2
** veld :   80
   2   5   4
   3   1   4
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
   2   4   1
   9   1   1 B
   1   5  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   80
   4   4  14 Y
   5   3  15 W
  11   2   4
** veld :  383
   2   5   4
   3   1   4
  11   2   4
   2   2   2
  11   4   2
  12   3  14 Y
   2   4   1
   5   2   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  383
   4   5   2
  10   5  14 Y
   5   4   4
** veld :  384
   3   1   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   9   1   1
  12   5   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  384
   5   2   1
   6   1  15 W
   4   4  14 Y
** veld :  385
   2   5   4
   3   1   4
   5   4   4
   4   5   2
   8   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
  12   5   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  385
   8   2   2
  11   2   4
  10   5  14 Y
** veld :  386
   3   1   4
   2   2   2
   4   5   2
   8   5   2
   8   2   2
   1   3  14 Y
   7   1  14 Y
   2   4   1
   9   1   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  386
  11   4   2
   5   3  15 W
   7   3   1
** veld :  387
   2   5   4
   2   2   2
   4   5   2
   8   5   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
   2   4   1
   9   1   1
  12   5   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  387
   7   3   1
   1   5  15 W
   5   4   4
** veld :  388
   3   1   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  388
   8   2   2
   4   4  14 Y
   6   1  15 W
** veld :  389
   2   5   4
   3   1   4
   5   4   4
   7   5   4
   2   2   2
   4   5   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  389
  10   3  15 W
   8   5   2
  11   2   4
** veld :  390
   2   5   4
   3   1   4
   8   5   2
   8   2   2
   1   3  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  390
   4   4  14 Y
   5   4   4
   5   2   1
** veld :  391
   2   5   4
   7   5   4
  11   2   4
   8   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  391
   9   1   1
   5   4   4
   1   3  14 Y
** veld :   42
   5   4   4
  11   2   4
   4   5   2
   8   2   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
   1   5  15 W
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   42
   6   1  15 W
   2   5   4
  12   5   1
** veld :  393
   2   5   4
  11   2   4
   8   5   2
   8   2   2
  11   4   2
  10   5  14 Y
  12   3  14 Y
   9   1   1
  12   5   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  393
   5   2   1
   7   5   4
   2   2   2
** veld :  394
   3   1   4
   5   4   4
   4   5   2
   8   5   2
   4   4  14 Y
   7   1  14 Y
   7   3   1
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  394
   7   5   4
   8   2   2
   1   3  14 Y
** veld :  395
   2   5   4
  11   2   4
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
   5   2   1
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  395
  12   3  14 Y
   7   3   1
   3   1   4
** veld :  396
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   4   5   2
   8   5   2
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  396
  10   3  15 W
   8   2   2
   5   2   1
** veld :  397
   2   5   4
   5   4   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   1   3  14 Y
   7   1  14 Y
  12   3  14 Y
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  397
   7   5   4
   4   4  14 Y
  11   4   2
** veld :  398
   2   2   2
   8   5   2
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
   7   3   1
   1   5  15 W
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  398
   5   2   1
  11   4   2
   7   1  14 Y
** veld :  399
   2   5   4
   3   1   4
  11   2   4
   8   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  399
   8   4  15 W
  12   5   1
   5   4   4
** veld :  400
   3   1   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
  12   5   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  400
   5   4   4
   8   2   2
   5   3  15 W
** veld :  401
   2   5   4
   3   1   4
   7   5   4
   4   5   2
   8   5   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  401
   2   2   2
  10   5  14 Y
   1   5  15 W
** veld :  402
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   8   2   2
  11   4   2
   4   4  14 Y
  10   5  14 Y
   2   4   1
   7   3   1
   9   1   1
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  402
   6   1  15 W
   5   2   1
   1   3  14 Y
** veld :  403
   5   4   4
   7   5   4
   4   5   2
   8   5   2
   1   3  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  403
   4   4  14 Y
   8   2   2
  11   2   4
** veld :  404
   5   4   4
  11   2   4
   4   5   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  404
   3   1   4
  11   4   2
   6   1  15 W
** veld :  405
   2   5   4
   7   5   4
   2   2   2
   8   5   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   5   2   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  405
   4   5   2
   7   3   1
  10   5  14 Y
** veld :  406
   3   1   4
   5   4   4
   4   5   2
   8   5   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  406
   7   5   4
   2   4   1
  11   4   2
** veld :  407
   2   5   4
   3   1   4
   5   4   4
   7   5   4
   2   2   2
   4   5   2
   8   2   2
   1   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  407
   8   5   2
   4   4  14 Y
   1   5  15 W
** veld :   50
   2   5   4
   5   4   4
   7   5   4
   2   2   2
   4   5   2
   8   5   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   7   3   1
   9   1   1 B
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :   50
   1   5  15 W
  11   4   2
   2   4   1
** veld :  409
   2   5   4
   3   1   4
   7   5   4
  11   2   4
   8   5   2
  11   4   2
   4   4  14 Y
  10   5  14 Y
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  409
   8   2   2
  12   3  14 Y
   2   4   1
** veld :  410
   3   1   4
   2   2   2
   8   2   2
   7   1  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   9   1   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  410
   7   3   1
   1   3  14 Y
   7   5   4
** veld :  411
   7   5   4
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   2   4   1
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  411
   6   1  15 W
  11   2   4
  10   5  14 Y
** veld :  412
   2   5   4
   7   5   4
  11   2   4
   4   5   2
   8   5   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   7   3   1
   9   1   1
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  412
  12   5   1
   2   2   2
   4   4  14 Y
** veld :  413
   3   1   4
   5   4   4
   4   5   2
   8   5   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  413
   8   2   2
   4   4  14 Y
  11   2   4
** veld :  414
   2   5   4
   3   1   4
   4   5   2
   8   2   2
   7   1  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  414
  10   3  15 W
   5   4   4
   2   2   2
** veld :  415
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   1   3  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
  12   5   1
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  415
   8   5   2
   1   5  15 W
   9   1   1
** veld :  416
   2   5   4
   3   1   4
   5   4   4
   4   5   2
   8   2   2
   4   4  14 Y
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  416
   7   3   1
  11   4   2
  11   2   4
** veld :  417
   5   4   4
  11   2   4
   2   2   2
   8   5   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   2   4   1
   7   3   1
  12   5   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  417
   6   1  15 W
  11   4   2
   3   1   4
** veld :  418
   2   5   4
   7   5   4
   4   5   2
   8   2   2
  11   4   2
   4   4  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   9   1   1
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  418
   3   1   4
   1   5  15 W
  12   5   1
** veld :  419
   3   1   4
   5   4   4
   7   5   4
   4   5   2
   8   5   2
   1   3  14 Y
   4   4  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  419
   5   2   1
   8   2   2
   8   4  15 W
** veld :  420
   2   5   4
   3   1   4
   8   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   7   3   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  420
  11   2   4
   4   5   2
  12   5   1
** veld :  421
   2   5   4
   7   5   4
  11   2   4
   2   2   2
   8   2   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
   9   1   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  421
   3   1   4
   1   5  15 W
   8   5   2
** veld :  422
   4   4  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  422
   2   2   2
   7   1  14 Y
   7   5   4
** veld :  423
   5   4   4
   7   5   4
   4   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
  12   5   1
   1   5  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  423
   8   4  15 W
  12   3  14 Y
   8   5   2
** veld :  424
   3   1   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  424
   8   2   2
   1   3  14 Y
   7   3   1
** veld :  425
   2   5   4
   5   4   4
   7   5   4
   8   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   2   4   1
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  425
   3   1   4
  12   3  14 Y
  12   5   1
** veld :  426
   2   5   4
   7   5   4
   2   2   2
   4   5   2
   8   2   2
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
  12   5   1
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  426
  11   4   2
  11   2   4
   6   1  15 W
** veld :  427
   3   1   4
   5   4   4
   7   5   4
   4   5   2
   8   5   2
  11   4   2
   4   4  14 Y
  10   5  14 Y
   2   4   1
   9   1   1
  12   5   1
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  427
   8   4  15 W
   2   2   2
   7   1  14 Y
** veld :  428
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   2   2   2
  11   4   2
   4   4  14 Y
  12   3  14 Y
   7   3   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  428
   8   5   2
   1   3  14 Y
  12   5   1
** veld :  429
   3   1   4
   5   4   4
   7   5   4
   4   5   2
   8   5   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
   5   2   1
   9   1   1
  12   5   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  429
   4   4  14 Y
   7   3   1
   8   2   2
** veld :  430
   2   5   4
   3   1   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
  11   4   2
  12   3  14 Y
   5   2   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  430
   7   5   4
   7   3   1
   4   4  14 Y
** veld :   85
   2   5   4
   7   5   4
  11   2   4
   4   5   2
   8   5   2
  11   4   2
   7   1  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1 B
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :   85
  12   5   1
   8   2   2
   6   1  15 W
** veld :  432
   3   1   4
  11   2   4
   2   2   2
   4   5   2
  11   4   2
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  432
   5   2   1
   2   5   4
   8   4  15 W
** veld :  433
   2   5   4
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   8   5   2
   8   2   2
   1   3  14 Y
   5   2   1
   7   3   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  433
   1   5  15 W
   9   1   1
  11   4   2
** veld :  434
   7   5   4
   4   5   2
   8   5   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  434
  11   4   2
   6   1  15 W
  11   2   4
** veld :  435
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   8   2   2
   1   3  14 Y
   4   4  14 Y
  10   5  14 Y
   5   2   1
  12   5   1
   5   3  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  435
   6   1  15 W
   2   5   4
   7   3   1
** veld :  436
   5   4   4
   4   5   2
   8   5   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   7   3   1
   9   1   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  436
  12   3  14 Y
   5   3  15 W
  11   2   4
** veld :  437
   8   2   2
   7   1  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  437
  12   3  14 Y
   2   2   2
  11   2   4
** veld :  438
   2   5   4
  11   2   4
   2   2   2
   8   5   2
   8   2   2
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  438
   2   4   1
   7   5   4
   4   4  14 Y
** veld :  439
   8   5   2
   8   2   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   5   2   1
   9   1   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  439
   4   5   2
  12   3  14 Y
   2   5   4
** veld :  440
   2   5   4
   3   1   4
   5   4   4
  11   2   4
   4   5   2
   8   5   2
   4   4  14 Y
   7   1  14 Y
   5   2   1
   7   3   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  440
   5   3  15 W
  12   5   1
   2   2   2
** veld :  441
   5   4   4
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  441
   7   5   4
   2   2   2
  10   3  15 W
** veld :  442
   7   5   4
   8   5   2
  11   4   2
   4   4  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  442
   2   2   2
  10   3  15 W
  10   5  14 Y
** veld :  443
   2   5   4
   3   1   4
   7   5   4
   2   2   2
   8   2   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
  12   5   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  443
   7   3   1
   5   4   4
   8   5   2
** veld :  444
   3   1   4
   5   4   4
   8   2   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  444
  11   4   2
   1   3  14 Y
  11   2   4
** veld :  445
  11   2   4
   4   5   2
   8   5   2
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  445
  10   3  15 W
   8   2   2
   2   5   4
** veld :  446
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   4   4  14 Y
   5   2   1
   9   1   1
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  446
   2   2   2
   7   1  14 Y
  12   5   1
** veld :  447
   3   1   4
   5   4   4
   7   5   4
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
   7   3   1
   9   1   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  447
   5   2   1
   1   3  14 Y
   2   2   2
** veld :  448
   7   5   4
  11   2   4
   4   5   2
  10   5  14 Y
   5   2   1
   9   1   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  448
   2   2   2
   7   1  14 Y
   2   5   4
** veld :  449
   4   5   2
   8   5   2
   8   2   2
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   9   1   1
   1   5  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  449
   5   3  15 W
  11   4   2
   7   1  14 Y
** veld :  450
   8   2   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  450
   4   4  14 Y
   2   5   4
   8   4  15 W
** veld :  451
   2   5   4
   5   4   4
   8   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   2   4   1
   9   1   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  451
   7   5   4
   2   2   2
  12   5   1
** veld :  452
   3   1   4
   2   2   2
   4   5   2
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  452
   8   5   2
  11   2   4
   8   4  15 W
** veld :  453
   2   5   4
   3   1   4
   7   5   4
   4   5   2
   8   5   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
  12   5   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  453
  12   3  14 Y
   2   2   2
   9   1   1
** veld :  454
   3   1   4
   5   4   4
   2   2   2
   4   5   2
   8   2   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   9   1   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  454
   1   5  15 W
  12   3  14 Y
   8   5   2
** veld :  455
   2   5   4
   5   4   4
   7   5   4
   4   5   2
   8   5   2
  11   4   2
  10   5  14 Y
  12   3  14 Y
   7   3   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  455
   3   1   4
   1   5  15 W
   2   2   2
** veld :  456
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  456
   1   3  14 Y
  11   2   4
   8   2   2
** veld :  457
   2   5   4
   3   1   4
  11   2   4
   8   5   2
   8   2   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  457
   7   5   4
   2   2   2
   1   5  15 W
** veld :  458
   2   5   4
   5   4   4
   4   5   2
   8   2   2
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  458
   7   3   1
  11   4   2
   3   1   4
** veld :  459
   7   5   4
  11   2   4
   4   5   2
   8   5   2
   8   2   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
  12   5   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  459
  10   3  15 W
   7   3   1
   4   4  14 Y
** veld :  460
   2   5   4
   3   1   4
   5   4   4
   8   5   2
   8   2   2
   4   4  14 Y
  10   5  14 Y
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  460
   2   4   1
  11   4   2
  11   2   4
** veld :  461
   3   1   4
  11   2   4
   4   5   2
   8   2   2
  11   4   2
   1   3  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
   1   5  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  461
   2   5   4
   8   5   2
   5   3  15 W
** veld :  462
   2   5   4
   5   4   4
  11   2   4
   2   2   2
   8   5   2
   4   4  14 Y
   9   1   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  462
   7   5   4
   2   4   1
   8   2   2
** veld :  463
   5   4   4
   7   5   4
   4   5   2
  11   4   2
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  463
   1   3  14 Y
  11   2   4
   8   5   2
** veld :  464
   7   5   4
  11   2   4
   2   2   2
   8   5   2
   5   2   1
   7   3   1
   9   1   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  464
   2   5   4
   6   1  15 W
  12   5   1
** veld :  465
   2   5   4
   7   5   4
  11   2   4
   4   5   2
   8   2   2
  11   4   2
   1   3  14 Y
   9   1   1
  12   5   1
   1   5  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  465
   5   2   1
   7   1  14 Y
   3   1   4
** veld :  466
   3   1   4
   5   4   4
  11   2   4
   2   2   2
   8   5   2
  11   4   2
   4   4  14 Y
   5   2   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  466
   1   5  15 W
   7   5   4
   1   3  14 Y
** veld :  467
   5   4   4
   7   5   4
   8   5   2
   8   2   2
  11   4   2
   1   3  14 Y
  10   5  14 Y
   5   2   1
   9   1   1
  12   5   1
   1   5  15 W
   5   3  15 W
   6   1  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  467
   2   4   1
   4   5   2
   4   4  14 Y
** veld :  468
   5   4   4
  11   2   4
   2   2   2
   8   5   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   7   3   1
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  468
   9   1   1
   3   1   4
   8   2   2
** veld :  469
   3   1   4
   7   5   4
   2   2   2
   4   5   2
   8   5   2
   8   2   2
  11   4   2
   4   4  14 Y
   7   1  14 Y
   7   3   1
   9   1   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  469
   5   4   4
   8   4  15 W
  12   5   1
** veld :  470
   3   1   4
   5   4   4
  11   2   4
   2   2   2
   8   2   2
  11   4   2
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   5   2   1
   9   1   1
  12   5   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  470
   4   4  14 Y
   7   5   4
   4   5   2
** veld :  109
   2   5   4
   3   1   4
   4   5   2
   8   5   2
   8   2   2
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1 B
  12   5   1
   5   3  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  109
   1   3  14 Y
   5   4   4
  11   4   2
** veld :  472
   3   1   4
   5   4   4
   7   5   4
   4   5   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   7   3   1
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  472
   5   2   1
   8   2   2
  12   3  14 Y
** veld :  473
   5   4   4
  11   2   4
   2   2   2
   8   5   2
   4   4  14 Y
   7   1  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
  12   5   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  473
   5   3  15 W
  11   4   2
   5   2   1
** veld :  474
   3   1   4
   7   5   4
  11   2   4
  11   4   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
   9   1   1
  12   5   1
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  474
   2   2   2
  12   3  14 Y
   5   4   4
** veld :  475
   2   5   4
   3   1   4
   5   4   4
   8   2   2
  11   4   2
  10   5  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  475
  11   2   4
   4   5   2
   5   3  15 W
** veld :  476
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   2   2
  12   3  14 Y
   7   3   1
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  476
   8   5   2
   1   3  14 Y
  12   5   1
** veld :  477
   2   5   4
   3   1   4
   7   5   4
   4   5   2
   8   5   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   9   1   1
   5   3  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  477
  12   5   1
   5   4   4
   8   2   2
** veld :   67
   2   5   4
   3   1   4
   7   5   4
  11   2   4
   8   2   2
   1   3  14 Y
   4   4  14 Y
   7   1  14 Y
  12   3  14 Y
   2   4   1
   7   3   1
   9   1   1 B
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
** serie :   67
  10   5  14 Y
  11   4   2
   5   2   1
** veld :  479
   3   1   4
   5   4   4
   8   2   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   7   3   1
   9   1   1
  12   5   1
   1   5  15 W
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  479
   5   3  15 W
  12   3  14 Y
   7   5   4
** veld :  480
   2   5   4
   3   1   4
   4   5   2
   8   5   2
   7   1  14 Y
  12   3  14 Y
   2   4   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  480
   9   1   1
   1   5  15 W
   7   5   4
** veld :  481
   7   5   4
  11   2   4
   2   2   2
   8   5   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  481
   8   2   2
   3   1   4
   1   5  15 W
** veld :  482
   2   5   4
   3   1   4
  11   2   4
   4   5   2
   8   5   2
   8   2   2
   4   4  14 Y
  10   5  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  482
   1   5  15 W
   7   1  14 Y
   7   5   4
** veld :  483
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   8   5   2
   1   3  14 Y
  10   5  14 Y
  12   3  14 Y
   2   4   1
  12   5   1
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  483
   3   1   4
   7   1  14 Y
   5   3  15 W
** veld :  484
   2   5   4
   5   4   4
   8   5   2
   8   2   2
  11   4   2
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   9   1   1
  12   5   1
   5   3  15 W
   6   1  15 W
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
** serie :  484
   4   5   2
   4   4  14 Y
  11   2   4
** veld :  485
   2   5   4
   3   1   4
   4   5   2
   8   2   2
   4   4  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
   1   5  15 W
   5   3  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  485
   1   3  14 Y
  11   2   4
   2   4   1
** veld :  486
   3   1   4
   5   4   4
  11   2   4
   2   2   2
  11   4   2
   1   3  14 Y
   7   1  14 Y
  10   5  14 Y
   2   4   1
   5   2   1
   7   3   1
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  486
  12   3  14 Y
   4   5   2
   8   4  15 W
** veld :  487
   2   5   4
   5   4   4
  11   2   4
   4   5   2
   8   5   2
   8   2   2
   4   4  14 Y
   2   4   1
   9   1   1
  12   5   1
   1   5  15 W
   8   4  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  487
   2   2   2
   7   1  14 Y
  10   3  15 W
** veld :  488
   2   5   4
   3   1   4
   7   5   4
   8   5   2
   7   1  14 Y
  12   3  14 Y
   5   2   1
   7   3   1
   9   1   1
   6   1  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  488
  11   2   4
   4   4  14 Y
   2   2   2
** veld :  489
   2   5   4
   3   1   4
   5   4   4
   7   5   4
  11   2   4
   2   2   2
   4   5   2
   7   1  14 Y
  10   5  14 Y
   2   4   1
   9   1   1
   8   4  15 W
  10   3  15 W
   0   0   0
   0   0   0
   0   0   0
   0   0   0
   0   0   0
** serie :  489
   8   2   2
   5   2   1
   1   5  15 W
`
