import styled from '@emotion/styled'
import { Button, ButtonProps } from '@mui/material'

const ButtonRed = styled(Button)<ButtonProps>(({ theme }) => ({
  flex: 1,
  height: '80px',
  color: 'var(--color-white-100)',
  backgroundColor: 'var(--color-red-500)',
  '&:hover': {
    backgroundColor: 'var(--color-red-500-hover)'
  }
}))
const ButtonGreen = styled(Button)<ButtonProps>(({ theme }) => ({
  flex: 1,
  height: '80px',
  color: 'var(--color-white-100)',
  backgroundColor: 'var(--color-green-500)',
  '&:hover': {
    backgroundColor: 'var(--color-green-500-hover)'
  }
}))
const ButtonBlue = styled(Button)<ButtonProps>(({ theme }) => ({
  flex: 1,
  height: '80px',
  color: 'var(--color-white-100)',
  backgroundColor: 'var(--color-blue-500)',
  '&:hover': {
    backgroundColor: 'var(--color-blue-500-hover)'
  }
}))
const ButtonWhite = styled(Button)<ButtonProps>(({ theme }) => ({
  flex: 1,
  height: '80px',
  color: 'var(--color-black-900)',
  backgroundColor: 'var(--color-white-100)',
  '&:hover': {
    backgroundColor: 'var(--color-white-100-hover)'
  }
}))
const ButtonYellow = styled(Button)<ButtonProps>(({ theme }) => ({
  flex: 1,
  height: '80px',
  color: 'var(--color-black-900)',
  backgroundColor: 'var(--color-yellow-500)',
  '&:hover': {
    backgroundColor: 'var(--color-yellow-500-hover)'
  }
}))
const ButtonBlack = styled(Button)<ButtonProps>(({ theme }) => ({
  flex: 1,
  height: '80px',
  color: 'var(--color-white-100)',
  border: '1px solid var(--color-white-100)',
  backgroundColor: 'var(--color-black-900)',
  '&:hover': {
    backgroundColor: 'var(--color-black-900-hover)'
  }
}))

const buttons = {
  ButtonRed,
  ButtonBlue,
  ButtonGreen,
  ButtonWhite,
  ButtonYellow,
  ButtonBlack
}

export default buttons
