import { hasValue } from '@root/misc/helpers'
import { VdaInput } from '@root/misc/types'

const returnOnlyNumeric = (input: string): number => {
  return parseInt(input.replace(/\D/g, ''), 10)
}

export const vdaParser = (input: string): VdaInput => {
  const arr = input.split(/\r?\n/)

  const structuredArr: VdaInput = []

  let activeID: number
  let itemNumber: number
  let activeType: 'field' | 'serie'

  arr.map((item) => {
    if (item.includes('veld')) {
      activeID = returnOnlyNumeric(item) - 1
      itemNumber = activeID + 1
      activeType = 'field'
      structuredArr[activeID] = {
        itemNumber,
        data: {
          field: [],
          serie: []
        }
      }

      return item
    }

    if (item.includes('serie')) {
      activeType = 'serie'
      return item
    }

    if (activeType === 'field' && hasValue(structuredArr[activeID])) {
      const data = item.split(' ').filter(e => e !== '')
      const formattedData = {
        x: data[0],
        y: data[1],
        color: data[2],
        colorText: data[3],
        originalData: item
      }
      if (data[2] !== '0') {
        structuredArr[activeID].data.field.push(formattedData)
      }
    }

    if (activeType === 'serie' && hasValue(structuredArr[activeID])) {
      const data = item.split(' ').filter(e => e !== '')
      const formattedData = {
        x: data[0],
        y: data[1],
        color: data[2],
        colorText: data[3],
        originalData: item
      }
      if (data[2] !== '0') {
        structuredArr[activeID].data.serie.push(formattedData)
      }
    }

    return item
  })

  return structuredArr
}
