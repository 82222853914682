import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import CurrentStep from '../CurrentStep/Component'
import { useLocation, useParams } from 'react-router-dom'
import { useAppContext } from '@root/global/context'
import { checkTitle } from '@root/misc/helpers'
import { ProgressStateGbd, ProgressStateAdm, ProgressStateDtg, ProgressStateVda } from '@root/misc/constants'
import { getTranslatedText } from '@root/utils/ui'
import style from './style.module.css'

export default function HeaderAppBar (): JSX.Element {
  const location = useLocation()
  const { variant } = useParams()
  const { pageTitle, setPageTitle, progressStateAdm, progressStateGbd, progressStateDtg, progressStateVda } = useAppContext()

  React.useEffect(() => {
    const checkVariant = (value: string): string => {
      switch (value) {
        case 'home':
          return getTranslatedText('location.home')
        default:
          return getTranslatedText('location.on-location')
      }
    }

    const newPageTitle = `${checkTitle(location.pathname)} - ${checkVariant(variant ?? '')}`
    setPageTitle(newPageTitle)
  }, [location, variant, setPageTitle])

  // For each new test, this should be extended.
  const returnTestType = (value: string): string => {
    switch (true) {
      case value.startsWith('/gbd'):
        return 'gbd'
      case value.startsWith('/adm'):
        return 'adm'
      case value.startsWith('/dtg'):
        return 'dtg'
      case value.startsWith('/vda'):
        return 'vda'
      default:
        return 'Dit zou je niet moeten zien.'
    }
  }

  // For each new test, this should be extended.
  const returnProgress = (value: string): ProgressStateGbd | ProgressStateAdm | ProgressStateDtg | ProgressStateVda => {
    switch (true) {
      case value.startsWith('/gbd'):
        return progressStateGbd
      case value.startsWith('/adm'):
        return progressStateAdm
      case value.startsWith('/vda'):
        return progressStateVda
      case value.startsWith('/dtg'):
      default:
        return progressStateDtg
    }
  }

  return (
    <Box className={style.header} sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar>
          <Typography
            variant='h6'
            noWrap
            component='div'
            sx={{ display: { xs: 'none', sm: 'block' }, width: '25%' }}
          >
            {pageTitle}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          {!location.pathname.startsWith('/introduction') && (
            <CurrentStep testType={returnTestType(location.pathname)} value={returnProgress(location.pathname)} />
          )}

        </Toolbar>
      </AppBar>
    </Box>
  )
}
