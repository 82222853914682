import React from 'react'

import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { nl } from 'make-plural/plurals'

import { messages as messagesNl } from '@locales/nl/messages.js'

interface Props {
  children?: React.ReactNode
}
export const locales = {
  nl: 'Nederlands'
}
export const defaultLocale = 'nl'

i18n.loadLocaleData({
  nl: { plurals: nl }
})

i18n.load('nl', messagesNl)
i18n.activate('nl')

export default function I18nWrapper (props: Props): JSX.Element {
  return (
    <I18nProvider i18n={i18n}>
      {props.children}
    </I18nProvider>
  )
}
