export const testDataPart1 =
{
  id: 1,
  orderNumberType: 'big',
  colorNumberType: 'big',
  numberOfNumbersToFind: 5,
  rows: [
    {
      bigColor: 'red',
      bigNumbers: [
        34, 26, 40, 12, 50, 37, 25, 13, 52, 27
      ]
    },
    {
      bigColor: 'yellow',
      bigNumbers: [
        19, 16, 35, 29, 43, 20, 41, 22, 18, 49
      ]
    },
    {
      bigColor: 'green',
      bigNumbers: [
        42, 39, 14, 44, 36, 55, 17, 48, 21, 33
      ]
    },
    {
      bigColor: 'white',
      bigNumbers: [
        54, 28, 56, 51, 24, 32, 53, 10, 31, 15
      ]
    },
    {
      bigColor: 'blue',
      bigNumbers: [
        45, 57, 30, 23, 11, 47, 38, 58, 46, 59
      ]
    }
  ]
}

export const testDataPart2 =
{
  id: 1,
  orderNumberType: 'big',
  colorNumberType: 'small',
  numberOfNumbersToFind: 5,
  rows: [
    {
      bigColor: 'red',
      bigNumbers: [
        37, 24, 54, 13, 19, 31, 32, 43, 47, 53

      ],
      smallColor: 'white',
      smallNumbers: [
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19
      ]
    },
    {
      bigColor: 'yellow',
      bigNumbers: [
        15, 33, 42, 25, 55, 11, 20, 29, 58, 36

      ],
      smallColor: 'blue',
      smallNumbers: [
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29
      ]
    },
    {
      bigColor: 'green',
      bigNumbers: [
        28, 46, 10, 48, 17, 26, 56, 52, 16, 50

      ],
      smallColor: 'red',
      smallNumbers: [
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39
      ]
    },
    {
      bigColor: 'white',
      bigNumbers: [
        38, 51, 12, 39, 59, 23, 49, 41, 18, 57
      ],
      smallColor: 'green',
      smallNumbers: [
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49
      ]
    },
    {
      bigColor: 'blue',
      bigNumbers: [
        45, 40, 30, 21, 14, 34, 44, 27, 22, 35

      ],
      smallColor: 'yellow',
      smallNumbers: [
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        59
      ]
    }
  ]
}
