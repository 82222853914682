import { appContext, useAppValue } from 'global/context'

interface Props {
  children?: React.ReactNode
}

function ContextWrapper (props: Props): JSX.Element {
  return (
    <appContext.Provider value={useAppValue()}>
      {props.children}
    </appContext.Provider>
  )
}

export default ContextWrapper
