const pagesData = [
  [
    ['4_1', '3_8', '4_13', '5_4', '3_8', '4_6', '5_1', '3_2', '4_14', '5_7', '4_14', '3_7', '5_7', '4_2', '3_7', '5_11', '5_1'],
    ['3_9', '4_6', '5_11', '4_1', '3_14', '5_6', '4_2', '4_14', '3_8', '4_11', '3_5', '4_6', '5_3', '4_7', '5_7', '3_14', '5_3'],
    ['3_1', '5_1', '4_6', '3_10', '4_14', '3_9', '4_5', '3_11', '3_10', '3_7', '4_6', '5_5', '4_15', '4_3', '5_8', '5_9', '3_4'],
    ['4_10', '3_9', '4_15', '5_9', '5_5', '4_10', '5_10', '3_5', '3_7', '4_4', '3_13', '5_10', '3_8', '3_9', '5_4', '4_13', '5_12'],
    ['4_13', '3_7', '5_5', '4_6', '5_7', '5_6', '4_8', '5_3', '3_4', '3_7', '4_10', '3_14', '4_11', '3_11', '4_4', '5_11', '4_10'],
    ['3_14', '3_10', '4_1', '3_14', '4_6', '5_4', '3_9', '4_8', '3_14', '3_7', '5_1', '4_6', '4_10', '3_10', '3_13', '3_2', '5_8'],
    ['4_2', '5_10', '3_14', '4_6', '5_5', '4_14', '5_6', '4_6', '5_13', '4_6', '5_14', '5_1', '5_12', '3_5', '4_10', '5_6', '3_14'],
    ['5_1', '4_13', '3_2', '4_7', '5_4', '4_14', '3_9', '4_12', '5_5', '3_7', '5_6', '3_14', '3_4', '3_8', '4_13', '5_7', '5_10'],
    ['5_7', '4_3', '5_5', '5_10', '3_7', '4_3', '5_13', '4_15', '3_7', '4_10', '3_12', '4_11', '3_9', '4_7', '3_4', '5_14', '3_4'],
    ['4_12', '3_15', '4_13', '3_14', '3_4', '5_4', '3_4', '5_14', '3_8', '4_4', '5_11', '4_13', '4_10', '3_14', '3_14', '4_15', '3_15']
  ],
  [
    ['3_14', '4_3', '5_7', '3_14', '4_3', '3_4', '5_11', '3_11', '5_1', '4_6', '3_8', '4_9', '3_1', '3_5', '4_15', '5_14', '3_8'],
    ['5_14', '4_13', '3_11', '5_14', '4_3', '3_10', '3_9', '3_14', '4_13', '3_1', '4_2', '5_15', '4_15', '3_13', '3_1', '4_15', '5_9'],
    ['3_2', '4_9', '3_10', '5_13', '4_14', '3_13', '4_8', '3_11', '3_9', '5_3', '4_7', '3_14', '4_6', '3_8', '4_12', '3_10', '4_12'],
    ['3_4', '5_8', '5_8', '4_5', '5_3', '3_8', '4_2', '5_14', '4_6', '5_13', '3_7', '4_8', '5_14', '4_10', '3_10', '4_13', '3_10'],
    ['4_7', '3_5', '5_2', '4_1', '5_6', '4_6', '3_7', '3_5', '5_15', '4_10', '3_13', '4_2', '3_1', '5_6', '3_15', '4_12', '5_8'],
    ['3_2', '4_3', '5_11', '4_14', '5_13', '3_9', '4_5', '3_10', '4_6', '5_14', '3_2', '4_11', '5_10', '5_5', '4_12', '3_15', '5_7'],
    ['3_8', '3_7', '4_1', '3_5', '4_14', '5_3', '3_7', '4_6', '3_14', '3_5', '4_15', '3_7', '3_12', '5_8', '3_11', '3_4', '4_1'],
    ['3_8', '4_7', '3_15', '3_12', '4_3', '3_11', '4_11', '5_11', '4_14', '3_12', '4_15', '5_15', '3_5', '4_15', '3_8', '4_6', '5_1'],
    ['4_12', '5_5', '4_13', '3_7', '4_11', '5_13', '5_1', '4_11', '5_1', '5_14', '5_1', '5_14', '4_3', '3_14', '4_6', '3_9', '4_9'],
    ['5_13', '4_13', '4_6', '3_7', '4_9', '3_12', '4_8', '3_4', '4_14', '5_1', '5_13', '3_12', '5_11', '4_7', '3_5', '5_14', '3_9']
  ],
  [

    ['3_12', '3_8', '5_10', '4_12', '5_11', '4_7', '3_1', '4_10', '5_10', '5_6', '4_3', '5_11', '3_11', '4_6', '3_14', '5_7', '3_14'],
    ['4_6', '5_1', '4_13', '4_12', '5_3', '4_10', '3_1', '4_7', '5_3', '4_5', '5_4', '3_15', '3_2', '4_3', '5_3', '3_8', '5_12'],
    ['4_14', '3_9', '3_1', '4_9', '3_12', '4_8', '3_14', '3_2', '4_2', '3_12', '5_5', '4_11', '3_7', '4_3', '3_13', '4_9', '3_9'],
    ['4_2', '3_14', '5_6', '3_14', '5_7', '4_9', '3_4', '3_13', '4_11', '3_7', '4_6', '3_14', '4_6', '3_13', '4_5', '3_11', '3_5'],
    ['4_1', '5_5', '3_9', '3_2', '5_4', '4_15', '3_7', '5_8', '3_13', '5_13', '3_12', '4_9', '3_14', '4_6', '5_3', '4_11', '5_7'],
    ['4_3', '5_9', '3_14', '3_15', '4_9', '3_14', '4_1', '3_14', '3_5', '5_9', '3_12', '4_5', '5_10', '3_8', '5_8', '4_11', '3_13'],
    ['5_2', '3_9', '4_12', '3_10', '4_13', '3_14', '4_11', '3_14', '5_7', '3_4', '4_6', '3_5', '4_3', '3_4', '3_14', '4_2', '3_13'],
    ['4_2', '5_13', '3_2', '4_7', '5_8', '4_9', '3_15', '3_4', '5_15', '3_14', '4_8', '5_4', '3_1', '4_12', '3_7', '4_9', '3_15'],
    ['3_12', '4_15', '5_5', '4_6', '5_4', '4_6', '3_9', '5_12', '4_1', '3_11', '4_6', '3_7', '3_9', '3_2', '4_10', '3_11', '4_14'],
    ['5_8', '3_1', '4_1', '3_14', '3_14', '5_2', '4_1', '5_1', '4_2', '5_9', '4_15', '3_5', '4_8', '3_1', '3_10', '4_15', '5_11']
  ],
  [
    ['3_9', '5_6', '4_5', '5_13', '4_2', '5_4', '4_5', '3_7', '5_2', '5_13', '3_14', '5_2', '4_15', '5_14', '4_11', '3_15', '4_1'],
    ['5_11', '3_15', '4_8', '3_7', '5_12', '5_11', '4_11', '3_12', '3_14', '5_4', '3_14', '4_12', '4_6', '4_12', '3_7', '4_12', '3_14'],
    ['5_1', '4_2', '5_12', '3_2', '4_11', '3_10', '4_3', '4_10', '3_15', '4_5', '3_14', '4_10', '5_7', '4_15', '5_3', '3_15', '4_11'],
    ['3_14', '5_5', '5_3', '3_15', '4_4', '5_5', '3_8', '4_15', '3_14', '4_4', '5_5', '4_15', '5_2', '3_2', '4_2', '5_1', '3_11'],
    ['4_4', '3_8', '5_8', '4_6', '3_9', '4_5', '5_11', '3_11', '5_11', '4_12', '3_7', '4_13', '5_13', '3_13', '4_8', '3_4', '5_15'],
    ['3_9', '4_3', '3_15', '4_3', '3_7', '4_11', '5_14', '4_4', '3_14', '4_15', '5_1', '3_12', '4_15', '5_2', '3_14', '4_13', '3_7'],
    ['5_8', '4_1', '3_11', '5_9', '5_4', '3_7', '5_13', '3_13', '4_1', '5_1', '4_7', '5_5', '3_11', '4_13', '4_11', '3_5', '3_7'],
    ['4_1', '3_14', '4_2', '5_4', '4_3', '5_12', '3_13', '4_13', '4_6', '3_13', '4_3', '3_1', '3_4', '4_2', '3_13', '4_13', '5_8'],
    ['5_3', '3_1', '4_4', '5_9', '5_2', '3_7', '4_12', '3_14', '4_13', '3_4', '4_7', '5_3', '5_10', '3_1', '4_13', '5_14', '3_15'],
    ['4_6', '3_4', '3_4', '4_6', '5_10', '5_10', '4_8', '5_1', '3_2', '4_5', '3_7', '5_8', '5_12', '5_5', '3_8', '3_8', '5_10']
  ],
  [
    ['5_6', '4_4', '3_7', '5_15', '5_10', '3_4', '4_5', '4_8', '3_9', '4_8', '4_1', '3_1', '4_12', '3_9', '4_15', '3_5', '4_3'],
    ['3_14', '4_14', '5_12', '4_6', '4_12', '3_1', '5_8', '4_2', '5_6', '4_1', '5_14', '4_15', '3_9', '3_1', '5_12', '3_9', '4_14'],
    ['5_11', '4_11', '5_3', '4_2', '5_7', '3_8', '3_13', '5_5', '3_13', '4_7', '5_11', '3_1', '4_15', '3_1', '5_12', '5_3', '5_5'],
    ['5_14', '3_8', '4_8', '3_14', '5_4', '5_5', '3_2', '3_10', '4_8', '5_15', '5_15', '3_10', '5_14', '4_7', '3_9', '4_15', '5_14'],
    ['3_9', '4_5', '3_9', '3_14', '5_12', '3_14', '5_14', '3_9', '4_3', '3_12', '4_12', '3_12', '4_7', '3_14', '4_6', '3_8', '3_9'],
    ['5_5', '3_12', '4_2', '5_6', '4_12', '3_14', '3_8', '4_3', '5_11', '5_3', '5_4', '3_11', '5_9', '4_8', '5_4', '4_6', '5_15'],
    ['4_2', '3_1', '4_13', '3_8', '5_13', '3_14', '4_11', '5_10', '5_1', '3_9', '4_5', '3_4', '4_13', '3_7', '5_7', '5_6', '4_10'],
    ['3_1', '5_6', '4_7', '3_1', '3_10', '4_10', '3_15', '4_6', '4_2', '3_5', '4_3', '5_11', '4_6', '3_2', '4_15', '3_7', '4_14'],
    ['5_2', '3_12', '5_11', '3_9', '4_11', '5_1', '5_10', '4_4', '5_15', '3_7', '4_8', '5_4', '5_9', '3_12', '4_4', '5_13', '4_4'],
    ['5_13', '5_8', '4_5', '4_5', '3_13', '4_2', '3_10', '4_4', '3_7', '4_3', '3_13', '4_6', '5_5', '3_13', '4_11', '5_13', '4_11']
  ]
]

export const data =
{
  id: 1,
  find: 4,
  grid: pagesData.flat(1)
}

export const testData =
{
  id: 2,
  find: 4,
  grid: [
    [
      '4_1',
      '3_1',
      '5_13',
      '3_5',
      '4_11',
      '5_7',
      '4_12',
      '5_8',
      '5_8',
      '4_8',
      '5_3',
      '4_4',
      '3_15',
      '3_1',
      '4_11',
      '4_9',
      '5_12'
    ]
  ]
}

export const groupsOfFour = [6, 7, 6, 5, 7, 5, 6, 5, 6, 6, 5, 6, 7, 6, 6, 6, 5, 7, 7, 6, 5, 6, 7, 6, 5, 5, 6, 6, 7, 6, 6, 6, 8, 5, 6, 7, 5, 8, 5, 4, 8, 8, 4, 4, 5, 5, 6, 8, 5, 8]
