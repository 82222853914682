import { t } from '@lingui/macro'

const data = {
  'location.home': (value: string) => t({
    id: 'location.home',
    message: 'Thuis'
  }),
  'location.on-location': (value: string) => t({
    id: 'location.on-location',
    message: 'Op locatie'
  }),
  'gbd.progress.introduction': (value: string) => t({ id: 'gbd.progress.introduction', message: 'Introductie' }),
  'gbd.progress.instruction': (value: string) => t({ id: 'gbd.progress.instruction', message: 'Instructie' }),
  'gbd.progress.practise': (value: string) => t({ id: 'gbd.progress.practise', message: 'Oefenen' }),
  'gbd.progress.test': (value: string) => t({ id: 'gbd.progress.test', message: 'De opdracht' }),
  'gbd.progress.final': (value: string) => t({ id: 'gbd.progress.final', message: 'Resultaten insturen' }),

  'adm.progress.introduction': (value: string) => t({ id: 'adm.progress.introduction', message: 'Introductie' }),
  'adm.progress.instruction.part1': (value: string) => t({ id: 'adm.progress.instruction.part1', message: 'Instructie deel 1' }),
  'adm.progress.practise.part1': (value: string) => t({ id: 'adm.progress.practise.part1', message: 'Oefenen deel 1' }),
  'adm.progress.test.part1': (value: string) => t({ id: 'adm.progress.test.part1', message: 'De opdracht deel 1' }),
  'adm.progress.instruction.part2': (value: string) => t({ id: 'adm.progress.instruction.part2', message: 'Instructie deel 2' }),
  'adm.progress.practise.part2': (value: string) => t({ id: 'adm.progress.practise.part2', message: 'Oefenen deel 2' }),
  'adm.progress.test.part2': (value: string) => t({ id: 'adm.progress.test.part2', message: 'De opdracht deel 2' }),
  'adm.progress.final': (value: string) => t({ id: 'adm.progress.final', message: 'Resultaten insturen' }),

  'dtg.progress.introduction': (value: string) => t({ id: 'dtg.progress.introduction', message: 'Introductie' }),
  'dtg.progress.instruction.part1': (value: string) => t({ id: 'dtg.progress.instruction.part1', message: 'Instructie deel 1' }),
  'dtg.progress.practise.part1': (value: string) => t({ id: 'dtg.progress.practise.part1', message: 'Oefenen deel 1' }),
  'dtg.progress.test.part1': (value: string) => t({ id: 'dtg.progress.test.part1', message: 'De opdracht deel 1' }),
  'dtg.progress.instruction.part2': (value: string) => t({ id: 'dtg.progress.instruction.part2', message: 'Instructie deel 2' }),
  'dtg.progress.practise.part2': (value: string) => t({ id: 'dtg.progress.practise.part2', message: 'Oefenen deel 2' }),
  'dtg.progress.test.part2': (value: string) => t({ id: 'dtg.progress.test.part2', message: 'De opdracht deel 2' }),
  'dtg.progress.final': (value: string) => t({ id: 'dtg.progress.final', message: 'Resultaten insturen' }),

  'vda.progress.introduction': (value: string) => t({ id: 'vda.progress.introduction', message: 'Introductie' }),
  'vda.progress.instruction.part1': (value: string) => t({ id: 'vda.progress.instruction.part1', message: 'Instructie deel 1' }),
  'vda.progress.instruction.part2': (value: string) => t({ id: 'vda.progress.instruction.part2', message: 'Instructie deel 2' }),
  'vda.progress.instruction.part3': (value: string) => t({ id: 'vda.progress.instruction.part3', message: 'Instructie deel 3' }),
  'vda.progress.instruction.part4': (value: string) => t({ id: 'vda.progress.instruction.part4', message: 'Instructie deel 4' }),
  'vda.progress.practise1': (value: string) => t({ id: 'vda.progress.practise1', message: 'Oefenen' }),
  'vda.progress.practise2': (value: string) => t({ id: 'vda.progress.practise2', message: 'Oefenen' }),
  'vda.progress.practise3': (value: string) => t({ id: 'vda.progress.practise3', message: 'Oefenen' }),
  'vda.progress.test': (value: string) => t({ id: 'vda.progress.test.part1', message: 'De opdracht' }),
  'vda.progress.final': (value: string) => t({ id: 'vda.progress.final', message: 'Resultaten insturen' }),

  'vda.too_many_mistakes.title': (value: string) => t({ id: 'vda.too_many_mistakes.title', message: 'Helaas!' }),

  'vda.retry.answer.part1.H': (value: string) => t({
    id: 'vda.retry.answer.part1.H', message: 'Bij 5 lampjes met dezelfde kleur moet u op de “L” toets drukken. U krijgt het veld opnieuw te zien.'
  }),
  'vda.retry.answer.part1.L': (value: string) => t({
    id: 'vda.retry.answer.part1.L', message: 'Er zijn geen 5 lampjes met dezelfde kleur, U hoeft dus niet te reageren. U krijgt het veld opnieuw te zien.'
  }),
  'vda.retry.answer.part1.none': (value: string) => t({
    id: 'vda.retry.answer.part1.none', message: 'Er zijn 5 lampjes met dezelfde kleur, U moet dus op de “L” toets drukken. U krijgt het veld opnieuw te zien.'
  }),
  'vda.retry.answer.part2.H': (value: string) => t({
    id: 'vda.retry.answer.part2.H', message: 'De drie lampjes zijn niet in de volgorde groen-geel-rood verschenen, U hoeft dus niet te reageren. U krijgt de serie opnieuw te zien.'
  }),
  'vda.retry.answer.part2.L': (value: string) => t({
    id: 'vda.retry.answer.part2.L', message: 'Als de drie lampjes in de volgorde groen-geel-rood verschijnen moet u op de “H” toets drukken. U krijgt de serie opnieuw te zien.'
  }),
  'vda.retry.answer.part2.none': (value: string) => t({
    id: 'vda.retry.answer.part2.none', message: 'De drie lampjes zijn in de volgorde groen-geel-rood verschenen, U moet dus op de “H” toets drukken.U krijgt de serie opnieuw te zien.'
  }),
  'vda.retry.answer.part3.H_L': (value: string) => t({
    id: 'vda.retry.answer.part3.H_L', message: 'Bij 5 lampjes met dezelfde kleur moet u op de “L” toets drukken. U krijgt het scherm opnieuw te zien.'
  }),
  'vda.retry.answer.part3.L': (value: string) => t({
    id: 'vda.retry.answer.part3.L', message: 'De drie lampjes zijn niet in de volgorde groen-geel-rood verschenen, U hoeft dus niet te reageren. U krijgt het scherm opnieuw te zien.'
  }),
  'vda.retry.answer.part3.L_H': (value: string) => t({
    id: 'vda.retry.answer.part3.L_H', message: 'Als de drie lampjes in de volgorde groen-geel-rood verschijnen moet u op de “H” toets drukken. U krijgt het scherm opnieuw te zien.'
  }),
  'vda.retry.answer.part3.none_H': (value: string) => t({
    id: 'vda.retry.answer.part3.none_H', message: 'De drie lampjes zijn in de volgorde groen-geel-rood verschenen, U moet dus op de “H” toets drukken. U krijgt het scherm opnieuw te zien.'
  }),
  'vda.retry.answer.part3.none_L': (value: string) => t({
    id: 'vda.retry.answer.part3.none_L', message: 'Er zijn 5 lampjes met dezelfde kleur, U moet dus op de “L” toets drukken. U krijgt het scherm opnieuw te zien.'
  }),
  'vda.retry.answer.part3.none_LH': (value: string) => t({
    id: 'vda.retry.answer.part3.none_LH', message: 'Er zijn 5 lampjes met dezelfde kleur, U moet dus op de “L” toets drukken. De drie lampjes zijn in de volgorde groen- geel - rood verschenen, U moet dus ook op de “H” toets drukken.U krijgt het scherm opnieuw te zien.'
  }),
  'vda.retry.button': (value: string) => t({ id: 'vda.retry.button', message: 'Opnieuw proberen' }),
  'vda.retry.none': (value: string) => t({ id: 'vda.retry.none', message: 'Dit is foutmelding' }),
  'vda.too_many_mistakes.button': (value: string) => t({ id: 'vda.too_many_mistakes.button', message: 'Naar de instructie' }),
  'vda.too_many_mistakes.description': (value: string) => t({
    id: 'vda.too_many_mistakes.description', message: 'Je hebt te veel fouten gemaakt. Wellicht heb je de instructie niet helemaal begrepen, lees deze opnieuw.'
  }),
  'vda.correct_practise.title': (value: string) => t({ id: 'vda.correct_practise.title', message: ' Heel goed!' }),
  'vda.correct_practise.description': (value: string) => t({ id: 'vda.correct_practise.description', message: 'Je hebt de oefening voltooid. Druk op \'Doorgaan\' om de test te maken.' }),
  'vda.correct_practise.button': (value: string) => t({ id: 'vda.correct_practise.button', message: 'Doorgaan' }),
  'gbd.practise.title': (value: string) => t({ id: 'gbd.practise.title', message: 'Oefenen: ' }),
  'gbd.title': (value: string[]) => t({ id: 'gbd.title', message: `${value[0]} Markeer alle sets van ${value[1]} stippen.` }),
  'adm.incorrect_practise.description': (value: string) => t({ id: 'adm.incorrect_practise.description', message: `Dit is niet goed. Je bent op zoek naar het getal ${value}` }),
  'adm.correct_practise.description': (value: string) => t({ id: 'adm.correct_practise.description', message: `Je hebt de oefening voltooid.Druk op 'Doorgaan' om test deel ${value} te maken.` }),
  'adm.practise.cue': (value: string[]) => t({ id: 'adm.practise.cue', message: `Je zoekt nu het getal ${value[0]}. Druk op de kleur van het getal, in dit geval ${value[1]}` }),
  'adm.practise.cue2': (value: string[]) => t({ id: 'adm.practise.cue2', message: `Je zoekt nu het getal ${value[0]}. Druk op de kleur van het getal daaronder, in dit geval ${value[1]}` }),
  'dtg.reaction.breack.description': (value: string) => t({
    id: 'dtg.reaction.breack.description',
    message: `We gaan door met het volgende deel over ${value} seconden.`
  }),
  'gbd.incorrect_practise.description': (value: string) => t({ id: 'gbd.incorrect_practise.description', message: `Probeer het nogmaals.Markeer alle sets van ${value} stippen.` }),
  'gbd.correct_practise.description': (value: string) => t({ id: 'gbd.correct_practise.description', message: `Je hebt alle setjes van ${value} stippen gemarkeerd.We gaan nu door naar de echte test.` })

}

export const getTranslatedText = (key: string, value?: any): string => {
  return data[key as keyof typeof data](value ?? '')
}
