import React from 'react'
import style from './style.module.css'
import { MemoReturnDots } from './Dots'
import { variants } from './shapes'

export function GbdViewOverview (): JSX.Element {
  const tiles = []
  for (let i = 3; i < 6; i++) {
    for (let j = 1; j < 16; j++) {
      tiles.push(`${i}_${j}`)
    }
  }

  return (
    <>

      <div className={style.grid}>
        {tiles.map((d, idx) => {
          const values = d.split('_')
          const currentAmount = parseInt(values[0], 10)
          const variation = parseInt(values[1], 10) - 1

          const variantOptions = variants.filter(e => currentAmount === e.amount)[0]
          const variantOption = variantOptions.variants[variation]

          return (
            <button
              // {...buttonProps}
              key={`grid_tile_${idx}`}
              style={{ border: '1px solid black' }}
            >
              <MemoReturnDots variantOption={variantOption} />
              {d}
            </button>
          )
        })}
      </div>
    </>
  )
}
