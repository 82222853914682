import * as React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Alert, CircularProgress } from '@mui/material'
import { AdmData, DtgData, GbdData, VdaData } from '@root/misc/types'
import { Trans } from '@lingui/macro'
import axios from 'axios'
import { getApiUrl } from '@root/misc/variables'
import { useLocation } from 'react-router-dom'
import { Box } from '@mui/system'
import { hasValue } from '@root/misc/helpers'

interface Props {
  data: AdmData | DtgData | GbdData | VdaData
  variant: string
  type: 'adm' | 'dtg' | 'gbd' | 'vda'
}

enum Result {
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

// Each test shares this specific end screen.
export default function EndScreen (props: Props): JSX.Element {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [result, setResult] = React.useState<Result>(Result.PENDING)
  const btnRef = React.useRef<HTMLButtonElement>(null)
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const token = urlParams.get('token')

  const possibleKeys = ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9']

  const handleUserKeyPress = React.useCallback((event: KeyboardEvent): void => {
    if (event.repeat) {
      return
    }

    if (possibleKeys.includes(event.key)) {
      event.preventDefault()
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)

    btnRef.current?.focus()

    const postData = async (): Promise<void> => {
      const postData = {
        token,
        type: props.type,
        location: props.variant,
        data: props.data
      }
      await axios.post(getApiUrl(), JSON.stringify(postData))
        .then(response => {
          setLoading(false)
          setResult(Result.SUCCESS)
          if (typeof window !== 'undefined') {
            window.close()
          }
        })
        .catch(error => {
          setLoading(false)
          setResult(Result.ERROR)
          console.error(error)
        })
    }

    void postData()

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  if (loading) {
    return <Box sx={{ padding: 4, paddingBlockStart: 10, display: 'grid', placeItems: 'center' }}><CircularProgress /></Box>
  }

  return (
    <>
      <Stack
        direction='column'
        justifyContent='space-between'
        alignItems='center'
        spacing={1}
        sx={{ height: '100%', padding: 3 }}
      >
        <div>
          <Typography variant='h3' component='h2'>
            <Trans id='endscreen.title'>Bijna klaar!</Trans>
          </Typography>
          <Typography variant='body1' sx={{ mb: 3 }}>
            <Trans id='endscreen.description'>Je bent aan het einde gekomen van deze test.</Trans>
          </Typography>

          {
            result === Result.SUCCESS && (
              <>
                <Alert severity='success'>
                  <Trans id='endscreen.success'>Het insturen van de data is gelukt!</Trans>
                </Alert>
                <Typography variant='body1' sx={{ mb: 3, mt: 3 }}>
                  <Trans id='endscreen.closetab'>
                    Je kunt het tabblad sluiten en terugkeren naar het overzicht!
                    Vergeet niet uit het volledig scherm te gaan alvorens de tab te sluiten.
                  </Trans>
                </Typography>
              </>
            )
          }

          {
            result === Result.ERROR && (
              <>
                <Alert severity='error'><Trans id='endscreen.error'>Het insturen van de data is helaas mislukt gelukt!</Trans></Alert>
              </>
            )
          }
        </div>
        {!(hasValue(process.env.NODE_ENV) && process.env.NODE_ENV === 'production') && (
          <div className='data'>
            <pre>{JSON.stringify(props.data, null, 2)}</pre>
          </div>
        )}
      </Stack>
    </>
  )
}
