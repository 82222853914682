import * as React from 'react'
import { returnStepperValues, ProgressStateGbd, ProgressStateAdm, ProgressStateDtg, ProgressStateVda } from '@root/misc/constants'
import { Box, Typography } from '@mui/material'

interface Props {
  value: ProgressStateGbd | ProgressStateAdm | ProgressStateDtg | ProgressStateVda
  testType: string
}
export default function CurrentStep (props: Props): JSX.Element {
  const { labels, index } = returnStepperValues(props.testType, props.value)

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant='h6'
          noWrap
          component='div'
          sx={{ display: 'block' }}
        >
          {labels[index]}
        </Typography>
      </Box>

      <Typography
        variant='body2'
        noWrap
        component='div'
        sx={{ fontWeight: 700, display: 'block', width: '25%', textAlign: 'end' }}
      >
        {index + 1} / {labels.length}
      </Typography>
    </>
  )
}
