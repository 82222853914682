import React from 'react'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { data, testData1 } from './data'
import { hasValue, randomOrder, range } from '@root/misc/helpers'
import style from './style.module.css'
import { useAppContext } from '@root/global/context'
import { Color, DtgDataEntry, Peddal, Tone } from '@root/misc/types'
import LowNoise from '@audio/low_short.mp3'
import HighNoise from '@audio/high_short.mp3'
import { getRandomInt } from '../GbdView/Component'
import YipYipButtons from '@misc/YipYipButtons'
import { modalStyle, ProgressStateDtg } from '@root/misc/constants'
import { useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { useLocalStorage } from 'usehooks-ts'
import { acceptableExtraTime } from '@root/global/constants'

/*
 This is the Dtg Test.

 This test consists of two parts. This part is the time-based assignment. The other (DTGViewReaction component) is the reaction-based assignment.

 This component contains both the practise as the actual part.

 There are two variants of the test. Home and on-location.
 The practise part is a set of 30 stimuli, each with 1,3s (1300 ms) of time enter an answer.

 The other part are 3 sets of 100 stimuli. each set with a different time to enter. These are 1,1s (1100ms), 1,0s (1000ms) and 0,8s(800ms)
 For the home-variant, all these values are customizable

 The answers can be one of the 5 colors (red, blue, green, yellow and white), a high or a low pitch sound or one of the two peddals.
  the Home variant doesn't have the peddals.

  You can only answer once per prompt. If you answer within 100ms it count for the previous propmt. The duration is being sent and if you were corerct, incorrect, late or have omitted.
*/

interface Props {
  endFn: () => void
  variant: string
  isPractise?: boolean
}

interface GivenAnswer {
  itemNumber: number
  reactionTime: number
  givenSignal: number
  askedSignal: number
  result: 'correct' | 'late' | 'incorrect' | 'omitted' | 'first-entry'
}

const getDuration = (idx: number, data: any, durations?: string): number => {
  const durationsArr = durations?.split(';').map((a: string) => parseInt(a, 10)) ?? []
  if (durationsArr.length !== 3) {
    if (idx < data[0].length) {
      return 1100
    } else if (idx < ((data[0].length as number) + (data[1].length as number))) {
      return 1000
    } else {
      return 800
    }
  } else {
    if (idx < data[0].length) {
      return durationsArr[0] ?? 1100
    } else if (idx < ((data[0].length as number) + (data[1].length as number))) {
      return durationsArr[1] ?? 1000
    } else {
      return durationsArr[2] ?? 800
    }
  }
}

const returnAnswer = (val: number, idx: number): string => {
  switch (val) {
    case 1:
      return 'rood'
    case 2:
      return 'groen'
    case 3:
      return 'geel'
    case 4:
      return 'blauw'
    case 5:
      return 'wit'
    case 6:
      return 'laag'
    case 7:
      return 'hoog'
    case 8:
      return 'links'
    case 9:
    default:
      return 'rechts'
  }
}

const returnElement = (val: number, idx: number): JSX.Element => {
  switch (val) {
    case 1:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-red-500)' }} />
    case 2:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-green-500)' }} />
    case 3:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-yellow-500)' }} />
    case 4:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-blue-500)' }} />
    case 5:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-white-100)' }} />
    case 6:
      return <audio key={idx} src={LowNoise} autoPlay loop />
    case 7:
      return <audio key={idx} src={HighNoise} autoPlay loop />
    case 8:
      return <div key={idx} className={style['left-square']} />
    case 9:
    default:
      return <div key={idx} className={style['right-square']} />
  }
}

const possibleAnswers = [Color.red, Color.green, Color.yellow, Color.blue, Color.white, Tone.low, Tone.high, Peddal.left, Peddal.right]

const generateData = (isPractise?: boolean, stimuli?: string): number[][] => {
  const stimuliArr = stimuli?.split(';') ?? []
  const getData = (amount: number | number[], sets: number): number[][] => {
    const data: number[][] = []
    for (let i = 0; i < sets; i++) {
      const rangeArr = range(0, (Array.isArray(amount) ? amount[i] : amount) - 1)
      data.push(rangeArr.map((n: number, j: number) => {
        const prevValue: number = hasValue(rangeArr[j - 1]) ? rangeArr[j - 1] : 0
        return n === 0 ? (getRandomInt(5, prevValue) + 1) : (getRandomInt(7, prevValue) + 1)
      }))
    }
    return data
  }

  if (hasValue(isPractise) && isPractise) {
    return getData(30, 1)
  } else {
    if (stimuliArr.length === 0) {
      return getData(100, 3)
    } else {
      return getData(stimuliArr.map((a: string) => parseInt(a, 10)), stimuliArr.length)
    }
  }
}

export function DtgView (props: Props): JSX.Element {
  const [signalTimes, setSignalTimes] = React.useState<number[]>([])
  const [currentTime, setCurrentTime] = React.useState<Date>(new Date())
  const { stimuli, durations } = useParams()
  const { setDtgData, setProgressStateDtg } = useAppContext()
  const [isLeftPedalF8] = useLocalStorage<string | null>('isLeftPedalF8', null)

  const [correctOpen, setCorrectOpen] = React.useState(false)
  const [returnOpen, setReturnOpen] = React.useState(false)
  const [startTime, setStartTime] = React.useState<Date>(new Date())
  const [isPaused, setIsPaused] = React.useState<boolean>(false)

  const [currentVisibleAnswer, setCurrentVisibleAnswer] = React.useState<number>(-1)
  const [givenAnswers, setGivenAnswers] = React.useState<GivenAnswer[]>([])
  const [isPossibleToAnswer, setIsPossibleToAnswer] = React.useState<boolean>(true)
  const [shouldProceed, setShouldProceed] = React.useState<boolean>(false)

  const modalCorrectBtnRef = React.useRef<HTMLButtonElement>(null)
  const modalReturnBtnRef = React.useRef<HTMLButtonElement>(null)

  const dataToUse: any = React.useMemo(() => {
    const arrData: any = props.variant === 'home' ? generateData(props.isPractise, stimuli) : hasValue(props.isPractise) ? [testData1] : data
    return arrData.flatMap((a: number) => a)
  }, [props.variant, props.isPractise, stimuli])

  const origData: any = React.useMemo(() => {
    return props.variant === 'home' ? generateData(props.isPractise, stimuli) : hasValue(props.isPractise) ? [testData1] : data
  }, [props.variant, props.isPractise, stimuli])

  const pauseMoments: number[] = React.useMemo(() => {
    const stimuliArrLength = stimuli?.split(';').length
    if (hasValue(props.isPractise) || stimuliArrLength === 1) {
      return []
    }

    const arrLengths: number[] = []
    origData.reduce(
      (accumulator: number, currentValue: any[]) => {
        const add = accumulator + currentValue.length
        arrLengths.push(add)
        return add
      }, 0)

    return arrLengths
  }, [origData, props.isPractise])

  const screenWidth = 800 - 16
  const screenHeight = props.variant === 'home' ? 384 : 600 - 16
  const circleSize = 70
  const xPositions = React.useMemo(() => range(circleSize / 2, screenWidth - (circleSize / 2)).sort(randomOrder), [screenWidth])
  const yPositions = React.useMemo(() => range(circleSize / 2, screenHeight - (circleSize / 2)).sort(randomOrder), [screenHeight])

  React.useEffect(() => {
    setTimeout(() => {
      setCurrentVisibleAnswer(0)
      setCurrentTime(new Date())
    }, 250)
  }, [])

  React.useEffect(() => {
    if (shouldProceed) {
      setDtgData((old: DtgDataEntry[][]) => {
        const newArr = [...old]
        newArr[0] = dataToUse.map((d: number, idx: number): DtgDataEntry => {
          const signalTime = signalTimes[idx]
          const givenAnswer = givenAnswers.filter(g => g.itemNumber === idx)?.[0]
          const part = (pauseMoments.findIndex(n => idx < n) ?? 0) + 1
          const duration = getDuration(idx, origData, durations)

          const defaultAnswer = {
            itemNumber: idx,
            reactionTime: 0,
            givenSignal: -1,
            askedSignal: d,
            result: 'omitted' as 'incorrect' | 'correct' | 'late' | 'omitted' | 'first-entry',
            actualSignalTime: signalTime,
            setSignalTime: duration,
            part,
            variant: 'time-based' as 'time-based' | 'reaction-based'
          }

          return hasValue(givenAnswer)
            ? {
                ...givenAnswer,
                actualSignalTime: signalTime,
                setSignalTime: duration,
                part,
                variant: 'time-based'
              }
            : defaultAnswer
        }).sort((a: DtgDataEntry, b: DtgDataEntry) => a.itemNumber - b.itemNumber)
        return newArr
      })
      props.endFn()
      setShouldProceed(false)
    }
  }, [shouldProceed, dataToUse, givenAnswers, props, setDtgData])

  const setNextAnswer = (): void => {
    setCurrentVisibleAnswer(old => old + 1)
    setIsPossibleToAnswer(true)
    setStartTime(new Date())
  }

  React.useEffect(() => {
    if (isPaused) {
      const duration = getDuration(currentVisibleAnswer, origData, durations)
      const timer = setTimeout(() => {
        setNextAnswer()
        setIsPaused(false)
      }, duration)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isPaused, durations, origData, currentVisibleAnswer])

  React.useEffect(() => {
    if (currentVisibleAnswer === dataToUse.length - 1) {
      if (hasValue(props.isPractise)) {
        givenAnswers.length < 10 || givenAnswers.filter(a => a.result !== 'correct').length >= 10 ? handleReturnOpen() : handleCorrectOpen()
      } else {
        const duration = hasValue(props.isPractise) ? 1300 : getDuration(currentVisibleAnswer, origData, durations)
        const timer = setTimeout(() => {
          const thisDate = new Date()
          const difference = Math.abs(thisDate.getTime() - currentTime?.getTime())
          setCurrentTime(thisDate)
          setSignalTimes(old => [...old, difference])

          setShouldProceed(true)
        }, duration)
        return () => {
          clearTimeout(timer)
        }
      }
    } else {
      const duration = hasValue(props.isPractise) ? 1300 : getDuration(currentVisibleAnswer, origData, durations)
      const timer = setTimeout(() => {
        const thisDate = new Date()
        const difference = Math.abs(thisDate.getTime() - currentTime?.getTime())
        setCurrentTime(thisDate)
        setSignalTimes(old => [...old, difference])

        if (pauseMoments.includes(currentVisibleAnswer + 1)) {
          setIsPaused(true)
        } else {
          setNextAnswer()
        }
      }, duration)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [currentVisibleAnswer, dataToUse.length, durations, origData, pauseMoments, props.isPractise])

  const giveAnswer = React.useCallback((answer: Color | Tone | Peddal): void => {
    if (isPossibleToAnswer) {
      const beginTime = startTime ?? new Date('01-01-1970')
      const endTime = new Date()
      const timeMilliSeconds = Math.abs(beginTime.getTime() - endTime?.getTime())

      setGivenAnswers((old: GivenAnswer[]): GivenAnswer[] => {
        let result = 'incorrect'
        const givenSignal = possibleAnswers.findIndex(i => i === answer) + 1
        const possibleAnswersToCheck = timeMilliSeconds < acceptableExtraTime ? Math.max(currentVisibleAnswer - 1, 0) : currentVisibleAnswer
        const askedSignal = dataToUse[possibleAnswersToCheck]
        if (askedSignal === givenSignal) {
          if (timeMilliSeconds < acceptableExtraTime) {
            result = 'late'
          } else {
            result = 'correct'
            setIsPossibleToAnswer(false)
          }
        } else {
          setIsPossibleToAnswer(false)
        }

        if (possibleAnswersToCheck === 0) {
          result = 'first-entry'
        }

        return [
          ...old,
          {
            itemNumber: possibleAnswersToCheck,
            reactionTime: timeMilliSeconds < acceptableExtraTime ? timeMilliSeconds + (getDuration(currentVisibleAnswer - 1, origData) ?? 1100) : timeMilliSeconds,
            givenSignal,
            askedSignal,
            result: result as 'incorrect' | 'correct' | 'late' | 'first-entry'
          }
        ]
      })
    }
  }, [currentVisibleAnswer, dataToUse, isPossibleToAnswer, origData, startTime])

  const handleUserKeyPress = React.useCallback((event: KeyboardEvent): void => {
    if (event.repeat) { return }

    const possibleKeys = ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9']
    if (possibleKeys.includes(event.key)) {
      event.preventDefault()

      const keyMap = {
        F1: Color.white,
        F2: Color.blue,
        F3: Color.yellow,
        F4: Color.red,
        F5: Color.green,
        F6: Tone.low,
        F7: Tone.high,
        F8: isLeftPedalF8 === 'true' ? Peddal.left : Peddal.right,
        F9: isLeftPedalF8 === 'true' ? Peddal.right : Peddal.left
      }

      if (!correctOpen) {
        const { key } = event
        giveAnswer((keyMap as any)[key])
      }
    }
  }, [correctOpen, giveAnswer])

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  const handleCorrectOpen = (): void => {
    setCorrectOpen(true)
    setTimeout(() => {
      modalCorrectBtnRef.current?.focus()
    }, 100)
  }
  const handleCorrectClose = (): void => {
    setCorrectOpen(false)
    props.endFn()
  }

  const handleReturnOpen = (): void => {
    setReturnOpen(true)
    setTimeout(() => {
      modalReturnBtnRef.current?.focus()
    }, 100)
  }
  const handleReturnClose = (): void => {
    setReturnOpen(false)
    setProgressStateDtg(ProgressStateDtg.InstructionPart1Page1)
  }

  return (
    <>

      <Modal
        disableEscapeKeyDown
        disableAutoFocus
        keepMounted
        open={returnOpen}
        aria-labelledby='modal-return-title'
        aria-describedby='modal-return-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-return-title' variant='h6' component='h2'>
                <Trans id='dtg.too_many_mistakes.title'>
                  Helaas!
                </Trans>
              </Typography>
              <Typography id='modal-return-description' sx={{ mt: 2 }}>
                <Trans id='dtg.too_many_mistakes.description'>
                  Je hebt te veel fouten gemaakt. Wellicht heb je de instructie niet helemaal begrepen, lees deze opnieuw.
                </Trans>
              </Typography>
            </div>
            <Button ref={modalReturnBtnRef} color='primary' variant='contained' onClick={() => handleReturnClose()}><Trans id='dtg.too_many_mistakes.button'>Naar de instructie</Trans></Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        disableAutoFocus
        keepMounted
        open={correctOpen}
        onClose={handleCorrectClose}
        aria-labelledby='modal-correct-title'
        aria-describedby='modal-correct-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-correct-title' variant='h6' component='h2'>
                <Trans id='dtg.correct_practise.title'>
                  Heel goed!
                </Trans>
              </Typography>
              <Typography id='modal-correct-description' sx={{ mt: 2 }}>
                <Trans id='dtg.correct_practise.description'>
                  Je hebt de oefening voltooid. Druk op 'Doorgaan' om test deel 1 te maken.
                </Trans>
              </Typography>
            </div>
            <Button ref={modalCorrectBtnRef} color='primary' variant='contained' onClick={() => handleCorrectClose()}><Trans id='dtg.correct_practise.button'>Doorgaan</Trans></Button>
          </Stack>
        </Box>
      </Modal>

      {!(hasValue(process.env.NODE_ENV) && process.env.NODE_ENV === 'production') && (
        <div className='data'>
          <pre>{JSON.stringify({ startTime, currentVisibleAnswer, answer: returnAnswer(dataToUse[currentVisibleAnswer], currentVisibleAnswer), givenAnswers, signalTimes }, null, 2)}</pre>
        </div>
      )}

      <Stack direction='column' sx={{ height: '100%' }}>
        <Stack sx={{ flex: 1, padding: 2, background: '#B2B2B0', position: 'relative' }} justifyContent={hasValue(props.isPractise) ? 'flex-start' : 'space-between'}>
          <Box
            style={
              ![8, 9].includes(dataToUse[currentVisibleAnswer])
                ? { position: 'absolute', left: xPositions[currentVisibleAnswer], top: yPositions[currentVisibleAnswer] }
                : {
                    position: 'absolute',
                    left: dataToUse[currentVisibleAnswer] === 8 ? '16px' : 'unset',
                    right: dataToUse[currentVisibleAnswer] === 9 ? '16px' : 'unset',
                    bottom: '16px'
                  }
            }
          >
            {
              !isPaused &&
              currentVisibleAnswer !== -1 &&
              !returnOpen &&
              !correctOpen &&
              returnElement(dataToUse[currentVisibleAnswer], currentVisibleAnswer)
            }
          </Box>
        </Stack>

        {props.variant === 'home' && (
          <Stack sx={{ height: '200px', flex: '0 0 200px', p: '10px', background: '#B2B2B0' }} direction='column' spacing='20px'>
            <Stack sx={{ height: '80px', flex: '0 0 80px' }} direction='row' spacing={2}>
              <YipYipButtons.ButtonRed variant='contained' onClick={() => giveAnswer(Color.red)}><Trans id='general.color.red'>rood</Trans></YipYipButtons.ButtonRed>
              <YipYipButtons.ButtonYellow variant='contained' onClick={() => giveAnswer(Color.yellow)}><Trans id='general.color.yellow'>geel</Trans></YipYipButtons.ButtonYellow>
              <YipYipButtons.ButtonGreen variant='contained' onClick={() => giveAnswer(Color.green)}><Trans id='general.color.green'>groen</Trans></YipYipButtons.ButtonGreen>
              <YipYipButtons.ButtonWhite variant='contained' onClick={() => giveAnswer(Color.white)}><Trans id='general.color.white'>wit</Trans></YipYipButtons.ButtonWhite>
              <YipYipButtons.ButtonBlue variant='contained' onClick={() => giveAnswer(Color.blue)}><Trans id='general.color.blue'>blauw</Trans></YipYipButtons.ButtonBlue>
            </Stack>
            <Stack sx={{ height: '80px', flex: '0 0 80px' }} direction='row' spacing={2}>
              <YipYipButtons.ButtonBlack variant='contained' onClick={() => giveAnswer(Tone.low)}><Trans id='general.tone.low'>Lage tonen</Trans></YipYipButtons.ButtonBlack>
              <YipYipButtons.ButtonBlack variant='contained' onClick={() => giveAnswer(Tone.high)}><Trans id='general.tone.high'>Hoge tonen</Trans></YipYipButtons.ButtonBlack>
            </Stack>
          </Stack>
        )}
      </Stack>

    </>
  )
}
