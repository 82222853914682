import React from 'react'
import clsx from 'clsx'
import style from './style.module.css'
import { hasValue, randomOrder } from '@root/misc/helpers'
import { MemoReturnDots } from './Dots'
import { variants } from './shapes'
import { data, groupsOfFour, testData } from './data'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { useAppContext } from '@root/global/context'
import { columnAmount, modalStyle, ProgressStateGbd, rowAmount } from '@root/misc/constants'
import { Trans } from '@lingui/macro'
import { getTranslatedText } from '@root/utils/ui'

/*
 This is the Gbd Test.

 There are 2 variants. Home and onlocation. And there is also a practise and an actual test phase.

 The on location variant has a specific set of data where the home variant is generated according to a set of rules (amount of sets of 4 fout per line)

 The display is a grid of 10 rows of 17 sets of dots. There could be multiple pages of them, this is optional to be more. In that case you progress until all pages are done.

 You have to select all sets of four by using the arrow keys. Left and Right to progress and go back, Up  to select/deselect. You can only return 1 block, and only on your current row.

 The duration per row is being tracked, just as the amount correct, incorrect, corrected and omitted.

*/

type DotGridRow = string[]

interface DotGrid {
  find: number
  id: number | string
  grid: DotGridRow[]
}

interface GridTile {
  amount: number
  variation: number
  isSelected: boolean
  isCorrect: boolean
  isPromptedAmount: boolean
  hasBeenChanged: boolean
}

interface RowTime {
  beginTime?: Date
  endTime?: Date
  rowId: number
}

export function getRandomInt (max: number, exclude: number): number {
  const value = Math.floor(Math.random() * max)
  if (value !== exclude) {
    return value
  }
  return getRandomInt(max, exclude)
}

interface Props {
  endFn: () => void
  variant: string
  pages?: number
}

// q: what does this following function do?
// a: it generates a random grid of dots
const returnGeneratedData = (pages: number): DotGrid => {
  const grid = []
  const rows = pages * rowAmount

  for (let i = 0; i < rows; i++) {
    const rowArr = []
    for (let j = 0; j < columnAmount; j++) {
      if (j < groupsOfFour[i % groupsOfFour.length]) {
        rowArr.push(4)
      } else {
        rowArr.push(Math.random() > 0.5 ? 3 : 5)
      }
    }
    rowArr.sort(randomOrder)
    const rowStrArr = rowArr.map(rw => {
      return `${rw.toString()}_${Math.ceil(Math.random() * 15).toString()}`
    })
    grid.push(rowStrArr)
  }

  return {
    find: 4,
    id: 'generated',
    grid
  }
}

type DefaultValues = Pick<Props, 'pages'>

const defaults: DefaultValues = {
  pages: 1
}

export function GbdView (defaultProps: Props): JSX.Element {
  const props = React.useMemo<Props>(() => {
    return { ...defaults, ...defaultProps }
  }, [defaultProps])
  const dataToUse: DotGrid = props.variant === 'on-location' ? data : props.variant === 'practise' ? testData : returnGeneratedData(props.pages ?? 1)
  const amount = dataToUse.find
  const [selectedRow, setSelectedRow] = React.useState<number>(0)
  const [selected, setSelected] = React.useState<number>(0)
  const [maxSelected, setMaxSelected] = React.useState<number>(0)
  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const btnRef = React.useRef<HTMLButtonElement>(null)
  const { setGbdData } = useAppContext()
  const [shouldProceed, setShouldProceed] = React.useState<boolean>(false)
  const { setProgressStateGbd } = useAppContext()
  const modalReturnBtnRef = React.useRef<HTMLButtonElement>(null)

  const [gridTiles, setGridTiles] = React.useState<GridTile[]>([])
  const [rowTimes, setRowTimes] = React.useState<RowTime[]>([])
  const modalIncorrectBtnRef = React.useRef<HTMLButtonElement>(null)
  const modalCorrectBtnRef = React.useRef<HTMLButtonElement>(null)

  const [open, setOpen] = React.useState(false)
  const [correctOpen, setCorrectOpen] = React.useState(false)
  const [, setAmountPractiseIncorrect] = React.useState<number>(0)

  // amountCorrect = selected and correct || not selected and correct
  // amountIncorrect = selected and incorrect
  // amountOmitted = not selected and incorrect
  // amountCorrected = not selected and correct, but changed

  const calculatedData = rowTimes.map((rt: RowTime, idx: number) => {
    const beginTime = rt.beginTime ?? new Date('01-01-1970')
    const endTime = rt.endTime ?? new Date('01-01-1970')
    const timeSeconds = Math.abs(beginTime.getTime() - endTime?.getTime()) / 1000

    return {
      pageNumber: Math.floor(idx / rowAmount) + 1,
      rowNumber: (idx % rowAmount) + 1,
      duration: timeSeconds,
      amountCorrected: gridTiles.slice(idx * columnAmount, (idx + 1) * columnAmount).filter(d => d.isCorrect && d.hasBeenChanged).length,
      amountIncorrect: gridTiles.slice(idx * columnAmount, (idx + 1) * columnAmount).filter(d => !d.isCorrect && d.isSelected).length,
      amountOmitted: gridTiles.slice(idx * columnAmount, (idx + 1) * columnAmount).filter(d => !d.isCorrect && !d.isSelected).length
    }
  })

  React.useEffect(() => {
    if (shouldProceed) {
      setGbdData(calculatedData)
      props.endFn()
      setShouldProceed(false)
    }
  }, [shouldProceed, calculatedData, props, setGbdData])

  const resetState = React.useCallback((): void => {
    setSelected(0)
    setSelectedRow(0)
    setMaxSelected(0)
    setRowTimes([])
    setRowTimes((old: RowTime[]): RowTime[] => {
      const newArr = [...old]
      for (let i = 0; i < dataToUse.grid.length; i++) {
        newArr[i] = {
          rowId: i
        }
      }
      newArr[0] = {
        ...newArr[0],
        beginTime: new Date()
      }
      return newArr
    })

    for (let i = 0; i < dataToUse.grid.flat().length; i++) {
      setGridTiles((old: GridTile[]): GridTile[] => {
        const newArr = [...old]
        const values = dataToUse.grid.flat()[i].split('_')
        const currentAmount = parseInt(values[0], 10)
        newArr[i] = {
          amount: currentAmount,
          variation: parseInt(values[1], 10) - 1,
          isSelected: false,
          isPromptedAmount: currentAmount !== amount,
          isCorrect: currentAmount !== amount,
          hasBeenChanged: false
        }
        return newArr
      })
    }
  }, [amount, dataToUse.grid])

  React.useEffect(() => {
    resetState()
  }, [])

  // q: what does this following function do, please be as in-depth as possible?
  // a: it handles the keypresses for the arrow keys, and handles the logic for selecting and deselecting dots, and going to the next row or page
  const handleUserKeyPress = React.useCallback((event: KeyboardEvent): void => {
    if (event.repeat) { return }

    const possibleKeys = ['ArrowRight', 'ArrowLeft', 'ArrowUp']
    if (possibleKeys.includes(event.key)) {
      event.preventDefault()

      if (!open && !correctOpen) {
        const { key } = event
        if (key === 'ArrowRight') {
          setSelected((old: number): number => {
            if ((old + 1) % columnAmount === 0) {
              setRowTimes((oldRowTime: RowTime[]): RowTime[] => {
                const newArr = [...oldRowTime]
                const date = new Date()
                newArr[selectedRow] = {
                  ...newArr[selectedRow],
                  endTime: date
                }
                if (old !== (dataToUse.grid.flat().length) - 1) {
                  newArr[selectedRow + 1] = {
                    ...newArr[selectedRow + 1],
                    beginTime: date
                  }
                }
                return newArr
              })
              setSelectedRow(old => old + 1)
            }
            if ((old + 1) % (rowAmount * columnAmount) === 0) {
              setCurrentPage(old => old + 1)
            }
            if (old !== (dataToUse.grid.flat().length) - 1) {
              const newVal = old + 1
              // Set max selected, so that
              setMaxSelected((oldMax: number): number => {
                const newMaxVal = Math.max(newVal, oldMax)
                return newMaxVal
              })
              return newVal
            } else {
              // This is the end of the test/page
              if (props.variant === 'practise') {
                if ((calculatedData[0].amountIncorrect + calculatedData[0].amountOmitted) === 0) {
                  handleCorrectOpen()
                } else {
                  setAmountPractiseIncorrect(old => {
                    if (old === 2) {
                      handleReturnOpen()
                      return old
                    } else {
                      handleOpen()
                      return old + 1
                    }
                  })
                }
              } else {
                setShouldProceed(true)
              }
            }
            return old
          })
          hasValue(btnRef.current) && btnRef.current.focus()
        }

        if (key === 'ArrowLeft') {
          setSelected((old: number): number => {
            if (old !== 0 && old === maxSelected && old % columnAmount !== 0) {
              return old - 1
            }
            return old
          })
          hasValue(btnRef.current) && btnRef.current.focus()
        }

        if (key === 'ArrowUp') {
          setGridTiles((old: GridTile[]): GridTile[] => {
            const newArr = [...old]
            newArr[selected].hasBeenChanged = old[selected].isSelected || (!old[selected].isSelected && selected !== maxSelected)
            newArr[selected].isSelected = !newArr[selected].isSelected
            newArr[selected].isCorrect = (newArr[selected].amount === amount && newArr[selected].isSelected) || (newArr[selected].amount !== amount && !newArr[selected].isSelected)
            return newArr
          })
        }
      }
    }
  }, [amount, correctOpen, dataToUse.grid, calculatedData, maxSelected, open, props.variant, selected, selectedRow])

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  const handleOpen = (): void => {
    setOpen(true)
    setTimeout(() => {
      modalIncorrectBtnRef.current?.focus()
    }, 100)
  }
  const handleCorrectOpen = (): void => {
    setCorrectOpen(true)

    setTimeout(() => {
      modalCorrectBtnRef.current?.focus()
    }, 100)
  }
  const handleClose = (): void => {
    setOpen(false)
    resetState()
  }
  const handleCorrectClose = (): void => {
    setCorrectOpen(false)
    props.endFn()
  }

  const [returnOpen, setReturnOpen] = React.useState(false)
  const handleReturnOpen = (): void => {
    setReturnOpen(true)
    setTimeout(() => {
      modalReturnBtnRef.current?.focus()
    }, 100)
  }
  const handleReturnClose = (): void => {
    setReturnOpen(false)
    setProgressStateGbd(ProgressStateGbd.Instruction)
  }

  const practiseTitle = props.variant === 'practise' ? getTranslatedText('gbd.practise.title') : ''

  return (
    <>
      {!(hasValue(process.env.NODE_ENV) && process.env.NODE_ENV === 'production') && (
        <div className='data'>
          <pre>{JSON.stringify(calculatedData, null, 2)}</pre>
        </div>
      )}

      <Modal
        disableEscapeKeyDown
        disableAutoFocus
        keepMounted
        open={returnOpen}
        aria-labelledby='modal-return-title'
        aria-describedby='modal-return-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-return-title' variant='h6' component='h2'>
                <Trans id='gbd.too_many_mistakes.title'>
                  Je hebt 3 fouten gemaakt!
                </Trans>
              </Typography>
              <Typography id='modal-return-description' sx={{ mt: 2 }}>
                <Trans id='gbd.too_many_mistakes.description'>
                  Wellicht heb je de instructie niet helemaal begrepen, lees deze opnieuw.
                </Trans>
              </Typography>
            </div>
            <Button ref={modalReturnBtnRef} color='primary' variant='contained' onClick={() => handleReturnClose()}><Trans id='gbd.too_many_mistakes.button'>Naar de instructie</Trans></Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        disableAutoFocus
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-incorrect-title'
        aria-describedby='modal-incorrect-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-incorrect-title' variant='h6' component='h2'>
                <Trans id='gbd.incorrect_practise.title'>
                  Helaas, niet alles is correct.
                </Trans>
              </Typography>
              <Typography id='modal-incorrect-description' sx={{ mt: 2 }}>

                {getTranslatedText('gbd.incorrect_practise.description', amount)}

              </Typography>
            </div>
            <Button color='primary' ref={modalIncorrectBtnRef} variant='contained' onClick={() => handleClose()}><Trans id='gbd.incorrect_practise.button'>Probeer opnieuw</Trans></Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        disableAutoFocus
        keepMounted
        open={correctOpen}
        onClose={handleCorrectClose}
        aria-labelledby='modal-correct-title'
        aria-describedby='modal-correct-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-correct-title' variant='h6' component='h2'>
                <Trans id='gbd.correct_practise.title'>
                  Heel goed!
                </Trans>
              </Typography>
              <Typography id='modal-correct-description' sx={{ mt: 2 }}>

                {getTranslatedText('gbd.correct_practise.description', amount)}

              </Typography>
            </div>
            <Button color='primary' ref={modalCorrectBtnRef} variant='contained' onClick={() => handleCorrectClose()}><Trans id='gbd.correct_practise.button'>Doorgaan</Trans></Button>
          </Stack>
        </Box>
      </Modal>

      <div>
        <Typography align='center' sx={{ m: 1, mb: 2 }} variant='h5' component='h2'>
          {getTranslatedText('gbd.title', [practiseTitle, amount])}
        </Typography>
      </div>

      <div className={style.grid}>
        {gridTiles.slice(
          (currentPage * (rowAmount * columnAmount)),
          ((currentPage + 1) * (rowAmount * columnAmount))
        ).map((d, idx) => {
          const thisIdx = (idx + (currentPage * (rowAmount * columnAmount)))
          const buttonProps = selected === thisIdx ? { ref: btnRef } : {}
          const variantOptions = variants.filter(e => d.amount === e.amount)[0]
          const variantOption = variantOptions.variants[d.variation]
          return (
            <button
              {...buttonProps}
              key={`grid_tile_${idx}`}
              className={
                clsx(
                  selected === thisIdx && style.active,
                  d.isSelected && style.selected
                )
              }
            >
              <MemoReturnDots variantOption={variantOption} />
            </button>
          )
        })}
      </div>

      {(props.pages ?? 1) > 1 && (
        <div>
          <Typography align='center' sx={{ m: 1, mb: 2 }} variant='body1'>
            {(currentPage + 1)} / {(props.pages ?? 1)}
          </Typography>
        </div>
      )}
    </>
  )
}
