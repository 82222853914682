import * as React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Button, Chip } from '@mui/material'
import { t } from '@lingui/macro'
import { hasValue } from '@root/misc/helpers'

interface Props {
  title: string
  label: string
  description: JSX.Element
  nextFn: () => void
  part?: number
}

// Each test shares this specific introduction screen.
export default function Introduction (props: Props): JSX.Element {
  const btnRef = React.useRef<HTMLButtonElement>(null)

  const possibleKeys = ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9']

  const handleUserKeyPress = React.useCallback((event: KeyboardEvent): void => {
    if (event.repeat) {
      return
    }

    if (possibleKeys.includes(event.key)) {
      event.preventDefault()
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    btnRef.current?.focus()
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      alignItems='center'
      spacing={1}
      sx={{ height: '100%', padding: 3, width: '100%' }}
    >
      <div style={{ width: '100%' }}>
        {hasValue(props.part) && (
          <Chip
            label={t({
              id: 'introduction.part',
              message: `Pagina ${props.part ?? ''}`
            })}
          />
        )}
        <Typography variant='h3' component='h2'>
          {props.title}
        </Typography>
        {props.description}
      </div>
      <Button
        color='primary'
        variant='contained'
        ref={btnRef}
        onClick={() => props.nextFn()}
      >
        {props.label}
      </Button>
    </Stack>
  )
}
