import React from 'react'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { reactionData, testData1, testData2 } from './data'
import { hasValue, randomOrder, range } from '@root/misc/helpers'
import style from './style.module.css'
import { useAppContext } from '@root/global/context'
import { Color, DtgDataEntry, Peddal, Tone } from '@root/misc/types'
import LowNoise from '@audio/low_short.mp3'
import HighNoise from '@audio/high_short.mp3'
import { getRandomInt } from '../GbdView/Component'
import YipYipButtons from '@misc/YipYipButtons'
import { modalStyle, ProgressStateDtg } from '@root/misc/constants'
import { useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { getTranslatedText } from '@root/utils/ui'
import { useLocalStorage } from 'usehooks-ts'

/*
 This is the Dtg Test.

 This test consists of two parts. This part is the reaction-based assignment. The other (DTGView component) is the time-based assignment.

 This component contains both the practise as the actual part.

 There are two variants of the test. Home and on-location.
 The practise part is a 60 second long period where you answer as much as possible stimuli.

 The other part are 2 sets of 150 seconds.
 For the home-variant, all these values are customizable

 The answers can be one of the 5 colors (red, blue, green, yellow and white), a high or a low pitch sound or one of the two peddals.
  the Home variant doesn't have the peddals.

  You can only answer once per prompt. The duration is being sent and if you were corerct or incorrect.
*/

interface Props {
  endFn: () => void
  variant: string
  pages?: number
  isPractise?: boolean
}

interface GivenAnswer {
  itemNumber: number
  reactionTime: number
  givenSignal: number
  askedSignal: number
  result: 'correct' | 'late' | 'incorrect' | 'omitted' | 'first-entry'
}

const returnAnswer = (val: number, idx: number): string => {
  switch (val) {
    case 1:
      return 'rood'
    case 2:
      return 'groen'
    case 3:
      return 'geel'
    case 4:
      return 'blauw'
    case 5:
      return 'wit'
    case 6:
      return 'laag'
    case 7:
      return 'hoog'
    case 8:
      return 'links'
    case 9:
    default:
      return 'rechts'
  }
}

const returnElement = (val: number, idx: number): JSX.Element => {
  switch (val) {
    case 1:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-red-500)' }} />
    case 2:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-green-500)' }} />
    case 3:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-yellow-500)' }} />
    case 4:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-blue-500)' }} />
    case 5:
      return <div key={idx} className={style.circle} style={{ backgroundColor: 'var(--color-white-100)' }} />
    case 6:
      return <audio key={idx} src={LowNoise} autoPlay />
    case 7:
      return <audio key={idx} src={HighNoise} autoPlay />
    case 8:
      return <div key={idx} className={style['left-square']} />
    case 9:
      return <div key={idx} className={style['right-square']} />
    default:
      return <></>
  }
}

const answerIndex = [Color.red, Color.green, Color.yellow, Color.blue, Color.white, Tone.low, Tone.high, Peddal.left, Peddal.right]

const generateData = (seconds: string, isPractise?: boolean): number[][] => {
  const getData = (amount: number, sets: number): number[][] => {
    const data = []
    for (let i = 0; i < sets; i++) {
      const rangeArr = range(0, amount - 1)
      data.push(rangeArr.map((n: number, j: number) => {
        const prevValue: number = hasValue(rangeArr[j - 1]) ? rangeArr[j - 1] : 0
        return n === 0 ? (getRandomInt(5, prevValue) + 1) : (getRandomInt(7, prevValue) + 1)
      }))
    }
    return data
  }

  if (hasValue(isPractise) && isPractise) {
    return getData(60, 1)
  } else {
    const secondsArrLength = seconds?.split(';').length
    return getData(500, secondsArrLength)
  }
}

export function DtgViewReaction (props: Props): JSX.Element {
  const [isLeftPedalF8] = useLocalStorage<string | null>('isLeftPedalF8', null)

  const { seconds } = useParams()
  const [currentPart, setCurrentPart] = React.useState<number>(0)
  const [shouldCheckPractise, setShouldCheckPractise] = React.useState<boolean>(false)
  const [pauseInSeconds, setPauseInSeconds] = React.useState<number>(-1)
  const data: any = props.variant === 'home' ? generateData(seconds ?? '500;500', props.isPractise) : hasValue(props.isPractise) ? [...testData2, ...testData1] : reactionData
  const origData = React.useMemo(() => {
    return [...data]
  }, [data])
  const dataToUse = data.flatMap((a: number) => a)

  const [correctOpen, setCorrectOpen] = React.useState(false)
  const [pauseOpen, setPauseOpen] = React.useState(false)
  const [currentVisibleAnswer, setCurrentVisibleAnswer] = React.useState<number>(-1)
  const [givenAnswers, setGivenAnswers] = React.useState<GivenAnswer[]>([])
  const modalCorrectBtnRef = React.useRef<HTMLButtonElement>(null)
  const [startTime, setStartTime] = React.useState<Date>(new Date())

  const screenWidth = 800 - 16
  const screenHeight = props.variant === 'home' ? 384 : 600 - 16
  const circleSize = 70
  const xPositions = React.useMemo(() => range(circleSize / 2, screenWidth - (circleSize / 2)).sort(randomOrder), [screenWidth])
  const yPositions = React.useMemo(() => range(circleSize / 2, screenHeight - (circleSize / 2)).sort(randomOrder), [screenHeight])

  const pauseMoments: number[] = React.useMemo(() => {
    const secondsArrLength = seconds?.split(';').length
    if (hasValue(props.isPractise) || secondsArrLength === 1) {
      return []
    }

    const arrLengths: number[] = []
    origData.reduce(
      (accumulator: number, currentValue: any[]) => {
        const add = accumulator + currentValue.length
        arrLengths.push(add)
        return add
      }, 0)

    return arrLengths
  }, [origData, props.isPractise])

  const { setDtgData } = useAppContext()
  const [shouldProceed, setShouldProceed] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (shouldProceed) {
      setDtgData((old: DtgDataEntry[][]) => {
        const newArr = [...old]
        newArr[1] = dataToUse.map((d: number, idx: number): DtgDataEntry | undefined => {
          const givenAnswer = givenAnswers.filter(g => g.itemNumber === idx)?.[0]
          const part = (pauseMoments.findIndex(n => idx < n) ?? 0) + 1
          const duration = returnReactionDuration()
          return hasValue(givenAnswer)
            ? {
                ...givenAnswer,
                part,
                variant: 'reaction-based',
                reactionDurationTime: duration
              }
            : undefined
        }).filter((a: DtgDataEntry | undefined) => hasValue(a))
          .sort((a: DtgDataEntry, b: DtgDataEntry) => a.itemNumber - b.itemNumber)

        return newArr
      })
      props.endFn()
      setShouldProceed(false)
    }
  }, [shouldProceed, dataToUse, givenAnswers, props, setDtgData])

  React.useEffect(() => {
    if (pauseInSeconds !== -1) {
      const timer = setTimeout(() => {
        setPauseInSeconds(old => old - 1)
      }, 1000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [pauseInSeconds])

  const returnReactionDuration = React.useCallback((): number => {
    const secondsArr = seconds?.split(';') ?? []
    let durationInMilliSeconds = 0
    if (hasValue(props.isPractise)) {
      durationInMilliSeconds = 60000
    } else if (secondsArr.length === 1) {
      durationInMilliSeconds = hasValue(seconds) ? parseInt(seconds, 10) * 1000 : 150000
    } else {
      durationInMilliSeconds = hasValue(seconds) ? parseInt(secondsArr[currentPart], 10) * 1000 : 150000
    }

    return durationInMilliSeconds
  }, [seconds, props.isPractise, currentPart])

  React.useEffect(() => {
    if (shouldCheckPractise) {
      givenAnswers.length < 5 || givenAnswers.filter(a => a.result === 'incorrect').length >= 5 ? handleReturnOpen() : handleCorrectOpen()
    }
  }, [givenAnswers, shouldCheckPractise])

  React.useEffect(() => {
    const durationInSeconds = returnReactionDuration()

    const timer = setTimeout(() => {
      if (hasValue(props.isPractise)) {
        setShouldCheckPractise(true)
      } else {
        if ((currentPart + 1) === origData.length) {
          setShouldProceed(true)
        } else {
          setPauseOpen(true)
          setPauseInSeconds(30)
          setTimeout(() => {
            setPauseOpen(false)
            setPauseInSeconds(-1)
            setCurrentPart(old => {
              setCurrentVisibleAnswer(origData[old].length)
              return (old + 1)
            })
            setStartTime(new Date())
          }, 30000)
          setCurrentVisibleAnswer(-1)
        }
      }
    }, durationInSeconds)
    return () => {
      clearTimeout(timer)
    }
  }, [currentPart])

  React.useEffect(() => {
    setTimeout(() => {
      setCurrentVisibleAnswer(0)
      setStartTime(new Date())
    }, 250)
  }, [])

  const giveAnswer = React.useCallback((answer: Color | Tone | Peddal): void => {
    const beginTime = startTime ?? new Date('01-01-1970')
    const endTime = new Date()
    const timeMilliSeconds = Math.abs(beginTime.getTime() - endTime?.getTime())

    setGivenAnswers((old: GivenAnswer[]): GivenAnswer[] => {
      let result = 'incorrect'
      const givenSignal = answerIndex.findIndex(i => i === answer) + 1
      const answerIndexToCheck = currentVisibleAnswer
      const askedSignal = dataToUse[answerIndexToCheck % dataToUse.length]

      if (askedSignal === givenSignal) {
        result = 'correct'
      }
      setCurrentVisibleAnswer(old => old + 1)
      setStartTime(new Date())

      if (old.length === 0) {
        result = 'first-entry'
      }

      return [
        ...old,
        {
          itemNumber: answerIndexToCheck,
          reactionTime: timeMilliSeconds,
          givenSignal,
          askedSignal,
          result: result as 'incorrect' | 'correct' | 'first-entry'
        }
      ]
    })
  }, [currentVisibleAnswer, dataToUse, startTime])

  const handleUserKeyPress = React.useCallback((event: KeyboardEvent): void => {
    if (event.repeat) { return }

    const possibleKeys = ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9']
    if (possibleKeys.includes(event.key)) {
      event.preventDefault()

      const keyMap = {
        F1: Color.white,
        F2: Color.blue,
        F3: Color.yellow,
        F4: Color.red,
        F5: Color.green,
        F6: Tone.low,
        F7: Tone.high,
        F8: isLeftPedalF8 === 'true' ? Peddal.left : Peddal.right,
        F9: isLeftPedalF8 === 'true' ? Peddal.right : Peddal.left
      }

      if (!correctOpen) {
        const { key } = event
        giveAnswer((keyMap as any)[key])
      }
    }
  }, [correctOpen, giveAnswer])

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  const handleCorrectOpen = (): void => {
    setCorrectOpen(true)
    setTimeout(() => {
      modalCorrectBtnRef.current?.focus()
    }, 100)
  }
  const handleCorrectClose = (): void => {
    setCorrectOpen(false)
    props.endFn()
  }

  const { setProgressStateDtg } = useAppContext()

  const modalReturnBtnRef = React.useRef<HTMLButtonElement>(null)
  const [returnOpen, setReturnOpen] = React.useState(false)
  const handleReturnOpen = (): void => {
    setReturnOpen(true)
    setTimeout(() => {
      modalReturnBtnRef.current?.focus()
    }, 100)
  }

  const handleReturnClose = (): void => {
    setReturnOpen(false)
    setProgressStateDtg(ProgressStateDtg.InstructionPart2)
  }

  return (
    <>
      <Modal
        disableEscapeKeyDown
        disableAutoFocus
        keepMounted
        open={returnOpen}
        aria-labelledby='modal-return-title'
        aria-describedby='modal-return-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-return-title' variant='h6' component='h2'>
                <Trans id='dtg.reaction.too_many_mistakes.title'>
                  Helaas!
                </Trans>
              </Typography>
              <Typography id='modal-return-description' sx={{ mt: 2 }}>
                <Trans id='dtg.reaction.too_many_mistakes.description'>
                  Je hebt of te weinig ingevoerd of te veel fouten gemaakt. Wellicht heb je de instructie niet helemaal begrepen, lees deze opnieuw.
                </Trans>
              </Typography>
            </div>
            <Button ref={modalReturnBtnRef} color='primary' variant='contained' onClick={() => handleReturnClose()}>Naar de instructie</Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        disableAutoFocus
        keepMounted
        open={correctOpen}
        onClose={handleCorrectClose}
        aria-labelledby='modal-correct-title'
        aria-describedby='modal-correct-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-correct-title' variant='h6' component='h2'>
                <Trans id='dtg.reaction.correct_practise.title'>
                  Heel goed!
                </Trans>
              </Typography>
              <Typography id='modal-correct-description' sx={{ mt: 2 }}>
                <Trans id='dtg.reaction.correct_practise.description'>
                  Je hebt de oefening voltooid. Druk op 'Doorgaan' om test deel 2 te maken.
                </Trans>
              </Typography>
            </div>
            <Button ref={modalCorrectBtnRef} color='primary' variant='contained' onClick={() => handleCorrectClose()}>Doorgaan/verder</Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        disableAutoFocus
        keepMounted
        open={pauseOpen}
        aria-labelledby='modal-pause-title'
        aria-describedby='modal-pause-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>

              <Typography id='modal-correct-title' variant='h6' component='h2'>
                <Trans id='dtg.reaction.break.title'>
                  Even pauze
                </Trans>
              </Typography>
              <Typography id='modal-correct-description' sx={{ mt: 2 }}>
                {getTranslatedText('dtg.reaction.breack.description', pauseInSeconds - 1)}
              </Typography>
            </div>
          </Stack>
        </Box>
      </Modal>

      {!(hasValue(process.env.NODE_ENV) && process.env.NODE_ENV === 'production') && (
        <div className='data'>
          <pre>{JSON.stringify({ startTime, currentVisibleAnswer, answer: returnAnswer(dataToUse[currentVisibleAnswer % dataToUse.length], currentVisibleAnswer), givenAnswers }, null, 2)}</pre>
        </div>
      )}

      <Stack direction='column' sx={{ height: '100%' }}>
        <Stack sx={{ flex: 1, padding: 2, background: '#B2B2B0', position: 'relative' }} justifyContent={hasValue(props.isPractise) ? 'flex-start' : 'space-between'}>
          <Box
            style={
              ![8, 9].includes(dataToUse[currentVisibleAnswer % dataToUse.length])
                ? { position: 'absolute', left: xPositions[currentVisibleAnswer % xPositions.length], top: yPositions[currentVisibleAnswer % yPositions.length] }
                : {
                    position: 'absolute',
                    left: dataToUse[currentVisibleAnswer % dataToUse.length] === 8 ? '16px' : 'unset',
                    right: dataToUse[currentVisibleAnswer % dataToUse.length] === 9 ? '16px' : 'unset',
                    bottom: '16px'
                  }
            }
          >
            {
              currentVisibleAnswer !== -1 &&
              !pauseOpen &&
              !returnOpen &&
              !correctOpen &&
              returnElement(dataToUse[currentVisibleAnswer % dataToUse.length], currentVisibleAnswer)
            }
          </Box>
        </Stack>

        {props.variant === 'home' && (
          <Stack sx={{ height: '200px', flex: '0 0 200px', p: '10px', background: '#B2B2B0' }} direction='column' spacing='20px'>
            <Stack sx={{ height: '80px', flex: '0 0 80px' }} direction='row' spacing={2}>
              <YipYipButtons.ButtonRed variant='contained' onClick={() => giveAnswer(Color.red)}><Trans id='general.color.red'>rood</Trans></YipYipButtons.ButtonRed>
              <YipYipButtons.ButtonYellow variant='contained' onClick={() => giveAnswer(Color.yellow)}><Trans id='general.color.yellow'>geel</Trans></YipYipButtons.ButtonYellow>
              <YipYipButtons.ButtonGreen variant='contained' onClick={() => giveAnswer(Color.green)}><Trans id='general.color.green'>groen</Trans></YipYipButtons.ButtonGreen>
              <YipYipButtons.ButtonWhite variant='contained' onClick={() => giveAnswer(Color.white)}><Trans id='general.color.white'>wit</Trans></YipYipButtons.ButtonWhite>
              <YipYipButtons.ButtonBlue variant='contained' onClick={() => giveAnswer(Color.blue)}><Trans id='general.color.blue'>blauw</Trans></YipYipButtons.ButtonBlue>
            </Stack>
            <Stack sx={{ height: '80px', flex: '0 0 80px' }} direction='row' spacing={2}>
              <YipYipButtons.ButtonBlack variant='contained' onClick={() => giveAnswer(Tone.low)}><Trans id='general.tone.low'>Lage tonen</Trans></YipYipButtons.ButtonBlack>
              <YipYipButtons.ButtonBlack variant='contained' onClick={() => giveAnswer(Tone.high)}><Trans id='general.tone.high'>Hoge tonen</Trans></YipYipButtons.ButtonBlack>
            </Stack>
          </Stack>
        )}
      </Stack>

    </>
  )
}
