import { t } from '@lingui/macro'
import { getTranslatedText } from '@root/utils/ui'

// For each new test, these should be extended.

/* Steps Gbd */
export enum ProgressStateGbd {
  Introduction = 'Introduction',
  Instruction = 'Instruction',
  Practise = 'Practise',
  Test = 'Test',
  End = 'End'
}
export const returnEnumIndexGbd = (val: ProgressStateGbd): number => Object.keys(ProgressStateGbd).indexOf(val)
export const stepLabelsGbd: string[] = [
  'gbd.progress.introduction',
  'gbd.progress.instruction',
  'gbd.progress.practise',
  'gbd.progress.test',
  'gbd.progress.final'
]

/* Gbd */

export const rowAmount = 10
export const columnAmount = 17

/* Steps Adm */
export enum ProgressStateAdm {
  Introduction = 'Introduction',
  InstructionPart1 = 'InstructionPart1',
  PractisePart1 = 'PractisePart1',
  TestPart1 = 'TestPart1',
  InstructionPart2 = 'InstructionPart2',
  PractisePart2 = 'PractisePart2',
  TestPart2 = 'TestPart2',
  End = 'End'
}
export const returnEnumIndexAdm = (val: ProgressStateAdm): number => Object.keys(ProgressStateAdm).indexOf(val)
export const stepLabelsAdm = [
  'adm.progress.introduction',
  'adm.progress.instruction.part1',
  'adm.progress.practise.part1',
  'adm.progress.test.part1',
  'adm.progress.instruction.part2',
  'adm.progress.practise.part2',
  'adm.progress.test.part2',
  'adm.progress.final'
]

/* Dtg */
export enum ProgressStateDtg {
  Introduction = 'Introduction',
  InstructionPart1Page1 = 'InstructionPart1Page1',
  InstructionPart1Page2 = 'InstructionPart1Page2',
  PractisePart1 = 'PractisePart1',
  TestPart1 = 'TestPart1',
  InstructionPart2 = 'InstructionPart2',
  // PractisePart2 = 'PractisePart2',
  TestPart2 = 'TestPart2',
  End = 'End'
}
export const returnEnumIndexDtg = (val: ProgressStateDtg): number => Object.keys(ProgressStateDtg).indexOf(val)
export const stepLabelsDtg = [
  'dtg.progress.introduction',
  'dtg.progress.instruction.part1',
  'dtg.progress.instruction.part1',
  'dtg.progress.practise.part1',
  'dtg.progress.test.part1',
  'dtg.progress.instruction.part2',
  // 'dtg.progress.practise.part2',
  'dtg.progress.test.part2',
  'dtg.progress.final'
]

/* Vda */
export enum ProgressStateVda {
  Introduction = 'Introduction',
  InstructionPart1 = 'InstructionPart1',
  InstructionPart2 = 'InstructionPart2',
  InstructionPart3 = 'InstructionPart3',
  InstructionPart4 = 'InstructionPart4',
  Practise1 = 'Practise1',
  Practise2 = 'Practise2',
  Practise3 = 'Practise3',
  Test = 'Test',
  End = 'End'
}
export const returnEnumIndexVda = (val: ProgressStateVda): number => Object.keys(ProgressStateVda).indexOf(val)
export const stepLabelsVda = [
  'vda.progress.introduction',
  'vda.progress.instruction.part1',
  'vda.progress.instruction.part2',
  'vda.progress.instruction.part3',
  'vda.progress.instruction.part4',
  'vda.progress.practise1',
  'vda.progress.practise2',
  'vda.progress.practise3',
  'vda.progress.test',
  'vda.progress.final'
]

interface StepperValues { labels: string[], index: number }
export const returnStepperValues = (variant: string, val: ProgressStateGbd | ProgressStateAdm | ProgressStateDtg | ProgressStateVda): StepperValues => {
  switch (variant) {
    case 'adm':
      return { labels: stepLabelsAdm.map(a => getTranslatedText(a)), index: returnEnumIndexAdm(val as ProgressStateAdm) }
    case 'gbd':
      return { labels: stepLabelsGbd.map(a => getTranslatedText(a)), index: returnEnumIndexGbd(val as ProgressStateGbd) }
    case 'dtg':
      return { labels: stepLabelsDtg.map(a => getTranslatedText(a)), index: returnEnumIndexDtg(val as ProgressStateDtg) }
    case 'vda':
      return { labels: stepLabelsVda.map(a => getTranslatedText(a)), index: returnEnumIndexVda(val as ProgressStateVda) }
    default:
      return { labels: [''], index: -1 }
  }
}

// This is being used by all modals.
export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

export interface ColorVariableStrings {
  red: string
  yellow: string
  blue: string
  white: string
  green: string
}

export const returnColorVariable: ColorVariableStrings = {
  red: 'var(--color-red-500)',
  yellow: 'var(--color-yellow-500)',
  blue: 'var(--color-blue-500)',
  white: 'var(--color-white-100)',
  green: 'var(--color-green-500)'
}

export const returnColorNicename: ColorVariableStrings = {
  red: t({
    id: 'general.color.red', message: 'rood'
  }),
  yellow: t({
    id: 'general.color.yellow', message: 'geel'
  }),
  blue: t({
    id: 'general.color.blue', message: 'blauw'
  }),
  white: t({
    id: 'general.color.white', message: 'wit'
  }),
  green: t({
    id: 'general.color.green', message: 'groen'
  })
}
