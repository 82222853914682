import { ProgressStateGbd, ProgressStateAdm, ProgressStateDtg, ProgressStateVda } from '@root/misc/constants'
import { GbdData, AdmData, DtgData, VdaData } from '@root/misc/types'
import React from 'react'

type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>

interface IContext {
  errorMsg: string | null
  setErrorMsg: StateSetter<string | null>
  pageTitle: string
  setPageTitle: StateSetter<string>
  progressStateGbd: ProgressStateGbd
  setProgressStateGbd: StateSetter<ProgressStateGbd>
  progressStateAdm: ProgressStateAdm
  setProgressStateAdm: StateSetter<ProgressStateAdm>
  progressStateDtg: ProgressStateDtg
  setProgressStateDtg: StateSetter<ProgressStateDtg>
  progressStateVda: ProgressStateVda
  setProgressStateVda: StateSetter<ProgressStateVda>
  gbdData: GbdData
  setGbdData: StateSetter<GbdData>
  admData: AdmData
  setAdmData: StateSetter<AdmData>
  dtgData: DtgData
  setDtgData: StateSetter<DtgData>
  vdaData: VdaData
  setVdaData: StateSetter<VdaData>
  callBackUrl: string | null
  setCallBackUrl: StateSetter<string | null>
}

// For each test the context should be extended
export function useAppValue (): IContext {
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null)
  const [pageTitle, setPageTitle] = React.useState<string>('')
  const [progressStateGbd, setProgressStateGbd] = React.useState<ProgressStateGbd>(ProgressStateGbd.Introduction)
  const [progressStateAdm, setProgressStateAdm] = React.useState<ProgressStateAdm>(ProgressStateAdm.Introduction)
  const [progressStateDtg, setProgressStateDtg] = React.useState<ProgressStateDtg>(ProgressStateDtg.Introduction)
  const [progressStateVda, setProgressStateVda] = React.useState<ProgressStateVda>(ProgressStateVda.Introduction)
  const [gbdData, setGbdData] = React.useState<GbdData>([])
  const [admData, setAdmData] = React.useState<AdmData>([])
  const [dtgData, setDtgData] = React.useState<DtgData>([])
  const [vdaData, setVdaData] = React.useState<VdaData>([])
  const [callBackUrl, setCallBackUrl] = React.useState<string | null>(null)

  React.useEffect(() => {
    document.title = pageTitle
  }, [pageTitle])

  return {
    errorMsg,
    setErrorMsg,
    pageTitle,
    setPageTitle,
    progressStateGbd,
    setProgressStateGbd,
    progressStateAdm,
    setProgressStateAdm,
    progressStateDtg,
    setProgressStateDtg,
    progressStateVda,
    setProgressStateVda,
    admData,
    setAdmData,
    gbdData,
    setGbdData,
    dtgData,
    setDtgData,
    vdaData,
    setVdaData,
    callBackUrl,
    setCallBackUrl
  }
}

export const appContext = React.createContext<IContext | null>(null)

export function useAppContext (): IContext {
  const c = React.useContext(appContext)
  if (c !== null) {
    return c
  }
  throw Error('No context available')
}
