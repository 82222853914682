import React from 'react'

interface Size {
  width: number | undefined
  height: number | undefined
  isTablet: boolean
  isDesktop: boolean
}

export default function useWindowSize (): Size {
  const [windowSize, setWindowSize] = React.useState<Size>({
    width: undefined,
    height: undefined,
    isTablet: false,
    isDesktop: false
  })

  React.useEffect(() => {
    function handleResize (): void {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isTablet: window.innerWidth >= 768,
        isDesktop: window.innerWidth >= 992
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}
