import React from 'react'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { dataPart1, dataPart2 } from './data'
import { hasValue, randomOrder, range } from '@root/misc/helpers'
import { useAppContext } from '@root/global/context'
import { AdmDataEntry, Color } from '@root/misc/types'
import YipYipButtons from '@misc/YipYipButtons'
import { ColorVariableStrings, modalStyle, returnColorVariable } from '@root/misc/constants'
import { Trans } from '@lingui/macro'
import { getTranslatedText } from '@root/utils/ui'

/*
  This is the actual ADM test (not practise). It works as follows:

  Either through generated data or provided data from Human Company two parts are being presented to the user.

  The first part shows 5 rows of 10 numbers. These numbers are all the numbers in the range of 10-59.
  The numbers seem randomly spread out, but there are some 'rules'. Number can't follow each other up on the same row.
  So, 10 could be on row 1, 11 can NOT be on row 1 and must be on a row between 2-5.

  Each row has a specific color.

  The user has to count from 10 up to 59, and should enter the correct color of the row where the current number is displayed.

  In part 2, each number will be accompanied by a second (smaller) number. The assignment is the same, except you need to enter the color of the smaller number.

  Each mistake will show a prompt, and each mistake will be saved as part of the data.
*/

interface Props {
  endFn: () => void // This is what needs to be triggered after finishing the assignment
  variant: string // "home" | "on-location"
  part: number // adm is a 2-part assignment
  pages?: number
}

const returnGeneratedData = (hasDistractions: boolean): any => {
  const colors = [Color.red, Color.yellow, Color.green, Color.white, Color.blue]
  const smallColors = [Color.white, Color.blue, Color.red, Color.green, Color.yellow]
  const colorsToUse = [Color.red, Color.yellow, Color.green, Color.white, Color.blue].sort(randomOrder)

  // these small numbers are always in the same order
  const smallNumbers = [
    [...range(10, 19)],
    [...range(20, 29)],
    [...range(30, 39)],
    [...range(40, 49)],
    [...range(50, 59)]
  ]

  const bigNumbers = Array.from(Array(50).keys()).map(k => k + 10)

  // This shuffles the colors until the first index is not the last color from the previous group of 5
  const shuffleColors = (arr: Color[], color: Color): Color[] => {
    arr.sort(randomOrder)

    if (arr[0] === color) {
      return shuffleColors(arr, color)
    }
    return arr
  }

  // here we map through them all and return an object with the color inside.
  const numbersAndColors = bigNumbers.map((bn: number, idx: number): { value: number, color: Color } => {
    if ((idx % 5) === 0) {
      shuffleColors(colorsToUse, colorsToUse[4])
    }

    const color: Color = colorsToUse[(idx % 5)]
    return { value: bn, color }
  })

  return {
    id: 'generated',
    orderNumberType: 'big',
    colorNumberType: hasDistractions ? 'small' : 'big',
    numberOfNumbersToFind: 30,
    rows: colors.map((color: Color, idx: number) => {
      return {
        bigColor: color,
        bigNumbers: numbersAndColors.filter(bn => bn.color === color).map(bn => bn.value).sort(randomOrder),
        smallColor: smallColors[idx],
        smallNumbers: smallNumbers[idx]
      }
    })
  }
}

export function AdmView (props: Props): JSX.Element {
  const { setAdmData } = useAppContext()

  // All states
  const [testData] = React.useState<any>(props.variant === 'on-location' ? [dataPart1, dataPart2] : [returnGeneratedData(false), returnGeneratedData(true)])
  const [shouldProceed, setShouldProceed] = React.useState<boolean>(false)
  const [currentAnswerToGive, setCurrentAnswerToGive] = React.useState<number>(10)
  const [currentColor, setCurrentColor] = React.useState<Color>(Color.red)
  const [startTime, setStartTime] = React.useState<Date>(new Date())
  const [cueOpen, setCueOpen] = React.useState<boolean>(false)
  const [givenAnswers, setGivenAnswers] = React.useState<AdmDataEntry[]>([])

  // All refs
  const modalCueBtnRef = React.useRef<HTMLButtonElement>(null)

  // Variables
  const dataToUse: any = testData[props.part - 1]

  // All useEffects
  // This one saves the data in the context and proceeds to the endFn()
  React.useEffect(() => {
    if (shouldProceed) {
      setAdmData((old: AdmDataEntry[][]) => {
        const newArr = [...old]
        const newGivenAnswers = [...givenAnswers].map(g => {
          if (g.isCorrect) {
            g.amountWrongAnswers = givenAnswers.filter(a => a.itemNumber === g.itemNumber && !a.isCorrect).length
          }
          return g
        }).filter(g => g.isCorrect)
        newArr[props.part - 1] = newGivenAnswers
        return newArr
      })
      props.endFn()
      setShouldProceed(false)
    }
  }, [shouldProceed, givenAnswers, props, setAdmData])

  // TODO: Remove when going to production
  // This one set the CurrentColor to give
  React.useEffect(() => {
    const color = dataToUse.rows.find((row: any) => {
      return row.bigNumbers.includes(currentAnswerToGive)
    })
    if (hasValue(color)) {
      setCurrentColor(props.part === 1 ? color.bigColor : color.smallColor)
    }
  }, [currentAnswerToGive, dataToUse.rows, props.part])
  // End TODO

  // This detects reaching the end of the assignment
  React.useEffect(() => {
    if (currentAnswerToGive === 10 + (dataToUse.numberOfNumbersToFind as number)) {
      setShouldProceed(true)
    }
  }, [currentAnswerToGive, dataToUse.numberOfNumbersToFind])

  // Saving the answer whether its correct or not
  const giveAnswer = React.useCallback((color: Color): void => {
    if (color === currentColor) {
      setGivenAnswers((old: AdmDataEntry[]): AdmDataEntry[] => {
        return [
          ...old,
          {
            beginTime: startTime,
            endTime: new Date(),
            searchDuration: Math.abs(startTime.getTime() - new Date().getTime()) / 1000,
            itemNumber: currentAnswerToGive,
            isCorrect: true,
            askedColor: currentColor,
            pressedColor: color
          }
        ]
      })

      setCurrentAnswerToGive(old => old + 1)
      setStartTime(new Date())
    } else {
      const amountIncorrect = givenAnswers.filter(a => !a.isCorrect).length
      amountIncorrect !== 2 && handleCueOpen()
      setGivenAnswers((old: AdmDataEntry[]): AdmDataEntry[] => {
        return [
          ...old,
          {
            beginTime: startTime,
            endTime: new Date(),
            searchDuration: Math.abs(startTime.getTime() - new Date().getTime()) / 1000,
            itemNumber: currentAnswerToGive,
            isCorrect: false,
            askedColor: currentColor,
            pressedColor: color
          }
        ]
      })
    }
  }, [currentAnswerToGive, currentColor, givenAnswers, startTime])

  // This handles the keypress. It prohibits repeats, and preventdefaults the keys that are being used.
  const handleUserKeyPress = React.useCallback((event: KeyboardEvent): void => {
    if (event.repeat) { return }
    if (event.key === 'f') {
      event.preventDefault()
    }
    const possibleKeys = ['F1', 'F2', 'F3', 'F4', 'F5']
    if (possibleKeys.includes(event.key)) {
      event.preventDefault()

      const keyMap = { F1: Color.white, F2: Color.blue, F3: Color.yellow, F4: Color.red, F5: Color.green }

      const { key } = event
      giveAnswer((keyMap as any)[key])
    }
  }, [giveAnswer])

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  // This handles the modal opening and focussing on the button
  const handleCueOpen = (): void => {
    setCueOpen(true)
    setTimeout(() => {
      modalCueBtnRef.current?.focus()
    }, 100)
  }
  const handleCueClose = (): void => {
    setStartTime(new Date())
    setCueOpen(false)
  }

  return (
    <>
      <Modal
        disableAutoFocus
        keepMounted
        open={cueOpen}
        onClose={handleCueClose}
        aria-labelledby='modal-cue-title'
        aria-describedby='modal-cue-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-cue-title' variant='h6' component='h2'>
                <Trans id='adm.incorrect_practise.title'>
                  Helaas!
                </Trans>
              </Typography>
              <Typography id='modal-cue-description' sx={{ mt: 2 }}>
                {getTranslatedText('adm.incorrect_practise.description', currentAnswerToGive)}
              </Typography>
            </div>
            <Button ref={modalCueBtnRef} color='primary' variant='contained' onClick={() => handleCueClose()}>
              <Trans id='adm.incorrect_practise.button'>
                Doorgaan
              </Trans>
            </Button>
          </Stack>
        </Box>
      </Modal>

      {!(hasValue(process.env.NODE_ENV) && process.env.NODE_ENV === 'production') && (
        <div className='data'>
          <pre>{JSON.stringify({ currentColor, currentAnswerToGive, givenAnswers }, null, 2)}</pre>
        </div>
      )}

      <Stack direction='column' sx={{ height: '100%' }}>
        <Stack sx={{ flex: 1, padding: 2, background: 'var(--color-grey-500)' }} justifyContent='space-between'>
          {
            dataToUse.rows.map((row: any) => {
              return (
                <Box key={row.bigColor} sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
                  {row.bigNumbers.map((val: number, idx: number) => {
                    if (cueOpen) return null

                    return (

                      <Box key={val} sx={{ color: returnColorVariable[row.bigColor as keyof ColorVariableStrings], textAlign: 'center', display: 'grid', placeItems: 'center' }}>
                        <Typography variant='h4' sx={{ userSelect: 'none' }}>
                          {val}
                        </Typography>
                        {props.part === 2 && row.smallNumbers !== undefined && (
                          <Typography variant='body1' sx={{ userSelect: 'none', color: returnColorVariable[row.smallColor as keyof ColorVariableStrings] }}>
                            {row.smallNumbers[idx]}
                          </Typography>
                        )}
                      </Box>
                    )
                  })}
                </Box>
              )
            })
          }
        </Stack>

        <Box
          sx={{ height: props.variant === 'home' ? '20px' : '30px', background: 'var(--color-grey-500)', pb: props.variant === 'home' ? 0 : '10px' }}
        >
          <Box
            sx={{ margin: '0 10px', padding: '2px', border: '1px solid var(--color-white-100)', height: '100%' }}
          >
            <Box
              sx={{ background: 'white', width: `calc((${currentAnswerToGive - 10} / ${dataToUse.numberOfNumbersToFind as number}) * 100%)`, height: '100%' }}
            />

          </Box>

        </Box>

        {props.variant === 'home' && (
          <Stack sx={{ height: '100px', flex: '0 0 100px', p: '10px', background: 'var(--color-grey-500)' }} direction='row' spacing={2}>
            <YipYipButtons.ButtonRed variant='contained' onClick={() => giveAnswer(Color.red)}><Trans id='general.color.red'>rood</Trans></YipYipButtons.ButtonRed>
            <YipYipButtons.ButtonYellow variant='contained' onClick={() => giveAnswer(Color.yellow)}><Trans id='general.color.yellow'>geel</Trans></YipYipButtons.ButtonYellow>
            <YipYipButtons.ButtonGreen variant='contained' onClick={() => giveAnswer(Color.green)}><Trans id='general.color.green'>groen</Trans></YipYipButtons.ButtonGreen>
            <YipYipButtons.ButtonWhite variant='contained' onClick={() => giveAnswer(Color.white)}><Trans id='general.color.white'>wit</Trans></YipYipButtons.ButtonWhite>
            <YipYipButtons.ButtonBlue variant='contained' onClick={() => giveAnswer(Color.blue)}><Trans id='general.color.blue'>blauw</Trans></YipYipButtons.ButtonBlue>
          </Stack>
        )}
      </Stack>

    </>
  )
}
