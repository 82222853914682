import { Trans, t } from '@lingui/macro'
import { Stack } from '@mui/material'
import EndScreen from '@root/components/molecules/EndScreen/Component'
import Introduction from '@root/components/molecules/Introduction/Component'
import { DtgView } from '@root/components/organisms/DtgView/Component'
import { DtgViewReaction } from '@root/components/organisms/DtgViewReaction/Component'
import viewstyle from '@components/organisms/DtgView/style.module.css'

import { useAppContext } from '@root/global/context'
import { ProgressStateDtg } from '@root/misc/constants'
import { checkTitle, hasValue } from '@root/misc/helpers'
import { Tone } from '@root/misc/types'
import YipYipButtons from '@root/misc/YipYipButtons'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import LowNoise from '@audio/low_short.mp3'
import HighNoise from '@audio/high_short.mp3'
import { useLocalStorage } from 'usehooks-ts'
import clsx from 'clsx'

const possibleKeys = ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9']

const DtgInstructions = (): JSX.Element => {
  const [pressedKey, setPressedKey] = React.useState<string | null>()
  const [isLeftPedalF8] = useLocalStorage<string | null>('isLeftPedalF8', null)
  const [currentTone, setCurrentTone] = React.useState<Tone | null>(null)
  const { setProgressStateDtg } = useAppContext()
  const { variant } = useParams()
  const location = useLocation()

  const playAudio = (tone: Tone): void => {
    setCurrentTone(old => {
      return old === tone ? null : tone
    })
  }

  const returnTone = (): string => {
    if (currentTone === Tone.high) {
      return HighNoise
    }
    return LowNoise
  }

  React.useEffect(() => {
    if (pressedKey === 'f6') {
      playAudio(Tone.low)
    }
    if (pressedKey === 'f7') {
      playAudio(Tone.high)
    }
  }, [pressedKey])

  const handleUserKeyPress = React.useCallback((event: KeyboardEvent): void => {
    if (event.repeat) { return }

    if (possibleKeys.includes(event.key)) {
      event.preventDefault()

      const { key } = event
      const lowerKey = key.toLowerCase()
      setPressedKey(lowerKey)
    }
  }, [isLeftPedalF8])

  const handleUserKeyRelease = React.useCallback((event: KeyboardEvent): void => {
    if (event.repeat) { return }

    if (possibleKeys.includes(event.key)) {
      event.preventDefault()
      const { key } = event
      const lowerKey = key.toLowerCase()
      setPressedKey(old => lowerKey === old ? null : old)
    }
  }, [isLeftPedalF8])

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    window.addEventListener('keyup', handleUserKeyRelease)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
      window.removeEventListener('keyup', handleUserKeyRelease)
    }
  }, [handleUserKeyPress, handleUserKeyRelease])

  return (
    <Introduction
      key='dtg3'
      nextFn={() => setProgressStateDtg(ProgressStateDtg.PractisePart1)}
      title={checkTitle(location.pathname)}
      label={t({ id: 'dtg.instruction.title.page2', message: 'Ga naar de oefentoets' })}
      part={2}
      description={(
        <>
          <p>
            <Trans id='dtg.instruction.page2.onlocation'>
              De test bestaat uit twee delen.<br /><br />
              In het eerste deel verschijnen de signalen met een vast tempo. Tijdens de test wordt dit tempo twee
              keer verhoogd. Probeer steeds zo goed mogelijk te reageren, ook wanneer je een aantal signalen
              mist.
            </Trans>
          </p>
          <p>
            <b>
              <Trans id='dtg.test.audio'>
                Probeer hier onder de tonen nog even uit.
              </Trans>
            </b>
          </p>

          {
            hasValue(currentTone) && (<audio src={returnTone()} loop autoPlay onEnded={() => setCurrentTone(null)} />)
          }

          <Stack sx={{ height: '80px', flex: '0 0 80px' }} direction='row' spacing={2}>
            <YipYipButtons.ButtonBlack variant='contained' onClick={() => playAudio(Tone.low)}>{hasValue(currentTone) && currentTone === Tone.low ? (<Trans id='general.tone.stop'>Stop audio</Trans>) : (<Trans id='general.tone.low'>Lage tonen</Trans>)}</YipYipButtons.ButtonBlack>
            <YipYipButtons.ButtonBlack variant='contained' onClick={() => playAudio(Tone.high)}>{hasValue(currentTone) && currentTone === Tone.high ? (<Trans id='general.tone.stop'>Stop audio</Trans>) : (<Trans id='general.tone.high'>Hoge tonen</Trans>)}</YipYipButtons.ButtonBlack>
          </Stack>

          {variant === 'on-location' && (
            <>
              <p>
                <b>
                  <Trans id='dtg.test.peddals'>
                    Deze elementen geven aan dat er een pedaal moet worden ingedrukt.
                  </Trans>
                </b>
              </p>

              <Stack sx={{ height: '80px', flex: '0 0 80px' }} direction='row' justifyContent='space-between' spacing={2}>
                <div className={
                  clsx(
                    viewstyle['left-square'],
                    hasValue(isLeftPedalF8) && isLeftPedalF8 === 'true' && pressedKey === 'f8' && viewstyle['left-square-active'],
                    hasValue(isLeftPedalF8) && isLeftPedalF8 === 'false' && pressedKey === 'f9' && viewstyle['left-square-active']
                  )
                }
                >
                  L
                </div>
                <div className={
                  clsx(
                    viewstyle['right-square'],
                    hasValue(isLeftPedalF8) && isLeftPedalF8 === 'true' && pressedKey === 'f9' && viewstyle['right-square-active'],
                    hasValue(isLeftPedalF8) && isLeftPedalF8 === 'false' && pressedKey === 'f8' && viewstyle['right-square-active']
                  )
                }
                >
                  R
                </div>

              </Stack>
            </>
          )}

        </>
      )}
    />
  )
}

// For each different step in a test, this file renders the HTML for that specific step.
export function Dtg (): JSX.Element {
  const { progressStateDtg, setProgressStateDtg, dtgData } = useAppContext()
  const location = useLocation()
  const { variant } = useParams()

  if (progressStateDtg === ProgressStateDtg.Introduction) {
    return (
      <Introduction
        key='dtg1'
        nextFn={() => setProgressStateDtg(ProgressStateDtg.InstructionPart1Page1)}
        title={checkTitle(location.pathname)}
        label={t({ id: 'dtg.introduction.title', message: 'Lees de instructie' })}
        description={(
          <>
            <p>
              <Trans id='dtg.introduction'>
                Bij deze test gaat het om het reactievermogen; het vermogen om zonder aarzelen adequaat te
                reageren op bepaalde signalen. <br /><br />
                Lees de instructie goed door en start de test pas als u deze volledig hebt begrepen.
              </Trans>
            </p>
          </>
        )}
      />
    )
  }

  if (progressStateDtg === ProgressStateDtg.InstructionPart1Page1) {
    return (
      <Introduction
        key='dtg2'
        nextFn={() => setProgressStateDtg(ProgressStateDtg.InstructionPart1Page2)}
        title={checkTitle(location.pathname)}
        label={t({ id: 'dtg.instruction.title.page1', message: 'Volgende pagina' })}
        part={1}
        description={(
          <>
            <p>
              {variant === 'on-location' && (
                <Trans id='dtg.instruction.page1.onlocation'>
                  Je gebruikt bij deze test het toetsenbord, een koptelefoon en de pedalen. <br />
                  Je moet straks zo snel en goed mogelijk gaan reageren op signalen die je krijgt. Dit zijn licht- en
                  geluidsignalen. Je reageert op de verschillende signalen op de volgende manier: <br />
                  <ul style={{ margin: '16px' }}>
                    <li>Lichtsignalen : toets met dezelfde kleur.</li>
                    <li>Geluidsignalen : de zwarte toetsen;
                      <ul>
                        <li>Laag is links (L),</li>
                        <li>Hoog is rechts (H).</li>
                      </ul>
                    </li>
                    <li>Blokjes : linker en rechter pedaal.</li>
                  </ul>

                  Als er een gekleurd bolletje op het scherm verschijnt, druk je op de juiste kleurtoets (wit, blauw, geel,
                  rood en groen).<br />
                  Als er een toon klinkt druk je op de juiste zwarte toets: (L) bij een lage toon en (H) bij een hoge toon.<br />
                  Onder aan het beeld staan links en rechts een blokje. Als een blokje oplicht druk je op een van de
                  pedalen:<br />
                  <ul style={{ margin: '16px' }}>
                    <li>als het linker blokje oplicht, druk je op het linker pedaal,</li>
                    <li>als het rechter blokje oplicht, druk je op het rechterpedaal.</li>
                  </ul>
                </Trans>
              )}
              {variant !== 'on-location' && (
                <Trans id='dtg.instruction.page1.home'>
                  Je gebruikt bij deze test de muis en een koptelefoon. <br />
                  Je moet straks zo snel en goed mogelijk gaan reageren op signalen die je krijgt. Dit zijn licht- en
                  geluidsignalen. Je reageert op de verschillende signalen op de volgende manier: <br />
                  <ul style={{ margin: '16px' }}>
                    <li>Lichtsignalen : toets met dezelfde kleur.</li>
                    <li>Geluidsignalen : de zwarte toetsen;
                      <ul>
                        <li>Laag is links (L),</li>
                        <li>Hoog is rechts (H).</li>
                      </ul>
                    </li>
                  </ul>

                  Als er een gekleurd bolletje op het scherm verschijnt, druk je op de juiste kleurtoets (wit, blauw, geel,
                  rood en groen).<br />
                  Als er een toon klinkt druk je op de juiste zwarte toets: (L) bij een lage toon en (H) bij een hoge toon.<br />
                </Trans>
              )}

            </p>
          </>
        )}
      />
    )
  }

  if (progressStateDtg === ProgressStateDtg.InstructionPart1Page2) {
    return (
      <DtgInstructions />
    )
  }

  if (progressStateDtg === ProgressStateDtg.InstructionPart2) {
    return (
      <Introduction
        nextFn={() => setProgressStateDtg(ProgressStateDtg.TestPart2)}
        title={checkTitle(location.pathname)}
        label={t({ id: 'dtg.instruction.part2.title.page1', message: 'Volgende pagina' })}
        part={1}
        description={(
          <>
            <p>
              <Trans id='dtg.instruction.part2'>
                Nu volgt nog een tweede testdeel. Je krijgt nu pas een volgend signaal als je een reactie gegeven
                heeft. Je bepaalt dus je eigen tempo. <br /><br />
                Probeer zo snel en zo goed mogelijk te reageren.
              </Trans>
            </p>
          </>
        )}
      />
    )
  }

  if (progressStateDtg === ProgressStateDtg.PractisePart1) {
    return (
      <DtgView key='practise' isPractise variant={variant ?? 'on-location'} endFn={() => setProgressStateDtg(ProgressStateDtg.TestPart1)} />
    )
  }

  // if (progressStateDtg === ProgressStateDtg.PractisePart2) {
  //   return (
  //     <DtgViewReaction key='practise2' isPractise variant={variant ?? 'on-location'} endFn={() => setProgressStateDtg(ProgressStateDtg.TestPart2)} />
  //   )
  // }

  if (progressStateDtg === ProgressStateDtg.End) {
    return (
      <EndScreen type='dtg' data={dtgData} variant={variant ?? ''} />
    )
  }

  if (progressStateDtg === ProgressStateDtg.TestPart1) {
    return (
      <DtgView key='test' variant={variant ?? 'on-location'} endFn={() => setProgressStateDtg(ProgressStateDtg.InstructionPart2)} />
    )
  }

  return (
    <DtgViewReaction key='test2' variant={variant ?? 'on-location'} endFn={() => setProgressStateDtg(ProgressStateDtg.End)} />
  )
}
