import { KeyboardPreview } from '@root/components/organisms/KeyboardPreview/Component'
import { KeyboardCalibration } from '@root/components/organisms/KeyboardCalibration/Component'
import { hasValue } from '@root/misc/helpers'
import { useLocalStorage } from 'usehooks-ts'

// For each different step in a test, this file renders the HTML for that specific step.
// In this case, the page only contains 1 step.

export function IntroductionPage (): JSX.Element {
  const [isLeftPedalF8, setIsLeftPedalF8] = useLocalStorage<string | null>('isLeftPedalF8', null)
  return (
    <>
      {!hasValue(isLeftPedalF8) && (
        <KeyboardCalibration localStorageFn={setIsLeftPedalF8} />
      )}
      {hasValue(isLeftPedalF8) && (
        <KeyboardPreview />
      )}
    </>
  )
}
