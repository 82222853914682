import React from 'react'
import { Trans } from '@lingui/macro'
import { Modal, Box, Stack, Typography, Button, Alert } from '@mui/material'
import { modalStyle } from '@root/misc/constants'
import { hasValue } from '@root/misc/helpers'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '@root/global/context'

const possibleKeys = ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9']

interface Props {
  localStorageFn: React.Dispatch<React.SetStateAction<string | null>>
}

export function KeyboardCalibration (props: Props): JSX.Element {
  const [pressedKey, setPressedKey] = React.useState<string | null>()
  const [leftPedalF8, setLeftPedalF8] = React.useState<string | null>(null)
  const [cueOpen, setCueOpen] = React.useState<boolean>(false)
  const modalCueBtnRef = React.useRef<HTMLButtonElement>(null)
  const navigate = useNavigate()
  const { callBackUrl } = useAppContext()

  const handleUserKeyPress = React.useCallback((event: KeyboardEvent): void => {
    if (event.repeat) { return }

    if (possibleKeys.includes(event.key)) {
      event.preventDefault()

      const { key } = event
      const lowerKey = key.toLowerCase()
      if (hasValue(leftPedalF8)) {
        setPressedKey(lowerKey)
      } else {
        setLeftPedalF8(lowerKey)
      }
    }
  }, [leftPedalF8])

  const handleUserKeyRelease = React.useCallback((event: KeyboardEvent): void => {
    if (event.repeat) { return }

    if (possibleKeys.includes(event.key)) {
      event.preventDefault()
      const { key } = event
      const lowerKey = key.toLowerCase()
      if (hasValue(leftPedalF8)) {
        setPressedKey(old => lowerKey === old ? null : old)
      }
    }
  }, [leftPedalF8])

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    window.addEventListener('keyup', handleUserKeyRelease)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
      window.removeEventListener('keyup', handleUserKeyRelease)
    }
  }, [handleUserKeyPress, handleUserKeyRelease])

  React.useEffect(() => {
    handleCueOpen()
  }, [])

  // This handles the modal opening and focussing on the button
  const handleCueOpen = (): void => {
    setCueOpen(true)
    setTimeout(() => {
      modalCueBtnRef.current?.focus()
    }, 100)
  }
  const handleCueClose = (): void => {
    setCueOpen(false)
  }

  const endFn = (): void => {
    props.localStorageFn(leftPedalF8 === 'f8' ? 'true' : 'false')
    hasValue(callBackUrl) && navigate(callBackUrl ?? '')
  }

  const leftPedal = leftPedalF8 === 'f8' ? 'f8' : 'f9'
  const rightPedal = leftPedalF8 === 'f8' ? 'f9' : 'f8'

  return (
    <>
      <Modal
        disableAutoFocus
        keepMounted
        open={cueOpen}
        onClose={handleCueClose}
        aria-labelledby='modal-cue-title'
        aria-describedby='modal-cue-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-cue-title' variant='h6' component='h2'>
                <Trans id='calibration.modal.title'>
                  Calibratie van toetsenbord
                </Trans>
              </Typography>
              <Typography id='modal-cue-description' sx={{ mt: 2 }}>
                <Trans id='calibration.modal.description'>
                  Het speciale toetsenbord moet nog even ingesteld worden. Volg de instructies op het scherm a.u.b.
                </Trans>
              </Typography>
            </div>
            <Button ref={modalCueBtnRef} color='primary' variant='contained' onClick={() => handleCueClose()}>
              <Trans id='calibration.modal.button'>
                Doorgaan
              </Trans>
            </Button>
          </Stack>
        </Box>
      </Modal>
      <div>
        <div style={{ paddingInline: '40px', paddingTop: '40px' }}>
          <Typography id='modal-cue-title' variant='h5' component='h2'>
            <Trans id='calibration.part1.title'>
              Calibratie van toetsenbord
            </Trans>
          </Typography>
          <Typography id='modal-cue-description' sx={{ mt: 2 }}>
            {!hasValue(leftPedalF8) && (
              <Alert severity='info'>
                <Trans id='calibration.part1.description'>
                  Gebruik nu het linker pedaal.
                </Trans>
              </Alert>
            )}

            {hasValue(leftPedalF8) && (
              <Trans id='calibration.part2.description'>
                Nu kunt u controleren of de pedalen goed zijn afgesteld. Als dit zo is, kunt u drukken op 'Calibratie afsluiten'.
              </Trans>
            )}

          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '40px' }}>
          <svg width='85' height='191' viewBox='0 0 85 191' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.5' y='0.5' width='84' height='190' rx='4.5' fill={pressedKey === leftPedal ? '#A8A8A8' : '#D8D8D8'} />
            <rect x='11' y='11' width='65' height='18' fill={pressedKey === leftPedal ? '#747474' : '#B9B9B9'} />
            <rect x='11' y='39' width='65' height='18' fill={pressedKey === leftPedal ? '#747474' : '#B9B9B9'} />
            <rect x='11' y='67' width='65' height='18' fill={pressedKey === leftPedal ? '#747474' : '#B9B9B9'} />
            <rect x='0.5' y='0.5' width='84' height='190' rx='4.5' stroke={pressedKey === leftPedal ? 'white' : '#818181'} />
          </svg>

          {!hasValue(leftPedalF8) && (
            <Box sx={{ display: 'grid', placeItems: 'center', backgroundColor: 'grey.100', height: '96px', width: '96px', borderRadius: '50%' }}>
              <Typography variant='h2' component='h2' sx={{ transform: 'translateY(-5px)' }}>
                ←
              </Typography>
            </Box>
          )}

          <svg width='85' height='191' viewBox='0 0 85 191' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.5' y='0.5' width='84' height='190' rx='4.5' fill={pressedKey === rightPedal ? '#A8A8A8' : '#D8D8D8'} />
            <rect x='11' y='11' width='65' height='18' fill={pressedKey === rightPedal ? '#747474' : '#B9B9B9'} />
            <rect x='11' y='39' width='65' height='18' fill={pressedKey === rightPedal ? '#747474' : '#B9B9B9'} />
            <rect x='11' y='67' width='65' height='18' fill={pressedKey === rightPedal ? '#747474' : '#B9B9B9'} />
            <rect x='0.5' y='0.5' width='84' height='190' rx='4.5' stroke={pressedKey === rightPedal ? 'white' : '#818181'} />
          </svg>
        </div>

        <Box sx={{ width: '100%', p: 2, display: 'grid', placeItems: 'center' }}>
          {hasValue(leftPedalF8) && (
            <>
              <Button color='primary' variant='text' onClick={() => setLeftPedalF8(null)} sx={{ marginBottom: '24px' }}>
                <Trans id='calibration.modal.resetbutton'>
                  Opnieuw calibreren
                </Trans>
              </Button>
              <Button color='primary' variant='contained' onClick={() => endFn()}>
                <Trans id='calibration.modal.closebutton'>
                  Calibratie afsluiten
                </Trans>
              </Button>
            </>
          )}
        </Box>
      </div>
    </>

  )
}
