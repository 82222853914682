export const variants = [
  {
    amount: 3,
    variants: [
      {
        id: 1,
        shape: 'boomerang',
        rotation: 0
      },
      {
        id: 2,
        shape: 'boomerang',
        rotation: 90
      },
      {
        id: 3,
        shape: 'boomerang',
        rotation: 135
      },
      {
        id: 4,
        shape: 'line',
        rotation: 0
      },
      {
        id: 5,
        shape: 'line',
        rotation: 90
      },
      {
        id: 6,
        shape: 'line',
        rotation: 45
      },
      {
        id: 7,
        shape: 'line',
        rotation: 135
      },
      {
        id: 8,
        shape: 'pyramid',
        rotation: 0
      },
      {
        id: 9,
        shape: 'pyramid',
        rotation: 90
      },
      {
        id: 10,
        shape: 'pyramid',
        rotation: 180
      },
      {
        id: 11,
        shape: 'pyramid',
        rotation: 110
      },
      {
        id: 12,
        shape: 'pyramid',
        rotation: 160
      },
      {
        id: 13,
        shape: 'corner',
        rotation: 0
      },
      {
        id: 14,
        shape: 'corner',
        rotation: 90
      },
      {
        id: 15,
        shape: 'corner',
        rotation: 205
      }
    ]
  },
  {
    amount: 4,
    variants: [
      {
        id: 0,
        shape: 'square',
        rotation: 0
      },
      {
        id: 1,
        shape: 'square',
        rotation: 45
      },
      {
        id: 2,
        shape: 'square',
        rotation: 15
      },
      {
        id: 3,
        shape: 'square',
        rotation: 32
      },
      {
        id: 4,
        shape: 'smallSquare',
        rotation: 0
      },
      {
        id: 5,
        shape: 'smallSquare',
        rotation: 22
      },
      {
        id: 6,
        shape: 'smallSquare',
        rotation: 29
      },
      {
        id: 7,
        shape: 'kite',
        rotation: 0
      },
      {
        id: 8,
        shape: 'kite',
        rotation: 45
      },
      {
        id: 9,
        shape: 'kite',
        rotation: 90
      },
      {
        id: 10,
        shape: 'kite',
        rotation: 180
      },
      {
        id: 11,
        shape: 'diamond',
        rotation: 0
      },
      {
        id: 12,
        shape: 'diamond',
        rotation: 90
      },
      {
        id: 13,
        shape: 'diamond',
        rotation: 45
      },
      {
        id: 14,
        shape: 'diamond',
        rotation: 24
      }
    ]
  },
  {
    amount: 5,
    variants: [
      {
        id: 0,
        shape: 'dice5',
        rotation: 0
      },
      {
        id: 1,
        shape: 'dice5',
        rotation: 90
      },
      {
        id: 2,
        shape: 'dice5',
        rotation: 135
      },
      {
        id: 3,
        shape: 'narrowHouse',
        rotation: 0
      },
      {
        id: 4,
        shape: 'narrowHouse',
        rotation: 90
      },
      {
        id: 5,
        shape: 'narrowHouse',
        rotation: 45
      },
      {
        id: 6,
        shape: 'narrowHouse',
        rotation: 135
      },
      {
        id: 7,
        shape: 'circle',
        rotation: 0
      },
      {
        id: 8,
        shape: 'circle',
        rotation: 90
      },
      {
        id: 9,
        shape: 'circle',
        rotation: 180
      },
      {
        id: 10,
        shape: 'circle',
        rotation: 110
      },
      {
        id: 11,
        shape: 'circle',
        rotation: 160
      },
      {
        id: 12,
        shape: 'house',
        rotation: 0
      },
      {
        id: 13,
        shape: 'house',
        rotation: 90
      },
      {
        id: 14,
        shape: 'house',
        rotation: 205
      }
    ]
  }
]
