import { t } from '@lingui/macro'

export function hasValue<T> (v: T | undefined | null): v is T {
  return (v !== null && v !== undefined)
}

export const checkTitle = (value: string): string => {
  switch (true) {
    case value.startsWith('/gbd'):
      return t({ id: 'gbd.page.title', message: 'Concentratievermogen (GBD)' })
    case value.startsWith('/adm'):
      return t({ id: 'adm.page.title', message: 'Selectieve aandacht (ADM)' })
    case value.startsWith('/dtg'):
      return t({ id: 'dtg.page.title', message: 'Reactievermogen (DTG)' })
    case value.startsWith('/vda'):
      return t({ id: 'vda.page.title', message: 'Visuele Differentiële Aandachtstest (VDA)' })
    case value.startsWith('/introduction'):
      return t({ id: 'introduction.page.title', message: 'Introductie' })
    default:
      return t({ id: 'index.page.title', message: 'Dit zou je niet moeten zien.' })
  }
}

export const randomOrder = (): number => (Math.random() >= 0.5) ? 1 : -1

export const range = (start: number, end: number): number[] => {
  return Array.from({ length: end - start + 1 }, (_, i) => i).map(n => n + start)
}

export function splitToNChunks (array: any, n: any): any {
  const result: any = []
  for (let i = n; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)))
  }
  return result
}
