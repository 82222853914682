
export type GbdData = GbdDataRow[]
export interface GbdDataRow {
  pageNumber: number
  rowNumber: number
  duration: number
  amountCorrected: number
  amountIncorrect: number
  amountOmitted: number
}

export type AdmData = AdmDataEntry[][]
export interface AdmDataEntry {
  itemNumber: number
  searchDuration: number
  pressedColor: Color
  askedColor: Color
  isCorrect: boolean
  beginTime: Date | null
  endTime: Date | null
  amountWrongAnswers?: number
}

export type DtgData = DtgDataEntry[][]
export interface DtgDataEntry {
  itemNumber: number
  reactionTime: number
  givenSignal: number
  askedSignal: number
  result: 'incorrect' | 'correct' | 'late' | 'omitted' | 'first-entry'
  actualSignalTime?: number
  setSignalTime?: number
  reactionDurationTime?: number
  part: number
  variant: 'time-based' | 'reaction-based'
}

export enum Color {
  red = 'red',
  green = 'green',
  blue = 'blue',
  white = 'white',
  yellow = 'yellow'
}

export enum Tone {
  low = 'low',
  high = 'high'
}

export enum Peddal {
  left = 'left',
  right = 'right'
}

export type VdaInput = VdaInputField[]
export interface VdaInputField {
  itemNumber: number
  data: {
    field: any[]
    serie: any[]
  }
}

export interface VdaTimes {
  combinedTime: number
  section1: number
  section2: number
  section3: number
  section4: number
  section5: number
}
export type VdaData = VdaDataEntry[]
export interface VdaDataEntry {
  input: 'none' | 'L' | 'H' | 'LH' | 'first-entry'
  isCorrect: boolean
  itemNumber: number
  actualSignalTimesMS?: VdaTimes
}
