import EndScreen from '@root/components/molecules/EndScreen/Component'
import Introduction from '@root/components/molecules/Introduction/Component'
import { GbdView } from '@root/components/organisms/GbdView/Component'
import { useAppContext } from '@root/global/context'
import { ProgressStateGbd } from '@root/misc/constants'
import { checkTitle, hasValue } from '@root/misc/helpers'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Trans, t } from '@lingui/macro'
import { GbdViewOverview } from '@root/components/organisms/GbdViewOverview/Component'

// For each different step in a test, this file renders the HTML for that specific step.

export function Gbd (props: { isOverview?: boolean }): JSX.Element {
  const { progressStateGbd, setProgressStateGbd, gbdData } = useAppContext()
  const location = useLocation()
  const { variant, pages } = useParams()

  if (hasValue(props.isOverview)) {
    return (<GbdViewOverview />)
  }

  if (progressStateGbd === ProgressStateGbd.Introduction) {
    return (
      <Introduction
        nextFn={() => setProgressStateGbd(ProgressStateGbd.Instruction)}
        title={checkTitle(location.pathname)}
        label={t({ id: 'gbd.introduction.title', message: 'Lees de instructie' })}
        description={(
          <>
            <p>
              <Trans id='gbd.introduction'>
                Bij deze test gaat het om het concentratie-vermogen; het vermogen om zich te kunnen richten op de opgedragen taak.<br /><br />
                Lees de instructie goed door en start de test pas als u deze volledig hebt begrepen.
              </Trans>
            </p>
          </>
        )}
      />
    )
  }

  if (progressStateGbd === ProgressStateGbd.Instruction) {
    return (
      <Introduction
        nextFn={() => setProgressStateGbd(ProgressStateGbd.Practise)}
        title={checkTitle(location.pathname)}
        label={t({ id: 'gbd.instruction.title.page1', message: 'Ga naar de oefentoets' })}
        description={(
          <>
            <p>
              <Trans id='gbd.instruction'>
                Op het scherm ziet je straks regels met groepjes stippen staan. Er zijn groepjes van drie, vier en vijf
                stippen. Het is de bedoeling dat je zo dadelijk alle groepjes van VIER stippen doorstreept, en die met
                drie of vijf laat staan.<br /><br />

                Met het pijltje naar rechts ga je de regel langs. Een rood streepje geeft aan waar je bent. Wanneer je
                een groepje van vier stippen tegenkomt, druk je op de toets met het pijltje naar boven. Daarmee zet
                je een streep door dit groepje.<br /><br />

                Als je per ongeluk een groepje van drie of vijf stippen doorstreept, kun je de streep weer weghalen
                door nog een keer op het pijltje-naar-boven te drukken. Met het pijltje-naar-links kun je maximaal 1
                plaats naar links, om daar alsnog een groepje door te strepen, of een streep weg te halen.
              </Trans>
            </p>
          </>
        )}
      />
    )
  }

  if (progressStateGbd === ProgressStateGbd.Practise) {
    return (
      <GbdView key='practise' variant='practise' endFn={() => setProgressStateGbd(ProgressStateGbd.Test)} />
    )
  }
  if (progressStateGbd === ProgressStateGbd.End) {
    return (
      <EndScreen type='gbd' data={gbdData} variant={variant ?? ''} />
    )
  }
  return (
    <GbdView key='test' pages={parseInt(pages ?? '5', 10) ?? 1} variant={variant ?? 'on-location'} endFn={() => setProgressStateGbd(ProgressStateGbd.End)} />
  )
}
