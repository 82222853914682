import style from './style.module.css'
import React from 'react'
interface Props {
  variantOption: Variant
}

interface Variant {
  id: number
  shape: string
  rotation: number
}

function generateRandomNumber ({ min, max }: { min: number, max: number }): number {
  const zeroOffset = 0 - min
  const maxOffset = max + zeroOffset
  const valueAboveZero = Math.random() * maxOffset
  return (valueAboveZero - zeroOffset)
}

const Shape = ({ dots, viewbox, maxOffset, margin, r }: { dots: Array<{ x: number, y: number }>, maxOffset: number, viewbox: string, margin: number, r: number }): JSX.Element => {
  return (
    <svg viewBox={viewbox}>
      {
        dots.map(dot => {
          const yPos = dot.y + generateRandomNumber({ min: -maxOffset, max: maxOffset })
          const xPos = dot.x + generateRandomNumber({ min: -maxOffset, max: maxOffset })
          return (<circle key={`shape_${dot.x}-${dot.y}`} cx={(20 * xPos) + (margin)} cy={(20 * yPos) + (margin)} r={r} />)
        })
      }
    </svg>
  )
}

const Boomerang = (props: { maxOffset: number, viewbox: string, margin: number, r: number }): JSX.Element => {
  const dots = [{ x: 0.8, y: 1 }, { x: 0.8, y: 0.45 }, { x: 0.4, y: 0 }]

  return (
    <Shape dots={dots} {...props} />
  )
}

const Line = (props: { maxOffset: number, viewbox: string, margin: number, r: number }): JSX.Element => {
  const dots = [{ x: 1, y: 1 }, { x: 0.5, y: 0.5 }, { x: 0, y: 0 }]

  return (
    <Shape dots={dots} {...props} />
  )
}

const Pyramid = (props: { maxOffset: number, viewbox: string, margin: number, r: number }): JSX.Element => {
  const dots = [{ x: 0.5, y: 0.1 }, { x: 1, y: 0.9 }, { x: 0, y: 0.9 }]

  return (
    <Shape dots={dots} {...props} />
  )
}

const Corner = (props: { maxOffset: number, viewbox: string, margin: number, r: number }): JSX.Element => {
  const dots = [{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 1, y: 1 }]

  return (
    <Shape dots={dots} {...props} />
  )
}

const Square = (props: { maxOffset: number, viewbox: string, margin: number, r: number }): JSX.Element => {
  const dots = [{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 0, y: 1 }, { x: 1, y: 1 }]

  return (
    <Shape dots={dots} {...props} />
  )
}

const SmallSquare = (props: { maxOffset: number, viewbox: string, margin: number, r: number }): JSX.Element => {
  const dots = [{ x: 0.1, y: 0.1 }, { x: 0.9, y: 0.1 }, { x: 0.1, y: 0.9 }, { x: 0.9, y: 0.9 }]

  return (
    <Shape dots={dots} {...props} />
  )
}

const Kite = (props: { maxOffset: number, viewbox: string, margin: number, r: number }): JSX.Element => {
  const dots = [{ x: 0, y: 0 }, { x: 1, y: 0.3 }, { x: 0.3, y: 1 }, { x: 1, y: 1 }]

  return (
    <Shape dots={dots} {...props} />
  )
}

const Diamond = (props: { maxOffset: number, viewbox: string, margin: number, r: number }): JSX.Element => {
  const dots = [{ x: 0, y: 0 }, { x: 0.75, y: 0.25 }, { x: 0.25, y: 0.75 }, { x: 1, y: 1 }]

  return (
    <Shape dots={dots} {...props} />
  )
}

const DiceFive = (props: { maxOffset: number, viewbox: string, margin: number, r: number }): JSX.Element => {
  const dots = [{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 0, y: 1 }, { x: 1, y: 1 }, { x: 0.5, y: 0.5 }]

  return (
    <Shape dots={dots} {...props} />
  )
}

const Circle = (props: { maxOffset: number, viewbox: string, margin: number, r: number }): JSX.Element => {
  const dots = [{ x: 0.5, y: 1 }, { x: 1, y: 0.8 }, { x: 0.8, y: 0.2 }, { x: 0.2, y: 0.2 }, { x: 0, y: 0.8 }]

  return (
    <Shape dots={dots} {...props} />
  )
}

const House = (props: { maxOffset: number, viewbox: string, margin: number, r: number }): JSX.Element => {
  const dots = [{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 0, y: 0.7 }, { x: 1, y: 0.7 }, { x: 0.5, y: 1 }]

  return (
    <Shape dots={dots} {...props} />
  )
}

const NarrowHouse = (props: { maxOffset: number, viewbox: string, margin: number, r: number }): JSX.Element => {
  const dots = [{ x: 0.15, y: 0 }, { x: 0.85, y: 0 }, { x: 0.1, y: 0.7 }, { x: 0.9, y: 0.7 }, { x: 0.5, y: 1 }]

  return (
    <Shape dots={dots} {...props} />
  )
}

export const returnDots = (props: Props): JSX.Element => {
  const { variantOption } = props
  const viewbox = '0 0 60 60'
  const margin = 20
  const r = 4
  const maxOffset = 0.08

  const returnVariation = (): JSX.Element => {
    switch (variantOption.shape) {
      case 'boomerang':
        return (<Boomerang r={r} maxOffset={maxOffset} viewbox={viewbox} margin={margin} />)
      case 'line':
        return (<Line r={r} maxOffset={maxOffset} viewbox={viewbox} margin={margin} />)
      case 'pyramid':
        return (<Pyramid r={r} maxOffset={maxOffset} viewbox={viewbox} margin={margin} />)
      case 'corner':
        return (<Corner r={r} maxOffset={maxOffset} viewbox={viewbox} margin={margin} />)

      case 'square':
        return (<Square r={r} maxOffset={maxOffset} viewbox={viewbox} margin={margin} />)
      case 'smallSquare':
        return (<SmallSquare r={r} maxOffset={maxOffset} viewbox={viewbox} margin={margin} />)
      case 'diamond':
        return (<Diamond r={r} maxOffset={maxOffset} viewbox={viewbox} margin={margin} />)
      case 'kite':
        return (<Kite r={r} maxOffset={maxOffset} viewbox={viewbox} margin={margin} />)

      case 'dice5':
        return (<DiceFive r={r} maxOffset={maxOffset} viewbox={viewbox} margin={margin} />)
      case 'circle':
        return (<Circle r={r} maxOffset={maxOffset} viewbox={viewbox} margin={margin} />)
      case 'house':
        return (<House r={r} maxOffset={maxOffset} viewbox={viewbox} margin={margin} />)
      case 'narrowHouse':
        return (<NarrowHouse r={r} maxOffset={maxOffset} viewbox={viewbox} margin={margin} />)

      default:
        return (<></>)
    }
  }

  return (
    <div className={style['dot-container']} style={{ transform: `rotate(${variantOption.rotation}deg)` }}>
      {returnVariation()}
    </div>
  )
}
export const MemoReturnDots = React.memo(returnDots)

// Initial C# code of localpopsition per dot

//   return new Vector3(Margin + CoreWidth * (relativePosition.x + Random.Range(-MaxRelativePositionVariance, MaxRelativePositionVariance)), Margin + CoreHeight * (relativePosition.y + Random.Range(-MaxRelativePositionVariance, MaxRelativePositionVariance)), 0.0f);
